import React from 'react';
import {
    CardHeader,
    Typography,
    Grid,
    Collapse,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import { saveAs } from 'file-saver';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

function DialogoEncuestadorTelefonicoCasa(props) {
    const { theme,
        encuestadorTelefonicoCasa,
        setOpenEstado,
        nombre,
        specs
    } = props;
    const [openCargo, setOpenCargo] = props.openCargo;

    const limpiar = () => {
        openCargo[1] = false;
        setOpenCargo([...openCargo]);
    }

    return (
        <Dialog
            open={openCargo[1]}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogTitle disableTypography style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={4}
                >
                    <Grid item xs={10} md={11}>
                        <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Está viendo el formato de {nombre}</Typography>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <IconButton onClick={() => { openCargo[1] = false; setOpenCargo([...openCargo]) }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <Divider />
            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>

                <CardHeader
                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                    title={
                        <Typography variant='h6' align='center'>Recolector de Información Telefónico</Typography>
                    }
                />

                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset">
                            <Typography variant='body1'> 1. ¿Cuenta con experiencia en servicio al cliente, ventas, analisis de información o recolección de datos?: </Typography>
                            <RadioGroup
                                value={encuestadorTelefonicoCasa.experiencia_servicios ? '1' : '0'}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Collapse in={encuestadorTelefonicoCasa.experiencia_servicios === true} mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='column'
                        alignItems='flex-start'
                        spacing={4}
                        style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" >
                                <Typography variant='body1'> 1.1. ¿Cuanto tiempo?: </Typography>
                                <RadioGroup
                                    value={encuestadorTelefonicoCasa.tiempo_experiencia_serivicios === "0 - 6 meses" ? '0' : (encuestadorTelefonicoCasa.tiempo_experiencia_serivicios === "6 - 12 meses" ? '1' : '2')}
                                >
                                    <FormControlLabel value='0' control={<Radio />} label="0 - 6 meses" />
                                    <FormControlLabel value='1' control={<Radio />} label="6 - 12 meses" />
                                    <FormControlLabel value='2' control={<Radio />} label="Más de 12 meses" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" >
                                <Typography variant='body1'> 1.2. ¿Cuenta con soporte de ésta experiencia?: </Typography>
                                <RadioGroup
                                    value={encuestadorTelefonicoCasa.soporte_experiencia_serivicios === "Sí" ? '1' : '0'}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                    <FormControlLabel value='0' control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>
                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" >
                            <Typography variant='body1'> 2. ¿Tiene experiencia en Call Center?: </Typography>
                            <RadioGroup
                                value={encuestadorTelefonicoCasa.experiencia_callcenter ? '1' : '0'}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Collapse in={encuestadorTelefonicoCasa.experiencia_callcenter === true} mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='column'
                        alignItems='flex-start'
                        spacing={4}
                        style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" >
                                <Typography variant='body1'> 2.1. ¿Cuenta con soporte de ésta experiencia?: </Typography>
                                <RadioGroup
                                    value={encuestadorTelefonicoCasa.soporte_experiencia_callcenter === "Sí" ? '1' : '0'}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                    <FormControlLabel value='0' control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>
                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" >
                            <Typography variant='body1'> 3. ¿Cuenta con computador (escritorio o PC) en su casa, que funcione correctamente?: </Typography>
                            <RadioGroup
                                value={encuestadorTelefonicoCasa.tiene_computador ? '1' : '0'}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Collapse in={encuestadorTelefonicoCasa.tiene_computador === true} mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='column'
                        alignItems='flex-start'
                        spacing={4}
                        style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset">
                                <Typography variant='body1'> 3.1. Por favor, indique el tiempo disponible para uso de su computador: </Typography>
                                <RadioGroup
                                    value={encuestadorTelefonicoCasa.disponibilidad_computador === "Todo el día" ? '0' : (encuestadorTelefonicoCasa.disponibilidad_computador === "Medio día" ? '1' : '2')}
                                >
                                    <FormControlLabel value='0' control={<Radio />} label="Todo el día" />
                                    <FormControlLabel value='1' control={<Radio />} label="Medio día" />
                                    <FormControlLabel value='2' control={<Radio />} label="Algunas horas" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" >
                                <Typography variant='body1'> 3.2. ¿Cuál es sistema operativo que tiene su computador?: </Typography>
                                <RadioGroup
                                    value={encuestadorTelefonicoCasa.sistema_operativo === "Anterior a Windows 10" ? '0' : (encuestadorTelefonicoCasa.sistema_operativo === "Superior a Windows 10" ? '1' : (encuestadorTelefonicoCasa.sistema_operativo === "Otro" ? '2' : '3'))}
                                >
                                    <FormControlLabel value='0' control={<Radio />} label="Anterior a Windows 10" />
                                    <FormControlLabel value='1' control={<Radio />} label="Superior a Windows 10" />
                                    <FormControlLabel value='2' control={<Radio />} label="Otro" />
                                    <FormControlLabel value='3' control={<Radio />} label="No sabe" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Collapse in={encuestadorTelefonicoCasa.sistema_operativo === 'Otro'} mountOnEnter unmountOnExit>
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={4}
                                style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(-2), marginLeft: theme.spacing(1) }}
                            >
                                <Grid item md={6} xs={12}>
                                    <Typography variant='body1'> Si selecciono otro, ¿Cúal?: </Typography>
                                    <TextField
                                        value={encuestadorTelefonicoCasa.otro_sistema_operativo}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Collapse>

                        <Grid item md={11} xs={12}>
                            <Typography variant='body1'> 3.3. ¿Qué procesador tiene su computador?: </Typography>
                            <TextField
                                value={encuestadorTelefonicoCasa.procesador_computador}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" >
                                <Typography variant='body1'> 3.4. ¿Cuánta memoria RAM tiene su computador?: </Typography>
                                <RadioGroup
                                    value={encuestadorTelefonicoCasa.ram_computador === "Menos de 4 Gigas" ? '0' : '1'}
                                >
                                    <FormControlLabel value='0' control={<Radio />} label="Menos de 4 Gigas" />
                                    <FormControlLabel value='1' control={<Radio />} label="Más de 4 Gigas" />

                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" >
                                <Typography variant='body1'> 3.5. ¿Su computador tiene puerto de conexión al modem de internet?: </Typography>
                                <RadioGroup
                                    value={encuestadorTelefonicoCasa.puerto_modem_computador === "Sí" ? '1' : '0'}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                    <FormControlLabel value='0' control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" >
                                <Typography variant='body1'> 3.6. ¿Su computador navega por Google Chrome?: </Typography>
                                <RadioGroup
                                    value={encuestadorTelefonicoCasa.chrome === "Sí" ? '1' : '0'}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                    <FormControlLabel value='0' control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item md={12} xs={12}>
                                <Typography>3.7 Características del computador:</Typography>
                            <IconButton
                                variant="contained"
                                disabled={(specs === null || specs === undefined)}
                                color={(specs === null || specs === undefined) ? 'inherit' : 'primary'}
                                onClick={() => { saveAs(`${specs}`, specs.split('/').slice(-1)[0])
                                }}
                            >
                                <CloudDownloadIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Collapse>
                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" >
                            <Typography variant='body1'> 4. ¿Actualmente cuenta con internet en su hogar?: </Typography>
                            <RadioGroup
                                value={encuestadorTelefonicoCasa.tiene_internet ? '1' : '0'}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Collapse in={encuestadorTelefonicoCasa.tiene_internet === true} mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='column'
                        alignItems='flex-start'
                        spacing={4}
                        style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={12} xs={12}>
                            <Typography variant='body1'> 4.1 ¿Cúal es el operador del intenet?: </Typography>
                            <TextField
                                value={encuestadorTelefonicoCasa.operador_internet}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='column'
                        alignItems='flex-start'
                        spacing={4}
                        style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={11} xs={12}>
                            <Typography variant='body1'> 4.2. ¿Cuántas megas tiene su internet?: </Typography>
                            <TextField
                                value={encuestadorTelefonicoCasa.megas_internet}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" >
                                <Typography variant='body1'> 4.3. ¿Tiene cable de conexión del computador al modem de internet?: </Typography>
                                <RadioGroup
                                    value={encuestadorTelefonicoCasa.cable_internet === "Sí" ? '1' : '0'}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                    <FormControlLabel value='0' control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>

                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" >
                            <Typography variant='body1'> 5. ¿Tiene auriculares con micrófono o diadema para realizar la llamada?: </Typography>
                            <RadioGroup
                                value={encuestadorTelefonicoCasa.auriculares_microfono ? '1' : '0'}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" >
                            <Typography variant='body1'> 6. ¿Tiene RUT?: </Typography>
                            <RadioGroup
                                value={encuestadorTelefonicoCasa.tiene_rut ? '1' : '0'}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Collapse in={encuestadorTelefonicoCasa.tiene_rut === false} mountOnEnter unmountOnExit>
                        <Grid
                            container
                            direction='column'
                            alignItems='flex-start'
                            spacing={4}
                            style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                        >
                            <Grid item md={12} xs={12}>
                                <FormControl component="fieldset" >
                                    <Typography variant='body1'> 6.1. ¿Está dispuesto a tramitarlo?: </Typography>
                                    <RadioGroup
                                        value={encuestadorTelefonicoCasa.tramite_rut === "Sí" ? '1' : '0'}
                                    >
                                        <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                        <FormControlLabel value='0' control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Collapse>
                </Grid>


            </DialogContent>

            <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />

            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='small' variant='contained' color='primary' onClick={() => { setOpenEstado(true) }}>
                    Estado
            </Button>
                <Button size='small' variant='contained' color='secondary' onClick={() => limpiar()} >
                    Cancelar
            </Button>
            </DialogActions>



        </Dialog>

    );
}

export default DialogoEncuestadorTelefonicoCasa;