import React from 'react';
import {
    CardHeader,
    Typography,
    Grid,
    Collapse,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    TextField
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import versiones_android from '../../data/versiones_android'

function DialogoSupervisorTelefonicoPresencial(props) {
    const { theme, 
        supervisorTelefonicoPresencial,
        setOpenEstado,
        nombre
    } = props;
    const [openCargo, setOpenCargo] = props.openCargo;

    const limpiar = () => {
        openCargo[3] = false;
        setOpenCargo([...openCargo])
    }
    return (
        <Dialog
            open={openCargo[3]}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='md'
        >
            <DialogTitle disableTypography style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
            <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={4}
                >
                <Grid item xs={10} md={11}>
                    <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Está viendo el formato de {nombre}</Typography>
                </Grid>
                <Grid item xs={2} md={1}>
                        <IconButton onClick={() => {openCargo[3] = false; setOpenCargo([...openCargo])}}>
                            <CloseIcon />
                        </IconButton>
                </Grid>
            </Grid>
            </DialogTitle>

            <Divider />

            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                <CardHeader
                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                    title={
                        <Typography variant='h6' align='center'>Supervisor Campo Telefónico</Typography>
                    }
                />

                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" >
                            <Typography variant='body1'> 1. ¿Tiene experiencia en el manejo de personal áreas de producción?: </Typography>
                            <RadioGroup
                                value={supervisorTelefonicoPresencial.experiencia_manejo_personal ? '1' : '0'}
                            >
                                <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                <FormControlLabel value='0' control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Collapse in={supervisorTelefonicoPresencial.experiencia_manejo_personal === true } mountOnEnter unmountOnExit>
                    <Grid
                        container
                        direction='column'
                        alignItems='flex-start'
                        spacing={4}
                        style={{ marginTop: theme.spacing(-2), marginBottom: theme.spacing(1), marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" >
                                <Typography variant='body1'> 1.1. ¿Cuánto tiempo de experiencia tiene liderando equipos de trabajo?:</Typography>
                                <RadioGroup
                                    value={supervisorTelefonicoPresencial.tiempo_experiencia === "0 - 6 meses" ? '0' : (supervisorTelefonicoPresencial.tiempo_experiencia === "6 - 12 meses" ? '1' : '2')}
                                >
                                    <FormControlLabel value='0' control={<Radio />} label="0 - 6 meses" />
                                    <FormControlLabel value='1' control={<Radio />} label="6 - 12 meses" />
                                    <FormControlLabel value='2' control={<Radio />} label="Más de 12 meses" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" >
                                <Typography variant='body1'> 1.2. ¿Cuántas personas ha tenido a cargo?:</Typography>
                                <RadioGroup
                                    value={supervisorTelefonicoPresencial.personas_a_cargo === "De 1 a 10" ? '0' : (supervisorTelefonicoPresencial.personas_a_cargo === "De 11 a 20" ? '1' : '2')}
                                >
                                    <FormControlLabel value='0' control={<Radio />} label="De 1 a 10" />
                                    <FormControlLabel value='1' control={<Radio />} label="De 11 a 20" />
                                    <FormControlLabel value='2' control={<Radio />} label="De 21 en adelante" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <FormControl component="fieldset" >
                                <Typography variant='body1'> 1.3. ¿Cuenta con soporte de esta experiencia?:</Typography>
                                <RadioGroup
                                    value={supervisorTelefonicoPresencial.soporte_experiencia === "Sí" ? '1' : '0'}
                                >
                                    <FormControlLabel value='1' control={<Radio />} label="Sí" />
                                    <FormControlLabel value='0' control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Collapse>

                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={6} xs={12}>
                        <Typography variant='body1'> 2. ¿Cuál es la marca y modelo de su celular?: </Typography>
                        <TextField
                            value={supervisorTelefonicoPresencial.marca_celular}
                            size='small'
                            variant='outlined'
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>

                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{marginBottom: theme.spacing(1)}}
                >
                    <Grid item md={6} xs={12}>
                        <Typography variant='body1'> 3. ¿Cuál es la versión de Android  su celular?: </Typography>
                        <TextField
                            value={ supervisorTelefonicoPresencial.version_android !== undefined ? (supervisorTelefonicoPresencial.version_android === 20 ? 'No tiene Android' : (versiones_android.filter(v => (v.cod === supervisorTelefonicoPresencial.version_android))[0].codename + ': ' + versiones_android.filter(v => (v.cod === supervisorTelefonicoPresencial.version_android))[0].version)) : ''}                            
                            size='small'
                            variant='outlined'
                            fullWidth
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={6} xs={12}>
                    <FormControl component="fieldset" >
                            <Typography variant='body1'> 4. ¿Con cuánta memoria RAM cuenta su celular?: </Typography>
                            <RadioGroup
                                aria-label="afiliacion"
                                value={supervisorTelefonicoPresencial.memoria_ram_celular === "Inferior a 2GB" ? '0' : (supervisorTelefonicoPresencial.memoria_ram_celular === "Entre 2GB y 4GB" ? '1' : '2')}
                            >
                                <FormControlLabel value='0' control={<Radio />} label="Inferior a 2GB" />
                                <FormControlLabel value='1' control={<Radio />} label="Entre 2GB y 4GB" />
                                <FormControlLabel value='2' control={<Radio />} label="Superior a 4GB" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

                <Divider style={{ marginBottom: 16 }} />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={6} xs={12}>
                    <FormControl component="fieldset" >
                            <Typography variant='body1'> 5. ¿Con cuánta memoria de almacenamiento cuenta su celular?: </Typography>
                            <RadioGroup
                                aria-label="afiliacion"
                                value={supervisorTelefonicoPresencial.memoria_interna_celular === "Inferior a 8GB" ? '0' : (supervisorTelefonicoPresencial.memoria_interna_celular === "Entre 8GB y 16GB" ? '1' : '2')}
                            >
                                <FormControlLabel value='0' control={<Radio />} label="Inferior a 8GB" />
                                <FormControlLabel value='1' control={<Radio />} label="Entre 8GB y 16GB" />
                                <FormControlLabel value='2' control={<Radio />} label="Superior a 16GB" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='small' variant='contained' color='primary' onClick={() => {setOpenEstado (true)}}>
                    Estado
                </Button>
                <Button size='small' variant='contained' color='secondary' onClick={() => limpiar()} >
                    Cancelar
                </Button>
            </DialogActions>

        </Dialog>

    );
}

export default DialogoSupervisorTelefonicoPresencial;