import React, { useState } from 'react';
import {
    CardHeader,
    Typography,
    Grid,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Collapse,
    List,
    ListItem,
    ListItemText,
    Checkbox,
    FormGroup,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { saveAs } from 'file-saver';
import { KeyboardDatePicker } from "@material-ui/pickers";
import variablesDescarga from '../../../data/variablesDescarga';

function DialogoDescargas(props) {
    const { theme,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        setShowBackdrop,
    } = props;
    const [openDescargas, setOpenDescargas] = props.open;
    const [accessToken, setAccessToken] = props.accessToken;
    const [modo, setModo] = useState('');
    const [variables, setVariables] = useState(['documento', null, 'nombre', null, null, null, 'fecha_nacimiento', 'celular', null, null, 'correo', 'ciudad_residencia', null, null, null, null, 'ultimo_nivel_estudios', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 'eps', 'pension', null, null, null, null, null, null, null, null, 'aprobado_documentos', null, null, 'estado_contratacion', null]);
    const [info, setInfo] = useState(Array(3).fill(false));
    const [since, setSince] = useState(null);
    const [until, setUntil] = useState(null);

    const limpiar = () => {
        setOpenDescargas(false);
        setModo('');
        setVariables(['documento', null, 'nombre', null, null, null, 'fecha_nacimiento', 'celular', null, null, 'correo', 'ciudad_residencia', null, null, null, null, 'ultimo_nivel_estudios', null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 'eps', 'pension', null, null, null, null, null, null, null, null, null, 'aprobado_documentos', null, null, 'estado_contratacion', null]);
        setInfo(Array(3).fill(false));
        setSince(null);
        setUntil(null);
    }

    async function descargarExcel(mode, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/descargar_excel/mode=${mode}&since=${since === null ? since : since.toISOString().split('T')[0]}&until=${until === null ? until : until.toISOString().split('T')[0]}/`, {
            headers: { 'Authorization': `Bearer ${access}` },
        })
        if (res.ok) {
            const date = new Date().toISOString().split('T')[0]
            const filename = `Gestor de selección ${date}.xlsx`
            const blob = await res.blob();

            saveAs(blob, filename);
            setShowBackdrop(false);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        descargarExcel(mode, newAccess);
                    }
                }
            });
        }
    }
    async function descargarExcelBody(mode, variables, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/descargar_excel/mode=${mode}&since=${since === null ? since : since.toISOString().split('T')[0]}&until=${until === null ? until : until.toISOString().split('T')[0]}/`, {
            headers: { 'Authorization': `Bearer ${access}`, 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({
                fields: variables.filter(v => v !== null)
            })
        })
        if (res.ok) {
            const date = new Date().toISOString().split('T')[0]
            const filename = `Gestor de selección ${date}.xlsx`
            const blob = await res.blob();

            saveAs(blob, filename);
            setShowBackdrop(false);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        descargarExcel(mode, newAccess);
                    }
                }
            });
        }
    }
    return (
        <Dialog
            open={openDescargas}
            onClose={() => limpiar()}

            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle disableTypography style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={4}
                >
                    <Grid item xs={10} md={10}>
                        <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Descarga de bases de datos</Typography>
                    </Grid>
                    <Grid item xs={2} md={2}>
                        <IconButton onClick={() => limpiar()}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <Divider />

            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                <CardHeader
                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                    title={
                        <Typography variant='h6' align='left'>Selecciona el tipo de base que quieres descargar:</Typography>
                    }
                />
                <Grid
                    container
                    direction='column'
                    alignItems='flex-start'
                    spacing={4}
                    style={{ marginBottom: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12}>
                        <FormControl component="fieldset" >
                            <RadioGroup
                                value={modo}
                                onChange={(e) => setModo(e.target.value)}
                            >
                                <FormControlLabel value='personal' control={<Radio />}
                                    label={
                                        <React.Fragment>
                                            <Typography variant='body1' align='left'>
                                                Información personal
                                                <IconButton onClick={() => { info[0] = !info[0]; setInfo([...info]) }}>
                                                    {!info[0] ? <HelpIcon color='primary' style={{ fontSize: '15px' }} /> : <ExpandLess color='primary' style={{ fontSize: '15px' }} />}
                                                </IconButton>
                                            </Typography>
                                        </React.Fragment>
                                    } />
                                <Collapse in={info[0]}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Typography style={{ fontWeight: 500, marginLeft: theme.spacing(4) }} variant='body1' align='left'>
                                                Esta base de datos contiene la información básica del personal registrado. Puedes seleccionar las variables que deseas descargar.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                                <FormControlLabel value='experiencia_laboral' control={<Radio />}
                                    label={
                                        <React.Fragment>
                                            <Typography variant='body1' align='left'>
                                                Experiencias laborales
                                                <IconButton onClick={() => { info[1] = !info[1]; setInfo([...info]) }}>
                                                    {!info[1] ? <HelpIcon color='primary' style={{ fontSize: '15px' }} /> : <ExpandLess color='primary' style={{ fontSize: '15px' }} />}
                                                </IconButton>
                                            </Typography>
                                        </React.Fragment>
                                    } />
                                <Collapse in={info[1]}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Typography style={{ fontWeight: 500, marginLeft: theme.spacing(4) }} variant='body1' align='left'>
                                                Esta base de datos contiene la lista y detalles de experiencia laboral de cada persona, sin incluir ninguno de sus datos personales:

                                            </Typography>
                                            <List style={{ marginLeft: theme.spacing(5) }}>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• persona_seleccion"
                                                        secondary="Cédula de la persona"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• sector"
                                                        secondary="Sector al que pertenece la empresa"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• nombre_empresa"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• telefono_empresa"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• ciudad"
                                                        secondary="Ciudad donde se encuentra la empresa"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• nombre_jefe"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• telefono_jefe"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• cargo"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• funciones"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• certificacion_laboral"
                                                        secondary="Si cuenta o no con certificación laboral"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• fecha_ingreso"
                                                        secondary="Fecha de ingreso al cargo"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• fecha_retiro"
                                                        secondary="Fecha de retiro de la empresa"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• tiempo_laborado"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• motivo_retiro"
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                                <FormControlLabel value='contrataciones' control={<Radio />}
                                    label={
                                        <React.Fragment>
                                            <Typography variant='body1' align='left'>
                                                Contrataciones e información del personal
                                                <IconButton onClick={() => { info[2] = !info[2]; setInfo([...info]) }}>
                                                    {!info[2] ? <HelpIcon color='primary' style={{ fontSize: '15px' }} /> : <ExpandLess color='primary' style={{ fontSize: '15px' }} />}
                                                </IconButton>
                                            </Typography>
                                        </React.Fragment>
                                    } />
                                <Collapse in={info[2]}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={12}>
                                            <Typography style={{ fontWeight: 500, marginLeft: theme.spacing(4) }} variant='body1' align='left'>
                                                Esta base contiene el histórico de contrataciones creadas, junto con las variables de información personal que escogas. Además de todas las variables incluídas en la primera base de datos, se suman:
                                            </Typography>
                                            <List style={{ marginLeft: theme.spacing(5) }}>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• perfil"
                                                        secondary="Cargo al que se contrató a la persona"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• fecha_inicio_contrato"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• fecha_fin_contrato"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• duracion"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• contrato"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• comodato"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText
                                                        primary="• fecha_firma_contrato"
                                                        secondary="Fecha en la que la persona firma el contrato"
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Collapse>
                                <Collapse in={modo === 'contrataciones'}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={4}
                                        style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                                    >
                                        <Grid item xs={6}>
                                            <Typography variant='body2' align='center' style={{ fontSize: 16, fontWeight: 500, justifyContent: 'left' }}>Desde: </Typography>
                                            <KeyboardDatePicker
                                                autoOk
                                                inputVariant='outlined'
                                                size='small'
                                                variant='inline'
                                                fullWidth
                                                value={since}
                                                placeholder="dd/mm/aaaa"
                                                onChange={date => { setSince(date) }}
                                                format="dd/MM/yyyy"
                                                clearable
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant='body2' align='center' style={{ fontSize: 16, fontWeight: 500, justifyContent: 'left' }}>Hasta: </Typography>
                                            <KeyboardDatePicker
                                                autoOk
                                                inputVariant='outlined'
                                                size='small'
                                                variant='inline'
                                                fullWidth
                                                value={until}
                                                placeholder="dd/mm/aaaa"
                                                onChange={date => { setUntil(date) }}
                                                format="dd/MM/yyyy"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Typography style={{ fontWeight: 300, marginLeft: theme.spacing(4), fontStyle: 'italic' }} variant='body1' align='left'>
                                        Si quieres descargar todo el histórico de contrataciones, deja estos campos de fechas vacíos.
                                    </Typography>
                                </Collapse>
                                <Collapse in={(modo === 'personal') || (modo === 'contrataciones')} >
                                    <Typography variant='body1' align='left' style={{ fontWeight: 500, fontSize: 18, marginTop: theme.spacing(2), marginLeft: theme.spacing(2) }}>
                                        Selecciona las variables que quieres descargar:
                                    </Typography>
                                    <div style={{marginLeft: theme.spacing(8), height: 400, width:400, "overflow-y": 'scroll'}}>
                                        {variablesDescarga.map((v, i) => (
                                            <React.Fragment>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        key={v}
                                                        control={
                                                            <Checkbox
                                                                defaultChecked={variables.includes(v.nombre)}
                                                                // checked={variables.includes(v)}
                                                                onChange={() => { variables[v.cod] === null ? variables[v.cod] = v.nombre : variables[v.cod] = null; setVariables([...variables]) }}
                                                            />
                                                        }
                                                        label={
                                                            <Typography variant='body1' align='left'>
                                                                {v.nombre}
                                                            </Typography>
                                                        }
                                                    />
                                                </FormGroup>
                                            </React.Fragment>))}
                                    </div>
                                </Collapse>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>


            </DialogContent>
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='small' variant='contained' color='primary' onClick={() => { modo === 'experiencia_laboral' ? descargarExcel(modo) : descargarExcelBody(modo, variables) }}>
                    Descargar
                </Button>

            </DialogActions>

        </Dialog>

    );
}

export default DialogoDescargas;