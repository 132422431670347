import React from 'react';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { esES } from '@material-ui/core/locale';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from "date-fns/locale/es";
import { BrowserRouter as Router } from "react-router-dom";

import Gestor from './Gestor/Gestor';

let theme = createTheme({
  palette: {
    primary: {
      main: '#C70039',
    },
    secondary: {
      main: '#8b0043'
    },
    background: {
      default: '#F4F6F8'
    },
    error: {
      main: '#e53935'
    },
    success: {
      main: '#43A047'
    }
  },
  typography: {
    fontFamily: 'Roboto'
  }
}, esES);

theme = responsiveFontSizes(theme);

function App() {
  return (
    <Router>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <ThemeProvider theme={theme}>
          <div className='App'>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
            <CssBaseline />
            <Gestor theme={theme} />
          </div>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Router>
  );
}

export default App;