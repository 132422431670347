import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Grid,
    Typography,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    Paper,
    TableRow,
    Link,
    Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import HistoryIcon from '@material-ui/icons/History';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import DraftsIcon from '@material-ui/icons/Drafts';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';

import cargos from '../../../data/cargos';

const useStyles = makeStyles({
    dialog: {
        position: 'absolute',
        right: 10,
        bottom: 10
    }
});

function DialogoContratacion(props) {
    const {
        theme,
        setShowBackdrop,
        setSeverity,
        setMessage,
        setSnack,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        cedula,
        nombre
    } = props;
    const inheritedClasses = props.classes;
    const classes = useStyles();
    const [openContratacion, setOpenContratacion] = props.openContratacion
    const [accessToken, setAccessToken] = props.accessToken;
    const [contrataciones, setContrataciones] = useState([]);
    const [datosEliminar, setDatosEliminar] = useState({});
    const [datosEliminarAux, setDatosEliminarAux] = useState({});
    const [openEliminar, setOpenEliminar] = useState(false);

    const [historial, setHistorial] = useState({});
    const [openHistorial, setOpenHistorial] = useState(false);

    async function fetchContrataciones(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/contratacion/persona_seleccion=${cedula}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchContrataciones(newAccess);
                }
            }
            else {
                setShowBackdrop(false);
                setContrataciones(res);
            }

        });
    }
    const limpiar = () => {
        setOpenContratacion(false);
        setContrataciones([]);
        setHistorial({});
    }
    const limpiarDelete = () => {
        setOpenEliminar(false);
        setDatosEliminar({});
        setDatosEliminarAux({});
    }

    async function deleteContratacion(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/contratacion/persona_seleccion=${cedula}/`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify(datosEliminar)
        })
        if (res.ok) {
            setShowBackdrop(false);
            setMessage('Se ha eliminado la contratación correctamente');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            limpiarDelete();
            fetchContrataciones();
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        deleteContratacion(newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    const dialogoEliminarContratacion = (
        <Dialog
            open={openEliminar}
            onClose={() => limpiarDelete()}
        >
            <DialogTitle>{"¿Estás seguro de que quieres eliminar esta contratación?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <p><strong>Perfil:</strong> {datosEliminarAux.perfil}.</p>
                    <p><strong>Fecha de creaci&oacute;n:</strong> {datosEliminarAux.fecha_creado}.</p>
                    <p><strong>Fecha de inicio:</strong> {datosEliminarAux.fecha_inicio_contrato}.</p>
                    <p><strong>Fecha de Fin:</strong> {datosEliminarAux.fecha_fin_contrato}.</p>
                    <p><strong>Duraci&oacute;n:</strong> {datosEliminarAux.duracion}.</p>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { limpiarDelete() }} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => {
                    deleteContratacion()
                }} color="primary" autoFocus>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    )

    const dialogoHistorial = (
        <Dialog
            classes={{
                paper: classes.dialog
            }}
            open={openHistorial}
            onClose={() => { setOpenHistorial(false); setHistorial({}) }}
        >
            <DialogTitle>{"Historial de contratación:"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <React.Fragment>
                        {"creacion_contrato" in historial ?
                            <Grid container direction="row" alignItems='center' style={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs={1}>
                                    <AddCircleIcon style={{ color: '#ff7500' }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography style={{ color: 'black' }}> {historial.creacion_contrato}</Typography>
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                    </React.Fragment>
                    <React.Fragment>
                        {"notificacion_creacion" in historial ?
                            <Grid container direction="row" alignItems='center' style={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs={1}>
                                    <NotificationsActiveIcon style={{ color: '#d98f00' }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography style={{ color: 'black' }}> {historial.notificacion_creacion}</Typography>
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                    </React.Fragment>
                    <React.Fragment>
                        {"ingreso_primera_vez" in historial ?
                            <Grid container direction="row" alignItems='center' style={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs={1}>
                                    <DraftsIcon style={{ color: '#E0C154' }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography style={{ color: 'black' }}> {historial.ingreso_primera_vez}</Typography>
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                    </React.Fragment>
                    <React.Fragment>
                        {"acepta_contrato" in historial ?
                            <Grid container direction="row" alignItems='center' style={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs={1}>
                                    {historial.acepta_contrato.includes("rechazado") ? <AssignmentLateIcon style={{ color: '#FC100D' }} /> : <EditIcon style={{ color: '#82ad02' }} />}
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography style={{ color: 'black' }}> {historial.acepta_contrato}</Typography>
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                        <React.Fragment>
                            {"recepcion_contrato_firmado" in historial ?
                                <Grid container direction="row" alignItems='center' style={{ marginBottom: theme.spacing(2) }}>
                                    <Grid item xs={1}>
                                        <AssignmentTurnedInIcon style={{ color: '#4BB543' }} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography style={{ color: 'black' }}> {historial.recepcion_contrato_firmado}</Typography>
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                        </React.Fragment>
                    </React.Fragment>
                    <React.Fragment>
                        {"finalizacion_contrato" in historial ?
                            <Grid container direction="row" alignItems='center' style={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs={1}>
                                    <EventBusyIcon style={{ color: '#EC3B47' }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography style={{ color: 'black' }}> {historial.finalizacion_contrato}</Typography>
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                    </React.Fragment>
                    <React.Fragment>
                        {"anulacion_contrato" in historial ?
                            <Grid container direction="row" alignItems='center' style={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs={1}>
                                    <CancelIcon style={{ color: '#EC3B47' }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography style={{ color: 'black' }}> {historial.anulacion_contrato}</Typography>
                                </Grid>
                            </Grid>
                            :
                            null
                        }
                    </React.Fragment>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { setOpenHistorial(false); setHistorial({}) }} color="primary">
                    Entendido
                </Button>
            </DialogActions>
        </Dialog>
    )
    return (
        <Dialog
            open={openContratacion}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='md'
            TransitionProps={{
                onEnter: () => { fetchContrataciones() }
            }}
        >
            <DialogTitle>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={4}
                >
                    <Grid item xs={11}>
                        <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Historial de contratación de: {cedula} - {nombre}</Typography>
                    </Grid>
                    <Grid item xs={1} >
                        <IconButton onClick={() => { limpiar() }}>
                            <CloseIcon style={{ align: 'flex-end' }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <TableContainer component={Paper} variant='outlined'>
                    <Table style={{ minWidth: 100 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Perfil</TableCell>
                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Fecha creado</TableCell>
                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Fecha inicio</TableCell>
                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Fecha fin</TableCell>
                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Duración</TableCell>
                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Contrato</TableCell>
                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Comodato</TableCell>
                                <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'>Opciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                contrataciones.map((row) => (
                                    <TableRow key={row.fecha_creado}>
                                        <TableCell style={{ paddingTop: 14, paddingBottom: 14, maxWidth: 50 }} align='left'> {cargos.filter(c => c.cod === row.perfil)[0].cargo} </TableCell>
                                        <TableCell style={{ paddingTop: 14, paddingBottom: 14, maxWidth: 5 }} align='right'>{row.fecha_creado === null ? 'Vacío' : (new Date(row.fecha_creado).toLocaleDateString("es-ES") + " - " + new Date(row.fecha_creado).toLocaleTimeString('en-US'))}</TableCell>
                                        <TableCell style={{ paddingTop: 14, paddingBottom: 14, maxWidth: 5 }} align='right'>{row.fecha_inicio_contrato === null ? 'Vacío' : (new Date(row.fecha_inicio_contrato.split('-')[0], row.fecha_inicio_contrato.split('-')[1] - 1, row.fecha_inicio_contrato.split('-')[2]).toLocaleDateString("es-ES"))}</TableCell>
                                        <TableCell style={{ paddingTop: 14, paddingBottom: 14, maxWidth: 5 }} align='right'>{row.fecha_fin_contrato === null ? 'Vacío' : (new Date(row.fecha_fin_contrato.split('-')[0], row.fecha_fin_contrato.split('-')[1] - 1, row.fecha_fin_contrato.split('-')[2]).toLocaleDateString("es-ES"))}</TableCell>
                                        <TableCell style={{ paddingTop: 14, paddingBottom: 14, maxWidth: 20 }} align='right'>{row.duracion}</TableCell>
                                        <TableCell style={{ paddingTop: 14, paddingBottom: 14, maxWidth: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} align='left'><Link href={row.contrato} target="_blank">{row.contrato}</Link></TableCell>
                                        <TableCell style={{ paddingTop: 14, paddingBottom: 14, maxWidth: 40, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} align='left'><Link href={row.comodato} target="_blank">{row.contrato}</Link></TableCell>
                                        <TableCell component='th' scope='row' align='center'>
                                            <div style={{ display: 'flex' }}>
                                                <Tooltip title='Ver historial'>
                                                    <IconButton
                                                        color='primary'
                                                        onClick={() => {
                                                            setOpenHistorial(true);
                                                            setHistorial(row.historial);
                                                        }}
                                                    >
                                                        <HistoryIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Eliminar contratación'>
                                                    <IconButton
                                                        color="secondary"
                                                        className={inheritedClasses.conditionalRoleDisplay}
                                                        onClick={() => {
                                                            setOpenEliminar(true);
                                                            setDatosEliminar(
                                                                {
                                                                    "id": row.id,
                                                                    "perfil": row.perfil,
                                                                }
                                                            )
                                                            setDatosEliminarAux(
                                                                {
                                                                    "perfil": cargos.filter(c => c.cod === row.perfil)[0].cargo,
                                                                    "fecha_creado": new Date(row.fecha_creado).toLocaleDateString("es-ES") + " - " + new Date(row.fecha_creado).toLocaleTimeString('en-US'),
                                                                    "fecha_inicio_contrato": new Date(row.fecha_inicio_contrato).toLocaleDateString("es-ES"),
                                                                    "fecha_fin_contrato": new Date(row.fecha_fin_contrato).toLocaleDateString("es-ES"),
                                                                    "duracion": row.duracion
                                                                }
                                                            )
                                                        }}
                                                        onClose={() => { limpiarDelete() }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            {dialogoEliminarContratacion}
            {dialogoHistorial}
        </Dialog>

    );
}

export default DialogoContratacion;