import React, { useState } from 'react';
import {
    CardHeader,
    Typography,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    Grid,
    Tooltip
} from '@material-ui/core';
import differenceInYears from 'date-fns/differenceInYears';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import DialogoDatosPersonales from './components/DialogoDatosPersonales';
import DialogoEstudiosRealizados from './components/DialogoEstudiosRealizados';
import DialogoExperienciaLaboral from './components/DialogoExperienciaLaboral';
import DialogoInformacionPersonal from './components/DialogoInformacionPersonal';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import HowToRegIcon from '@material-ui/icons/HowToReg';

import municipios from '../../../../data/municipios'
import niveles from '../../../../data/nivelEstudios';
import areas from '../../../../data/areaEstudios';
import horarios from '../../../../data/horarioEstudios';
import epss from '../../../../data/epss';
import fondosPension from '../../../../data/fondosPension';
import tipoAfiliaciones from '../../../../data/tipoAfiliaciones';

import { saveAs } from 'file-saver';

function DialogoHojaDeVida(props) {
    const { theme,
        classes,
        API_DEFAULT,
        point,
        getAccessTokenWithRefresh,
        setSnack,
        setShowBackdrop,
        setMessage,
        setSeverity,
        cedula
    } = props;
    const [open, setOpen] = props.open;
    const [accessToken, setAccessToken] = props.accessToken;
    const [formatoColapso, setFormatoColapso] = useState(Array(4).fill(false));

    // Datos personales
    const [nombre, setNombre] = useState('');
    const [datosPersonales, setDatosPersonales] = useState([]);
    const [primerApellido, setPrimerApellido] = useState('');
    const [segundoApellido, setSegundoApellido] = useState('');
    const [primerNombre, setPrimerNombre] = useState('');
    const [segundoNombre, setSegundoNombre] = useState('');
    const [tipoDocumento, setTipoDocumento] = useState(null);
    const [documento, setDocumento] = useState('');
    const [fechaExpedicion, setFechaExpedicion] = useState(new Date());
    const [lugarExpedicion, setLugarExpedicion] = useState(null);
    const [fechaNacimiento, setFechaNacimiento] = useState(new Date());
    const [ciudad, setCiudad] = useState(null);
    const [celular, setCelular] = useState('');
    const [celular2, setCelular2] = useState('');
    const [telefono, setTelefono] = useState('');
    const [correo, setCorreo] = useState('');
    const [direccion, setDireccion] = useState('');
    const [complementoDireccion, setComplementoDireccion] = useState('');
    const [barrio, setBarrio] = useState('');
    const [localidad, setLocalidad] = useState('');

    // Estudios realizados
    const [nivel, setNivel] = useState(null);
    const [area, setArea] = useState(null);
    const [estado, setEstado] = useState(null);
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const [centroEducativo, setCentroEducativo] = useState('');
    const [estudioActual, setEstudioActual] = useState(null);
    const [modalidad, setModalidad] = useState(null);
    const [horario, setHorario] = useState(null);

    const [experienciaLaboral, setExperienciaLaboral] = useState([]);

    // Información personal 
    const [cirugia, setCirugia] = useState(null);
    const [tipoCirugia, setTipoCirugia] = useState('');
    const [medicamento, setMedicamento] = useState(null);
    const [tipoMedicamento, setTipoMedicamento] = useState('');
    const [nombreFamiliar, setNombreFamiliar] = useState(Array(1).fill(''));
    const [parentesco, setParentesco] = useState(Array(1).fill(''));
    const [telefonoFamiliar, setTelefonoFamiliar] = useState(Array(1).fill(''));
    const [nombreReferencia, setNombreReferencia] = useState('');
    const [relacionReferencia, setRelacionReferencia] = useState('');
    const [telefonoReferencia, setTelefonoReferencia] = useState('');
    const [nombreReferencia2, setNombreReferencia2] = useState('');
    const [relacionReferencia2, setRelacionReferencia2] = useState('');
    const [telefonoReferencia2, setTelefonoReferencia2] = useState('');
    const [eps, setEps] = useState(null);
    const [fondoPension, setFondoPension] = useState(null);
    const [estadoAfiliacion, setEstadoAfiliacion] = useState(null);
    const [tipoAfiliacion, setTipoAfiliacion] = useState(null);

    const [readOnly, setReadOnly] = useState(true);
    const [errores, setErrores] = useState(Array(22).fill(false));

    const validar = () => {
        let errorDatos = false;
        // Errores de datos personales:
        if (primerApellido === '') {
            errorDatos = true;
            errores[0] = true;
        }
        if (primerNombre === '') {
            errorDatos = true;
            errores[1] = true;
        }
        if (differenceInYears(fechaExpedicion, fechaNacimiento) < 18) {
            errorDatos = true;
            errores[2] = true;
        }
        if (differenceInYears(new Date(), fechaNacimiento) < 18) {
            errorDatos = true;
            errores[3] = true;
        }
        if (celular === '' || parseInt(celular) === 0 || celular.length !== 10) {
            errorDatos = true;
            errores[4] = true;
        }
        if (celular2 === '' || parseInt(celular2) === 0 || celular2.length !== 10) {
            errorDatos = true;
            errores[5] = true;
        }
        if (/^\S+@\S+\.\S+$/.test(correo) === false || correo === '') {
            errorDatos = true;
            errores[6] = true;
        }
        if (direccion === '') {
            errorDatos = true;
            errores[7] = true;
        }
        if (barrio === '') {
            errorDatos = true;
            errores[8] = true;
        }
        // Errores de estudios realizados:
        if (area === null) {
            errorDatos = true;
            errores[9] = true;
        }
        if (estado === 'Culminado' && (fechaFin === null || differenceInCalendarDays(fechaFin, fechaInicio) < 0)) {
            errorDatos = true;
            errores[10] = true;

        }
        if (centroEducativo === '') {
            errorDatos = true;
            errores[11] = true;
        }
        if (estudioActual === 'Sí' && modalidad === null) {
            errorDatos = true;
            errores[12] = true;
        }
        if (estudioActual === 'Sí' && horario === null) {
            errorDatos = true;
            errores[13] = true;
        }
        // Errores Información Personal
        if (cirugia === 'Sí' && tipoCirugia === '') {
            errorDatos = true;
            errores[14] = true;
        }
        if (medicamento === 'Sí' && tipoMedicamento === '') {
            errorDatos = true;
            errores[15] = true;
        }
        if (nombreReferencia === '') {
            errorDatos = true;
            errores[16] = true;
        }
        if (relacionReferencia === '') {
            errorDatos = true;
            errores[17] = true;
        }
        if (telefonoReferencia === '' || parseInt(telefonoReferencia) === 0) {
            errorDatos = true;
            errores[18] = true;
        }
        if (nombreReferencia2 === '') {
            errorDatos = true;
            errores[19] = true;
        }
        if (relacionReferencia2 === '') {
            errorDatos = true;
            errores[20] = true;
        }
        if (telefonoReferencia2 === '' || parseInt(telefonoReferencia2) === 0) {
            errorDatos = true;
            errores[21] = true;
        }
        if (errorDatos) {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
            setReadOnly(false);
        }
        else {
            putInformation(cedula)
        }
    }

    const limpiar = () => {
        setOpen(false);
        setFormatoColapso(Array(4).fill(false));
        setReadOnly(true);
        setErrores(Array(22).fill(false));
        setNombreFamiliar(Array(1).fill(''));
        setParentesco(Array(1).fill(''));
        setTelefonoFamiliar(Array(1).fill(''));
    }

    async function fetchDatosPersonales(cc, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/persona_seleccion=${cc}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchDatosPersonales(cc, newAccess);
                }
            }
            else {
                setDatosPersonales(res);
                setNombre(res.nombre);
                setPrimerApellido(res.p_apellido);
                setSegundoApellido(res.s_apellido);
                setPrimerNombre(res.p_nombre);
                setSegundoNombre(res.s_nombre);
                setTipoDocumento(res.tipo_documento);
                setDocumento(res.documento);
                setFechaExpedicion(new Date(res.fecha_expedicion.split('-')[0], res.fecha_expedicion.split('-')[1] - 1, res.fecha_expedicion.split('-')[2]))
                setLugarExpedicion(municipios.filter(m => m.daneMuni === res.lugar_expedicion)[0].municipio + ' - ' + municipios.filter(m => m.daneMuni === res.lugar_expedicion)[0].departamento);
                setFechaNacimiento(new Date(res.fecha_nacimiento.split('-')[0], res.fecha_nacimiento.split('-')[1] - 1, res.fecha_nacimiento.split('-')[2]));
                setCiudad(municipios.filter(m => m.daneMuni === res.ciudad_residencia)[0].municipio + ' - ' + municipios.filter(m => m.daneMuni === res.ciudad_residencia)[0].departamento);
                setCelular(String(res.celular));
                setCelular2(String(res.celular_alternativo));
                setTelefono(res.telefono);
                setCorreo(res.correo);
                setDireccion(res.direccion);
                setComplementoDireccion(res.complemento_direccion);
                setBarrio(res.barrio);
                setLocalidad(res.localidad);

                setNivel(res.ultimo_nivel_estudios);
                setArea(res.area_estudios);
                setEstado(res.estado_estudios);
                setFechaInicio(new Date(res.fecha_inicio_estudios.split('-')[0], res.fecha_inicio_estudios.split('-')[1] - 1, res.fecha_inicio_estudios.split('-')[2]));
                if (res.fecha_finalizacion_estudios !== "1999-01-01") { setFechaFin(new Date(res.fecha_finalizacion_estudios.split('-')[0], res.fecha_finalizacion_estudios.split('-')[1] - 1, res.fecha_finalizacion_estudios.split('-')[2])); }
                setCentroEducativo(res.centro_educativo);
                setEstudioActual(res.estudia_actualmente);
                setModalidad(res.modalidad);
                setHorario(res.horario);

                setCirugia(res.tuvo_cirugia);
                setTipoCirugia(res.que_cirugia);
                setMedicamento(res.toma_medicamento);
                setTipoMedicamento(res.que_medicamento);
                for (let i = 0; i < res.nucleo_familiar.length; i++) {
                    nombreFamiliar[i] = res.nucleo_familiar[i].nombre_familiar; setNombreFamiliar([...nombreFamiliar]);
                    parentesco[i] = res.nucleo_familiar[i].parentesco; setParentesco([...parentesco]);
                    telefonoFamiliar[i] = res.nucleo_familiar[i].telefono_familiar; setTelefonoFamiliar([...telefonoFamiliar]);
                }
                setNombreReferencia(res.nombre_referencia_1);
                setRelacionReferencia(res.relacion_referencia_1);
                setTelefonoReferencia(res.telefono_referencia_1);
                setNombreReferencia2(res.nombre_referencia_2);
                setRelacionReferencia2(res.relacion_referencia_2);
                setTelefonoReferencia2(res.telefono_referencia_2);
                setEps(res.eps);
                setFondoPension(res.pension);
                setEstadoAfiliacion(res.esta_afiliado);
                setTipoAfiliacion(res.tipo_afiliacion);

                setShowBackdrop(false);
            }
        });
    }

    async function fetchExperienciaLaboral(cc, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/experiencia_persona_seleccion=${cc}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchExperienciaLaboral(cc, newAccess);
                }
            }
            else {
                setExperienciaLaboral(res);
                setShowBackdrop(false);
            }
        });
    }

    async function putInformation(cc, access = accessToken) {
        let municipio_exp = lugarExpedicion.split(' - ')[0];
        let departamento_exp = lugarExpedicion.split(' - ')[1];
        let municipio_res = ciudad.split(' - ')[0];
        let departamento_res = ciudad.split(' - ')[1];
        let res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/persona_seleccion=${cedula}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify(
                {
                    'p_apellido': primerApellido,
                    's_apellido': segundoApellido,
                    'p_nombre': primerNombre,
                    's_nombre': segundoNombre,
                    'tipo_documento': tipoDocumento === 'Cédula de Ciudadanía' ? 0 : 1,
                    'documento': parseInt(documento),
                    'fecha_expedicion': fechaExpedicion.toISOString().split('T')[0],
                    'lugar_expedicion': municipios.filter(m => m.municipio === municipio_exp && m.departamento === departamento_exp)[0].daneMuni,
                    'fecha_nacimiento': fechaNacimiento.toISOString().split('T')[0],
                    'celular': celular,
                    'celular_alternativo': celular2,
                    'telefono': telefono === '' ? 0 : telefono,
                    'correo': correo,
                    'ciudad_residencia': municipios.filter(m => m.municipio === municipio_res && m.departamento === departamento_res)[0].daneMuni,
                    'direccion': {
                        'descripcion': direccion,
                    },
                    'complemento_direccion': complementoDireccion,
                    'barrio': barrio,
                    'localidad': localidad,
                    'ultimo_nivel_estudios': niveles.filter(n => n.nivel === nivel)[0].cod,
                    'area_estudios': areas.filter(a => a.area === area)[0].cod,
                    'estado_estudios': estado === 'Culminado' ? 0 : (estado === 'En curso' ? 1 : (estado === 'Abandonado' ? 2 : 3)),
                    'fecha_inicio_estudios': fechaInicio.toISOString().split('T')[0],
                    'fecha_finalizacion_estudios': estado !== 'Culminado' ? '1999-01-01' : fechaFin.toISOString().split('T')[0],
                    'centro_educativo': centroEducativo,
                    'estudia_actualmente': estudioActual === 'Sí',
                    'modalidad': estudioActual === 'No' ? 3 : (modalidad === 'Presencial' ? 0 : (modalidad === 'Virtual' ? 1 : 2)),
                    'horario': estudioActual === 'No' ? 8 : horarios.filter(h => h.horario === horario)[0].cod,
                    'tuvo_cirugia': cirugia === 'Sí',
                    'que_cirugia': tipoCirugia,
                    'toma_medicamento': medicamento === 'Sí',
                    'que_medicamento': tipoMedicamento,
                    'nucleo_familiar': nombreFamiliar.map((v, i) => (
                        {
                            'nombre_familiar': nombreFamiliar[i],
                            'parentesco': parentesco[i],
                            'telefono_familiar': telefonoFamiliar[i]
                        }
                    )),
                    'nombre_referencia_1': nombreReferencia,
                    'relacion_referencia_1': relacionReferencia,
                    'telefono_referencia_1': telefonoReferencia,
                    'nombre_referencia_2': nombreReferencia2,
                    'relacion_referencia_2': relacionReferencia2,
                    'telefono_referencia_2': telefonoReferencia2,
                    'eps': epss.filter(e => e.eps === eps)[0].cod,
                    'pension': fondosPension.filter(p => p.pension === fondoPension)[0].cod,
                    'esta_afiliado': estadoAfiliacion,
                    'tipo_afiliacion': tipoAfiliacion === null ? null : tipoAfiliaciones.filter(t => t.tipo === tipoAfiliacion)[0].cod
                }
            )
        })
        if (res.ok) {
            setShowBackdrop(false);
            setMessage(`Se han actualizado los datos personales de ${nombre}.`);
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            limpiar();
            fetchDatosPersonales(cedula);
        } else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putInformation(cc, newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }

    }

    async function descargarTratamientoDatos(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/tratamiento_datos/persona_seleccion=${parseInt(documento)}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            method: 'GET'
        });
        if (res.ok) {
            const filename = `${documento} - Tratamiento de datos.pdf`
            const blob = await res.blob();
            console.log(blob)
            
            saveAs(blob, filename);
            setShowBackdrop(false);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);
                        
                        descargarTratamientoDatos(newAccess);
                    }
                }
            });
        }
    }

    const expandirDatos = () => {
        if (formatoColapso.every(v => v === true)) { setFormatoColapso(Array(4).fill(false)) } else {
            if (experienciaLaboral.length === 0) { formatoColapso[0] = true; formatoColapso[1] = true; formatoColapso[3] = true; setFormatoColapso([...formatoColapso]) }
            else { setFormatoColapso(Array(4).fill(true)) }
        }
    }

    return (
        <Dialog
            open={open}
            TransitionProps={{
                onEnter: () => { fetchDatosPersonales(cedula); fetchExperienciaLaboral(cedula); }
            }}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='lg'
        > 
            <DialogTitle disableTypography style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={4}
                >
                    <Grid item xs={9} md={10}>
                        <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Está viendo el formato de {nombre}</Typography>
                    </Grid>
                    <Grid item xs={3} md={2}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Descargar autorización de tratamiento de datos">
                                <IconButton onClick={() => descargarTratamientoDatos()}>
                                    <HowToRegIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title={formatoColapso.every(v => v === true) ? "Contraer" : "Expandir"}>
                                <IconButton
                                    onClick={() => {
                                        expandirDatos()
                                    }}>
                                    {formatoColapso.every(v => v === true) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Imprimir">
                                <IconButton onClick={() => window.print()}>
                                    <PrintIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Cerrar">
                                <IconButton onClick={() => limpiar()}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </div>

                    </Grid>
                </Grid>
            </DialogTitle>

            <Divider />

            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                <CardHeader
                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                    title={
                        <Typography variant='h6' align='center' style={{ fontWeight: 550 }}>Formato de registro</Typography>
                    }
                />


                <DialogoDatosPersonales
                    theme={theme}
                    point={point}
                    formatoColapso={[formatoColapso, setFormatoColapso]}
                    readOnly={readOnly}
                    primerApellido={[primerApellido, setPrimerApellido]}
                    segundoApellido={[segundoApellido, setSegundoApellido]}
                    primerNombre={[primerNombre, setPrimerNombre]}
                    segundoNombre={[segundoNombre, setSegundoNombre]}
                    tipoDocumento={[tipoDocumento, setTipoDocumento]}
                    documento={documento}
                    fechaExpedicion={[fechaExpedicion, setFechaExpedicion]}
                    lugarExpedicion={[lugarExpedicion, setLugarExpedicion]}
                    fechaNacimiento={[fechaNacimiento, setFechaNacimiento]}
                    ciudad={[ciudad, setCiudad]}
                    celular={[celular, setCelular]}
                    celular2={[celular2, setCelular2]}
                    telefono={[telefono, setTelefono]}
                    correo={[correo, setCorreo]}
                    direccion={[direccion, setDireccion]}
                    complementoDireccion={[complementoDireccion, setComplementoDireccion]}
                    barrio={[barrio, setBarrio]}
                    localidad={[localidad, setLocalidad]}
                    errores={[errores, setErrores]}
                />

                <DialogoEstudiosRealizados
                    theme={theme}
                    classes={classes}
                    formatoColapso={[formatoColapso, setFormatoColapso]}
                    readOnly={readOnly}
                    nivel={[nivel, setNivel]}
                    area={[area, setArea]}
                    estado={[estado, setEstado]}
                    fechaInicio={[fechaInicio, setFechaInicio]}
                    fechaFin={[fechaFin, setFechaFin]}
                    centroEducativo={[centroEducativo, setCentroEducativo]}
                    estudioActual={[estudioActual, setEstudioActual]}
                    modalidad={[modalidad, setModalidad]}
                    horario={[horario, setHorario]}
                    errores={[errores, setErrores]}
                />

                <DialogoExperienciaLaboral
                    theme={theme}
                    datosPersonales={datosPersonales}
                    experienciaLaboral={experienciaLaboral}
                    setMessage={setMessage}
                    setSeverity={setSeverity}
                    setSnack={setSnack}
                    API_DEFAULT={API_DEFAULT}
                    accessToken={accessToken}
                    setShowBackdrop={setShowBackdrop}
                    formatoColapso={[formatoColapso, setFormatoColapso]}
                />

                <DialogoInformacionPersonal
                    theme={theme}
                    formatoColapso={[formatoColapso, setFormatoColapso]}
                    readOnly={readOnly}
                    cirugia={[cirugia, setCirugia]}
                    tipoCirugia={[tipoCirugia, setTipoCirugia]}
                    medicamento={[medicamento, setMedicamento]}
                    tipoMedicamento={[tipoMedicamento, setTipoMedicamento]}
                    nombreFamiliar={[nombreFamiliar, setNombreFamiliar]}
                    parentesco={[parentesco, setParentesco]}
                    telefonoFamiliar={[telefonoFamiliar, setTelefonoFamiliar]}
                    nombreReferencia={[nombreReferencia, setNombreReferencia]}
                    relacionReferencia={[relacionReferencia, setRelacionReferencia]}
                    telefonoReferencia={[telefonoReferencia, setTelefonoReferencia]}
                    nombreReferencia2={[nombreReferencia2, setNombreReferencia2]}
                    relacionReferencia2={[relacionReferencia2, setRelacionReferencia2]}
                    telefonoReferencia2={[telefonoReferencia2, setTelefonoReferencia2]}
                    eps={[eps, setEps]}
                    fondoPension={[fondoPension, setFondoPension]}
                    estadoAfiliacion={[estadoAfiliacion, setEstadoAfiliacion]}
                    tipoAfiliacion={[tipoAfiliacion, setTipoAfiliacion]}
                    errores={[errores, setErrores]}
                />

            </DialogContent>
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button size='small' variant='contained' color='primary'
                    startIcon={readOnly ? <EditIcon /> : <SaveIcon />}
                    onClick={() => {
                        expandirDatos();
                        setReadOnly(!readOnly);
                        if (!readOnly) {
                            validar()
                        }
                    }}
                    className={classes.conditionalRoleVisibility}
                >
                    <Typography variant='button'> {readOnly ? "Editar datos" : "Guardar cambios"}</Typography>
                </Button>
                <Button size='small' variant='contained' color='secondary' onClick={() => limpiar()} >
                    Cancelar
                </Button>
            </DialogActions>

        </Dialog>
    );

}

export default DialogoHojaDeVida;