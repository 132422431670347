import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Divider,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TablePagination,
    Container,
    IconButton,
    Tooltip,
    List,
    ListItem,
    Button
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import NumberFormat from 'react-number-format';
import { Autocomplete } from '@material-ui/lab';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";
import DateFnsUtils from '@date-io/date-fns';

import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import BookIcon from '@material-ui/icons/Book';
import GetAppIcon from '@material-ui/icons/GetApp';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import AttachFileIcon from '@material-ui/icons/AttachFile';
// import SettingsIcon from '@material-ui/icons/Settings';

import municipios from '../../data/municipios'
import cargos from '../../data/cargos'
import estadosContratacion from '../../data/estadosContratacion'
import aprobacionDocs from '../../data/aprobacionDocs';
// import { saveAs } from 'file-saver';

import DialogoHojaDeVida from './components/HojaDeVida/DialogoHojaDeVida'
import DialogoDocumentos from './components/DialogoDocumentos';
import DialogoFiltrosContratacion from './components/DialogoFiltrosContratacion';
import DialogoEditarEstado from './components/DialogoEditarEstado';
import DialogoDescargas from './components/DialogoDescargas';

import DialogoEncuestadorPresencial from '../Formatos/DialogoEncuestadorPresencial';
import DialogoEncuestadorTelefonicoCasa from '../Formatos/DialogoEncuestadorTelefonicoCasa';
import DialogoAuditorPresencial from '../Formatos/DialogoAuditorPresencial';
import DialogoCoordinadorCampo from '../Formatos/DialogoCoordinadorCampo';
import DialogoSupervisorTelefonicoPresencial from '../Formatos/DialogoSupervisorTelefonicoPresencial';


const useStyles = makeStyles({
    table: {
        minWidth: 1200,
    },
    menuItem: {
        fontSize: 14
    },
    cardTitle: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    checkButton: {
        color: '#49be25',
        marginRight: 4,
        size: 'small'
    },
    clearButton: {
        color: '#ff0006',
        marginRight: 4,
        size: 'small'
    },
    warningHelperText: {
        '& p': {
            color: 'blue',
        },
        backgroundAutocomplete: {
            backgroundColor: '#E0E0E0'
        }
    },
    conditionalRoleVisibility:{
        visibility: (localStorage.getItem('rol') === 'gestor'? 'visible':'hidden')
    },
    conditionalRoleDisplay:{
        display: (localStorage.getItem('rol') === 'gestor'? 'inline':'none')
    }

});

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} color='inherit'>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

function Tabla(props) {
    const { theme,
        point,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        validRole,
        setShowBackdrop,
        setMessage,
        setSeverity,
        setSnack } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const classes = useStyles();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);

    const [filtros, setFiltros] = useState(Array(14).fill(''));
    const [filtering, setFiltering] = useState(false);
    const [optionsCuidad, setOptionsCuidad] = useState(municipios.map(municipio => municipio.municipio));
    const [optionsDepartamento, setOptionsDepartamento] = useState(municipios.map(municipio => municipio.departamento));

    const [openEditarEstado, setOpenEditarEstado] = useState(false);
    const [openFormatoRegistro, setOpenFormatoRegistro] = useState(false);
    const [openCargo, setOpenCargo] = useState(Array(5).fill(false))
    const [openDocumentos, setOpenDocumentos] = useState(false);
    const [openEstado, setOpenEstado] = useState(false);
    const [openDescargas, setOpenDescargas] = useState(false);

    const [datos, setDatos] = useState([]);
    const [formato, setFormato] = useState([]);
    const [specs, setSpecs] = useState(null);
    const [archivos, setArchivos] = useState(Array(22).fill([]));

    const [postulaciones, setPostulaciones] = useState([]);
    const [nombreActual, setNombreActual] = useState('');
    const [cedulaActual, setCedulaActual] = useState('');
    const [correoActual, setCorreoActual] = useState('');
    const [estadoActual, setEstadoActual] = useState('');
    const [celularActual, setCelularActual] = useState('');
    const [ciudadActual, setCiudadActual] = useState('');
    const [hayContratos, setHayContratos] = useState(false);

    const clearDisabled = !filtros.some((v, i) => {
        if (i > 0 && i < 6 && v !== '') return true;
        else if (([6, 10].includes(i)) && (v !== 'Todas' && v !== '')) return true;
        else if (([0, 7, 8, 9, 11].includes(i)) && (v !== 'Todos' && v !== '')) return true;
        else if (([12, 13].includes(i)) && (v !== '')) return true;
        return false;
    });

    const clearFilters = () => {
        for (let i = 1; i < 6; i++) {
            filtros[i] = '';
        }
        filtros[0] = 'Todos';
        filtros[6] = 'Todas'
        filtros[7] = 'Todos'
        filtros[8] = 'Todos'
        filtros[9] = 'Todos'
        filtros[10] = 'Todas'
        filtros[11] = 'Todos'
        filtros[12] = ''
        filtros[13] = ''
        setFiltros([...filtros]);
        setOptionsCuidad(municipios.map(municipio => municipio.municipio));
        setOptionsDepartamento(municipios.map(municipio => municipio.departamento));
    }

    const fetchPersonal = async (access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/inicio=${page * rowsPerPage}&fin=${page * rowsPerPage + rowsPerPage}&docs=${filtros[0] === 'Todos' || filtros[0] === '' ? 'all-elements' : filtros[0]}&cedula=${filtros[1] === '' ? 'all-elements' : filtros[1]}&nombre=${filtros[2] === '' ? 'all-elements' : filtros[2]}&celular=${filtros[3] === '' ? 'all-elements' : filtros[3]}&correo=${filtros[4] === '' ? 'all-elements' : filtros[4]}&edad=${filtros[5] === '' ? 'all-elements' : filtros[5]}&ciudad=${filtros[6].includes('Todas') || filtros[6] === '' ? 'all-elements' : (filtros[7] === 'Todos' ? municipios.filter(m => m.municipio === filtros[6])[0].daneMuni : municipios.filter(m => m.municipio === filtros[6] && m.departamento === filtros[7])[0].daneMuni)}&departamento=${filtros[7].includes('Todos') || filtros[7] === '' ? 'all-elements' : municipios.filter(m => m.departamento === filtros[7])[0].daneDep}&escolaridad=${filtros[8] === 'Todos' || filtros[8] === '' ? 'all-elements' : filtros[8]}&perfil=${filtros[9] === 'Todos' || filtros[9] === '' ? 'all-elements' : filtros[9]}&disponibilidad=${filtros[10] === 'Todas' || filtros[10] === '' ? 'all-elements' : filtros[10]}&estado=${filtros[11] === 'Todos' || filtros[11] === '' ? 'all-elements' : filtros[11]}&fecha_creado=${filtros[12] === '' ? 'all-elements' : filtros[12]}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchPersonal(newAccess);
                }
            }
            else {
                setCount(res[1]);
                res = res[0]
                setDatos(res);
                setShowBackdrop(false);
            }
        });
    }

    async function fetchPorcentajeRevision(cc, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/porcentaje_revision/persona_seleccion=${cc}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchPorcentajeRevision(cc, newAccess);
                }
            }
            else {
                setPostulaciones(res);
                setShowBackdrop(false);
            }
        });
    }

    async function fetchFormato(cc, formato, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/formato/persona_seleccion=${cc}&perfil_postulacion=${formato}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchFormato(cc, formato, newAccess);
                }
            }
            else {
                setFormato(res.cuerpo_formato);
                if (res.specs !== null) { setSpecs(`https://sistema-cnc.storage.googleapis.com/${res.specs.split('https://storage.googleapis.com/sistema-cnc/')[1]}`) }
                setShowBackdrop(false);
            }
        });
    }
    // async function funcionesEspeciales(access = accessToken) {
    //     setShowBackdrop(true);
    //     const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/funciones_especiales/`, {
    //         headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
    //     });
    //     if (res.ok) {
    //         const date = new Date().toISOString().split('T')[0]
    //         const filename = `Gestor de selección ${date}.xlsx`
    //         const blob = await res.blob();

    //         saveAs(blob, filename);
    //         setShowBackdrop(false);
    //     }
    //     else {
    //         res.json().then(async res => {
    //             if (res['code'] === 'token_not_valid') {
    //                 let newAccess = await getAccessTokenWithRefresh();
    //                 if (newAccess) {
    //                     setAccessToken(newAccess);
    //                     localStorage.setItem('access', newAccess);

    //                     funcionesEspeciales(newAccess);
    //                 }
    //             }
    //         });
    //     }
    // }

    useEffect(() => {
        for (let i = 1; i < 6; i++) {
            filtros[i] = '';
        }
        filtros[0] = 'Todos';
        filtros[6] = 'Todas'
        filtros[7] = 'Todos'
        filtros[8] = 'Todos'
        filtros[9] = 'Todos'
        filtros[10] = 'Todas'
        filtros[11] = 'Todos'
        filtros[12] = ''
        filtros[13] = ''
        setFiltros([...filtros]);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { if (validRole) { fetchPersonal() } }, [validRole]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { if (validRole) { fetchPersonal() } }, [page, filtering, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

    function Cargos({ value, cc, nombre }) {
        const puestos = value.split("\n");
        return (
            <List>
                {puestos.map((puesto, i) => (
                    <ListItem key={i}>{
                        <Typography style={{ color: 'primary', fontWeight: 500, fontSize: 14, cursor: 'pointer', paddingRight: theme.spacing(1.2) }} onClick={() => {
                            fetchFormato(cc, cargos.filter(c => c.cargo === puesto)[0].cod)
                            openCargo[cargos.filter(c => c.cargo === puesto)[0].cod] = true;
                            setOpenCargo([...openCargo]);
                            setNombreActual(nombre)
                            fetchPorcentajeRevision(cc);
                            setCedulaActual(cc);
                        }}>{puesto}</Typography>
                    }
                    </ListItem>
                ))}
            </List>
        );
    }
    return (
        <div className='Table-body'>
            <Container component='main' maxWidth='xl'>
                <Card>
                    <CardHeader
                        style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}
                        classes={{
                            title: classes.cardTitle
                        }}
                        title={
                            <React.Fragment>
                                <Typography variant='h6'>Tabla de Personal</Typography>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <Tooltip title="Funciones especiales">
                                        <IconButton size='small' style={{ marginRight: theme.spacing(2) }} onClick={() => { funcionesEspeciales() }}>
                                            <SettingsIcon color='primary' />
                                        </IconButton>
                                    </Tooltip> */}
                                    <Tooltip title="Descargas">
                                        <IconButton size='small' style={{ marginRight: theme.spacing(2) }} onClick={() => { setOpenDescargas(true) }}>
                                            <GetAppIcon color='primary' />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </React.Fragment>
                        }
                    />
                    <Divider />
                    <CardContent>
                        <TableContainer component={Paper} variant='outlined'>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'></TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Cédula</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Nombre Completo</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Celular</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Correo electrónico</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>Edad</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Ciudad de residencia</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Departamento de residencia {((filtros[6] !== 'Todas' || filtros[6] === '') && (optionsDepartamento.length > 1)) ? <PriorityHighIcon color='primary'> </PriorityHighIcon> : null}</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Nivel de escolaridad</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Cargo al que se postuló</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Disponibilidad de tiempo</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Estado</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Fecha registro</TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>Fecha creación contrato</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} component='th' scope='row' align='center'>
                                            <div style={{ display: 'flex' }}>
                                                <IconButton
                                                    className={classes.checkButton}
                                                    size='small'
                                                    disabled={clearDisabled || filtering}
                                                    onClick={() => {
                                                        setPage(0);
                                                        setFiltering(true);
                                                        setOptionsCuidad(municipios.map(municipio => municipio.municipio));
                                                        setOptionsDepartamento(municipios.map(municipio => municipio.departamento));
                                                    }}
                                                >
                                                    <CheckIcon />
                                                </IconButton>
                                                <IconButton
                                                    className={classes.clearButton}
                                                    size='small'
                                                    onClick={() => {
                                                        clearFilters();
                                                        if (filtering) { setPage(0); setFiltering(false); }
                                                    }}
                                                // disabled={clearDisabled}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                                {/* Aprobación de documentos */}
                                                <Autocomplete
                                                    id="estado-docs"
                                                    value={filtros[0]}
                                                    onChange={(e, v) => { filtros[0] = v; setFiltros([...filtros]) }}
                                                    options={
                                                        aprobacionDocs.map(option => option.estado)}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter') {
                                                            setPage(0);
                                                            setFiltering(true);
                                                        }
                                                    }}
                                                    noOptionsText='No hay coincidencias'
                                                    disableClearable={true}
                                                    renderOption={(option) => {
                                                        if (option === 'Todos')
                                                            return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                        else
                                                            return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                    }}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            inputProps={{
                                                                ...params.inputProps,
                                                                style: {
                                                                    fontSize: 14,
                                                                    padding: '1.69px 3.69px'
                                                                }
                                                            }}
                                                            size='small'
                                                            variant='outlined'
                                                            fullWidth
                                                            style={{ minWidth: 120 }}
                                                        />
                                                    )}
                                                    disabled={filtering}
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            {/* Cédula */}
                                            <NumberFormat
                                                customInput={TextField}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                onFocus={(e) => e.target.select()}
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setPage(0);
                                                        setFiltering(true);
                                                    }
                                                }}
                                                value={filtros[1]}
                                                onValueChange={(e) => { filtros[1] = e.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            {/* Nombre */}
                                            <TextField
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px'
                                                    }
                                                }}
                                                value={filtros[2]}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setPage(0);
                                                        setFiltering(true);
                                                    }
                                                }}
                                                onChange={(e) => { filtros[2] = e.target.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            {/* Celular */}
                                            <NumberFormat
                                                customInput={TextField}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                onFocus={(e) => e.target.select()}
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                                value={filtros[3]}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setPage(0);
                                                        setFiltering(true);
                                                    }
                                                }}
                                                onValueChange={(e) => { filtros[3] = e.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            {/* Correo */}
                                            <TextField
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px'
                                                    }
                                                }}
                                                value={filtros[4]}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setPage(0);
                                                        setFiltering(true);
                                                    }
                                                }}
                                                onChange={(e) => { filtros[4] = e.target.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            {/* Edad */}
                                            <NumberFormat
                                                customInput={TextField}
                                                decimalSeparator={false}
                                                allowNegative={false}
                                                onFocus={(e) => e.target.select()}
                                                inputProps={{
                                                    style: {
                                                        fontSize: 14,
                                                        padding: '7.69px 9.69px',
                                                        textAlign: 'right'
                                                    }
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setPage(0);
                                                        setFiltering(true);
                                                    }
                                                }}
                                                value={filtros[5]}
                                                onValueChange={(e) => { filtros[5] = e.value; setFiltros([...filtros]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            {/* Ciudad */}
                                            <Autocomplete
                                                value={filtros[6]}
                                                onChange={(e, v) => { filtros[6] = v; setFiltros([...filtros]); setOptionsDepartamento(municipios.filter(o => o.municipio === filtros[6]).map(municipio => municipio.departamento)); }}
                                                options={optionsCuidad.filter((item, i, ar) => ar.indexOf(item) === i)}
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todas')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setPage(0);
                                                        setFiltering(true);
                                                    }
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                                disabled={filtering}
                                            />
                                        </TableCell>

                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            {/* Departamento */}
                                            <Autocomplete
                                                value={filtros[7]}
                                                onChange={(e, v) => { filtros[7] = v; setFiltros([...filtros]); setOptionsCuidad(municipios.filter(o => o.departamento === filtros[7]).map(municipio => municipio.municipio)) }}
                                                options={optionsDepartamento.filter((item, i, ar) => ar.indexOf(item) === i)}
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setPage(0);
                                                        setFiltering(true);
                                                    }
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            {/* Nivel de escolaridad  */}
                                            <Autocomplete
                                                value={filtros[8]}
                                                onChange={(e, v) => { filtros[8] = v; setFiltros([...filtros]) }}
                                                options={
                                                    ['Todos', 'Preescolar', 'Básica Primaria (1° - 5°)', 'Básica Secundaria (6° - 9°)', 'Media  (10° - 13°)',
                                                        'Técnico Laboral', 'Formación Técnica Profesional', 'Tecnológica', 'Universitaria', 'Especialización',
                                                        'Maestría', 'Doctorado']
                                                }
                                                // getOptionSelected={(option, value) => option.value === value.value}
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setPage(0);
                                                        setFiltering(true);
                                                    }
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            {/* Cargos al que se postula  */}
                                            <Autocomplete
                                                value={filtros[9]}
                                                onChange={(e, v) => { filtros[9] = v; setFiltros([...filtros]) }}
                                                options={
                                                    cargos.map(c => c.cargo)
                                                }
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setPage(0);
                                                        setFiltering(true);
                                                    }
                                                }}
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            {/* Disponibilidad de tiempo */}
                                            <Autocomplete
                                                value={filtros[10]}
                                                onChange={(e, v) => { filtros[10] = v; setFiltros([...filtros]) }}
                                                options={
                                                    ['Todas', 'Por horas', 'Tiempo completo',
                                                        'Medio tiempo', 'Solo fines de semana']
                                                }
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setPage(0);
                                                        setFiltering(true);
                                                    }
                                                }}
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todas')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                            {/* Estados de contratación  */}
                                            <Autocomplete
                                                value={filtros[11]}
                                                onChange={(e, v) => { filtros[11] = v; setFiltros([...filtros]) }}
                                                options={
                                                    estadosContratacion.map(e => e.estado)
                                                }
                                                onKeyPress={(e) => {
                                                    if (e.key === 'Enter') {
                                                        setPage(0);
                                                        setFiltering(true);
                                                    }
                                                }}
                                                noOptionsText='No hay coincidencias'
                                                disableClearable={true}
                                                renderOption={(option) => {
                                                    if (option === 'Todos')
                                                        return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                    else
                                                        return (<span style={{ fontSize: 14 }}>{option}</span>);
                                                }}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            style: {
                                                                fontSize: 14,
                                                                padding: '1.69px 3.69px'
                                                            }
                                                        }}
                                                        size='small'
                                                        variant='outlined'
                                                        fullWidth
                                                        style={{ minWidth: 120 }}
                                                    />
                                                )}
                                                disabled={filtering}
                                            />
                                        </TableCell>
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            {/* Fecha de creación de la persona  */}
                                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    onFocus={(e) => e.target.select()}
                                                    inputProps={{
                                                        style: {
                                                            fontSize: 14,
                                                            padding: '7.69px 9.69px',
                                                            textAlign: 'center'
                                                        }
                                                    }}
                                                    value={(filtros[12] === '') ? undefined : filtros[12] + 'T00:00:00-05:00'}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                    disabled={filtering}
                                                    format="yyyy-MM-dd"
                                                    onChange={
                                                        (date) => {
                                                            filtros[12] = date.getFullYear() + '-' + ((date.getMonth() < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + ((date.getDate() < 10) ? '0' + (date.getDate()) : (date.getDate()));
                                                            setFiltros([...filtros]);
                                                        }
                                                    }
                                                    disableFuture
                                                />
                                            </MuiPickersUtilsProvider>
                                        </TableCell>
                                        {/* Fecha de creación del último contrato */}
                                        <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='right'>
                                            <MuiPickersUtilsProvider locale={esLocale} utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    disableToolbar
                                                    onFocus={(e) => e.target.select()}
                                                    inputProps={{
                                                        style: {
                                                            fontSize: 14,
                                                            padding: '7.69px 9.69px',
                                                            textAlign: 'right'
                                                        }
                                                    }}
                                                    value={(filtros[13] === '') ? undefined : filtros[13] + 'T00:00:00-05:00'}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                    disabled={true}
                                                    format="yyyy-MM-dd"
                                                    onChange={
                                                        (date) => {
                                                            filtros[13] = date.getFullYear() + '-' + ((date.getMonth() < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + ((date.getDate() < 10) ? '0' + (date.getDate()) : (date.getDate()));
                                                            setFiltros([...filtros]);
                                                        }
                                                    }
                                                    disableFuture
                                                />
                                            </MuiPickersUtilsProvider>
                                        </TableCell>
                                    </TableRow>
                                    {datos.map((row) => (
                                        <TableRow key={row.cedula}>
                                            <TableCell component='th' scope='row' align='left'>
                                                <div style={{ display: 'flex' }}>
                                                    <Tooltip title={'Ver datos'}>
                                                        <span>
                                                            <Button
                                                                onClick={() => {
                                                                    setNombreActual(row.nombre);
                                                                    setCedulaActual(row.cedula);
                                                                    setOpenFormatoRegistro(true);
                                                                }}
                                                                color='primary'
                                                            >
                                                                <BookIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>

                                                    <Tooltip title={'Ver documentos'}>
                                                        <span>
                                                            <Button
                                                                onClick={() => {
                                                                    fetchPorcentajeRevision(row.cedula);
                                                                    setNombreActual(row.nombre);
                                                                    setCedulaActual(row.cedula);
                                                                    setCorreoActual(row.correo);
                                                                    setOpenDocumentos(true);
                                                                    setEstadoActual(row.estado_contratacion);

                                                                }}
                                                                color='primary'
                                                            >
                                                                <AttachFileIcon />
                                                            </Button>
                                                            <Typography align='center' noWrap style={{ fontSize: 10 }}>{row.aprobado_documentos}</Typography>
                                                        </span>
                                                    </Tooltip>
                                                    <Tooltip title={'Ver filtros de contratación'}>
                                                        <span>
                                                            <Button
                                                                onClick={() => {
                                                                    fetchPorcentajeRevision(row.cedula);
                                                                    setNombreActual(row.nombre);
                                                                    setCedulaActual(row.cedula);
                                                                    setOpenEstado(true);

                                                                }}
                                                                color='primary'
                                                            >
                                                                <AssignmentTurnedInIcon />
                                                            </Button>
                                                        </span>
                                                    </Tooltip>
                                                </div>

                                            </TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='right'> {row.cedula} </TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14, minWidth: 200 }} align='left'>{row.nombre}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='right'>{row.celular}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14, minWidth: 150, wordBreak: 'break-word' }} align='left'>{row.correo}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='right'>{row.edad}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{municipios.filter(m => m.daneMuni === row.ciudad_residencia)[0].municipio}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{municipios.filter(m => m.daneMuni === row.ciudad_residencia)[0].departamento}</TableCell>

                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.nivel_escolaridad}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{<Cargos value={row.postulaciones} cc={row.cedula} nombre={row.nombre} />}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.disponibilidad_tiempo}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>
                                                <Typography
                                                    style={{ color: 'primary', fontWeight: 500, fontSize: 14, cursor: 'pointer', paddingRight: theme.spacing(1.2) }}
                                                    onClick={() => {
                                                        
                                                        setOpenEditarEstado(true);
                                                        setNombreActual(row.nombre);
                                                        setCedulaActual(row.cedula);
                                                        setCorreoActual(row.correo);
                                                        setCelularActual(row.celular);
                                                        setCiudadActual(row.ciudad_residencia);
                                                        setEstadoActual(row.estado_contratacion);
                                                        setHayContratos(row.fecha_creacion_contrato === "Vacío" ? false : true);
                                                        
                                                    }}  
                                                > {row.estado_contratacion}
                                                </Typography>
                                            </TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{new Date(row.fecha_creado).toLocaleDateString("es-ES")}</TableCell>
                                            <TableCell style={{ paddingTop: 14, paddingBottom: 14 }} align='left'>{row.fecha_creacion_contrato === "Vacío" ? "Vacío" : new Date(row.fecha_creacion_contrato).toLocaleDateString("es-ES")}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                component='div'
                                style={{ minWidth: 1200 }}
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={(e, n) => setPage(n)}
                                onRowsPerPageChange={(e) => { setRowsPerPage(parseInt(e.target.value, 10)); setPage(0) }}
                                SelectProps={{
                                    style: {
                                        fontSize: 14
                                    }
                                }}
                                classes={{ menuItem: classes.menuItem }}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableContainer>
                    </CardContent>
                </Card>
            </Container>
            <DialogoHojaDeVida
                theme={theme}
                point={point}
                classes={classes}
                open={[openFormatoRegistro, setOpenFormatoRegistro]}
                nombre={nombreActual}
                cedula={cedulaActual}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                setShowBackdrop={setShowBackdrop}
            />
            <DialogoDocumentos
                theme={theme}
                classes={classes}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                open={[openDocumentos, setOpenDocumentos]}
                setOpenEstado={setOpenEstado}
                nombre={nombreActual}
                cedula={cedulaActual}
                correo={correoActual}
                estadoContratacion = {estadoActual}
                fetchPersonal={fetchPersonal}
                archivos={[archivos, setArchivos]}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                setShowBackdrop={setShowBackdrop}
            />

            <DialogoFiltrosContratacion
                theme={theme}
                classes={classes}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                open={[openEstado, setOpenEstado]}
                setOpenEditarEstado={setOpenEditarEstado}
                nombre={nombreActual}
                cedula={cedulaActual}
                postulaciones={postulaciones}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                setShowBackdrop={setShowBackdrop}
                fetchPersonal={fetchPersonal}
            />
            <DialogoEncuestadorPresencial
                theme={theme}
                classes={classes}
                encuestadorPresencial={formato}
                openCargo={[openCargo, setOpenCargo]}
                nombre={nombreActual}
                cedula={cedulaActual}
                postulaciones={postulaciones}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                API_DEFAULT={API_DEFAULT}
                accessToken={accessToken}
                setShowBackdrop={setShowBackdrop}
                setOpenEstado={setOpenEstado}
            />

            <DialogoEncuestadorTelefonicoCasa
                theme={theme}
                classes={classes}
                encuestadorTelefonicoCasa={formato}
                specs={specs}
                openCargo={[openCargo, setOpenCargo]}
                nombre={nombreActual}
                cedula={cedulaActual}
                postulaciones={postulaciones}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                API_DEFAULT={API_DEFAULT}
                accessToken={accessToken}
                setShowBackdrop={setShowBackdrop}
                setOpenEstado={setOpenEstado}
            />

            <DialogoAuditorPresencial
                theme={theme}
                classes={classes}
                auditorPresencial={formato}
                openCargo={[openCargo, setOpenCargo]}
                nombre={nombreActual}
                cedula={cedulaActual}
                postulaciones={postulaciones}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                API_DEFAULT={API_DEFAULT}
                accessToken={accessToken}
                setShowBackdrop={setShowBackdrop}
                setOpenEstado={setOpenEstado}
            />

            <DialogoSupervisorTelefonicoPresencial
                theme={theme}
                classes={classes}
                supervisorTelefonicoPresencial={formato}
                openCargo={[openCargo, setOpenCargo]}
                nombre={nombreActual}
                cedula={cedulaActual}
                postulaciones={postulaciones}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                API_DEFAULT={API_DEFAULT}
                accessToken={accessToken}
                setShowBackdrop={setShowBackdrop}
                setOpenEstado={setOpenEstado}
            />
            <DialogoCoordinadorCampo
                theme={theme}
                classes={classes}
                coordinadorCampo={formato}
                openCargo={[openCargo, setOpenCargo]}
                nombre={nombreActual}
                cedula={cedulaActual}
                postulaciones={postulaciones}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                API_DEFAULT={API_DEFAULT}
                accessToken={accessToken}
                setShowBackdrop={setShowBackdrop}
                setOpenEstado={setOpenEstado}
            />
            <DialogoEditarEstado
                theme={theme}
                classes={classes}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                point={point}
                open={[openEditarEstado, setOpenEditarEstado]}
                estado={[estadoActual, setEstadoActual]}
                nombre={nombreActual}
                cedula={cedulaActual}
                correo={correoActual}
                celular={celularActual}
                ciudad={ciudadActual}
                hayContratos={hayContratos}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                setShowBackdrop={setShowBackdrop}
                fetchPersonal={fetchPersonal}
            />
            <DialogoDescargas
                theme={theme}
                classes={classes}
                API_DEFAULT={API_DEFAULT}
                accessToken={[accessToken, setAccessToken]}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                open={[openDescargas, setOpenDescargas]}
                setShowBackdrop={setShowBackdrop}
            />

        </div>
    );
}

export default Tabla;