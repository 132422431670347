const areas = [
    {nivel: "Preescolar", area: "Preescolar", cod: 0},
    {nivel: "Básica Primaria (1° - 5°)", area: "Básica Primaria (1° - 5°)", cod: 1},
    {nivel: "Básica Secundaria (6° - 9°)", area: "Básica Secundaria (6° - 9°)", cod: 2},
    {nivel: "Media  (10° - 13°)", area: "Bachillerato Académico", cod: 3},
    {nivel: "Media  (10° - 13°)", area: "Bachillerato Comercial", cod: 4},
    {nivel: "Media  (10° - 13°)", area: "Bachillerato Técnico", cod: 5},
    // Niveles superiores
    {nivel: "Superior", area: "Administración", cod: 6},
    {nivel: "Superior", area: "Agronomía", cod: 7},
    {nivel: "Superior", area: "Antropología, Artes Liberales", cod: 8},
    {nivel: "Superior", area: "Arquitectura y afines", cod: 9},
    {nivel: "Superior", area: "Artes Plásticas, Visuales afines", cod: 10},
    {nivel: "Superior", area: "Artes Representativas", cod: 11},
    {nivel: "Superior", area: "Bacteriología", cod: 12},
    {nivel: "Superior", area: "Bibliotecología, otros de Ciencias Sociales y Humanas", cod: 13},
    {nivel: "Superior", area: "Biología, Microbiología y afines", cod: 14},
    {nivel: "Superior", area: "Ciencia Política, Relaciones Internacionales", cod: 15},
    {nivel: "Superior", area: "Comunicación Social, Periodismo y afines", cod: 16},
    {nivel: "Superior", area: "Contaduría Pública", cod: 17},
    {nivel: "Superior", area: "Deportes, Educación Física y Recreación", cod: 18},
    {nivel: "Superior", area: "Derecho y afines", cod: 19},
    {nivel: "Superior", area: "Diseño", cod: 20},
    {nivel: "Superior", area: "Economía", cod: 21},
    {nivel: "Superior", area: "Educación", cod: 22},
    {nivel: "Superior", area: "Enfermería", cod: 23},
    {nivel: "Superior", area: "Filosofía, Teología y afines", cod: 24},
    {nivel: "Superior", area: "Física", cod: 25},
    {nivel: "Superior", area: "Formación relacioanda con el campo militar o policial", cod: 26},
    {nivel: "Superior", area: "Geografía, Historia", cod: 27},
    {nivel: "Superior", area: "Geología, otros programas de Ciencias Naturales", cod: 28},
    {nivel: "Superior", area: "Ingeniería Administrativa y afines", cod: 29},
    {nivel: "Superior", area: "Ingeniería Agrícola, Forestal y afines", cod: 30},
    {nivel: "Superior", area: "Ingeniería Agroindustrial, Alimentos y afines", cod: 31},
    {nivel: "Superior", area: "Ingeniería Agronómica, Pecuaria y afines", cod: 32},
    {nivel: "Superior", area: "Ingeniería Ambiental, Sanitaria y afines", cod: 33},
    {nivel: "Superior", area: "Ingeniería Biomédica y afines", cod: 34},
    {nivel: "Superior", area: "Ingeniería Civil y afines", cod: 35},
    {nivel: "Superior", area: "Ingeniería de Minas, Metalurgia y afines", cod: 36},
    {nivel: "Superior", area: "Ingeniería de Sistemas, Telemática y afines", cod: 37},
    {nivel: "Superior", area: "Ingeniería Eléctrica y afines", cod: 38},
    {nivel: "Superior", area: "Ingeniería Electrónica, Telecomunicaciones y afines", cod: 39},
    {nivel: "Superior", area: "Ingeniería Industrial y afines", cod: 40},
    {nivel: "Superior", area: "Ingeniería Mecánica y afines", cod: 41},
    {nivel: "Superior", area: "Ingeniería Química y afines", cod: 42},
    {nivel: "Superior", area: "Instrumentación Quirúrgica", cod: 43},
    {nivel: "Superior", area: "Lenguas Modernas, Literatura, Lingüistica y afines", cod: 44},
    {nivel: "Superior", area: "Matemáticas, Estadística y afines", cod: 45},
    {nivel: "Superior", area: "Medicina Veterinaria", cod: 46},
    {nivel: "Superior", area: "Música", cod: 47},
    {nivel: "Superior", area: "Nutrición y Dietética", cod: 48},
    {nivel: "Superior", area: "Odontología", cod: 49},
    {nivel: "Superior", area: "Optometría, otros programas de Ciencias de la Salud", cod: 50},
    {nivel: "Superior", area: "Otras ingenierías", cod: 51},
    {nivel: "Superior", area: "Otros programas asociados a Bellas Artes", cod: 52},
    {nivel: "Superior", area: "Psicología", cod: 53},
    {nivel: "Superior", area: "Publicidad y afines", cod: 54},
    {nivel: "Superior", area: "Química y afines", cod: 55},
    {nivel: "Superior", area: "Salud Pública", cod: 56},
    {nivel: "Superior", area: "Sociología, Trabajo Social y afines", cod: 57},
    {nivel: "Superior", area: "Terapias", cod: 58},
    {nivel: "Superior", area: "Zootecnia", cod: 59}
    ];
    
    export default areas;