import React, { useState } from 'react';
import {
    Typography,
    Grid,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    TextField,
    Radio,
    Collapse,

} from '@material-ui/core';
import { KeyboardDatePicker } from "@material-ui/pickers";

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@material-ui/lab';

import cargos from '../../../data/cargos'

function DialogoFiltrosContratacion(props) {

    const { theme,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        nombre,
        cedula,
        postulaciones,
        setShowBackdrop,
        setMessage,
        setSeverity,
        setSnack,
        setOpenEditarEstado,
        fetchPersonal,
        classes
    } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [openEstado, setOpenEstado] = props.open;

    const [notaCurso, setNotaCurso] = useState('');
    const [fechaAplicacionCurso, setFechaAplicacionCurso] = useState(null);
    const [notaEntrevista, setNotaEntrevista] = useState('');
    const [fechaEntrevista, setFechaEntrevista] = useState(null);
    const [notaLectura, setNotaLectura] = useState('');
    const [fechaLectura, setFechaLectura] = useState(null);

    const [control, setControl] = useState(Array(4).fill(''));
    const [financiera, setFinanciera] = useState(null);
    const [observacionesFinanciera, setObservacionesFinanciera] = useState('');
    const [fechaFinanciera, setFechaFinanciera] = useState('');
    const [userFinanciera, setUserFinanciera] = useState('');
    const [curso, setCurso] = useState(null);
    const [observacionesCurso, setObservacionesCurso] = useState('');
    const [fechaCurso, setFechaCurso] = useState('');
    const [userCurso, setUserCurso] = useState('');
    const [encuesta, setEncuesta] = useState(null);
    const [observacionesEncuesta, setObservacionesEncuesta] = useState('');
    const [fechaEncuesta, setFechaEncuesta] = useState('');
    const [userEncuesta, setUserEncuesta] = useState('');
    const [contratacion, setContratacion] = useState(null);
    const [observacionesContratacion, setObservacionesContratacion] = useState('');
    const [fechaContratacion, setFechaContratacion] = useState('');
    const [userContratacion, setUserContratacion] = useState('');
    const [cargo, setCargo] = useState(null);
    const [colapso, setColapso] = useState(false);

    let now = new Date().toISOString();

    async function fetchNotas(perfil, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/notas_persona_seleccion=${cedula}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchEstado(perfil, newAccess);
                }
            }
            else {
                setNotaCurso(res.curso_basico_nota === null ? '' : res.curso_basico_nota);
                setFechaAplicacionCurso(res.curso_basico_fecha === null ? null : new Date(res.curso_basico_fecha.split('-')[0], res.curso_basico_fecha.split('-')[1] - 1, res.curso_basico_fecha.split('-')[2]));

                setNotaEntrevista(res.entrevista_nota === null ? '' : res.entrevista_nota);
                setFechaEntrevista(res.entrevista_fecha === null ? null : new Date(res.entrevista_fecha.split('-')[0], res.entrevista_fecha.split('-')[1] - 1, res.entrevista_fecha.split('-')[2]));

                setNotaLectura(res.prueba_lectura_nota === null ? '' : res.prueba_lectura_nota);
                setFechaLectura(res.prueba_lectura_fecha === null ? null : new Date(res.prueba_lectura_fecha.split('-')[0], res.prueba_lectura_fecha.split('-')[1] - 1, res.prueba_lectura_fecha.split('-')[2]));

                setShowBackdrop(false);
            }
        });
    }

    async function fetchEstado(perfil, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/estado_contratacion/persona_seleccion=${cedula}&perfil_postulacion=${perfil}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchEstado(perfil, newAccess);
                }
            }
            else {
                setFinanciera(res.aprob_finan.toString());
                setObservacionesFinanciera(res.aprob_finan_obs);
                setFechaFinanciera(res.aprob_finan_fecha_mod);
                setUserFinanciera(res.aprob_finan_user_mod);

                setCurso(res.curso_basico.toString());
                setObservacionesCurso(res.curso_basico_obs);
                setFechaCurso(res.curso_basico_fecha_mod);
                setUserCurso(res.curso_basico_user_mod);

                setEncuesta(res.enc_riesgo.toString());
                setObservacionesEncuesta(res.enc_riesgo_obs);
                setFechaEncuesta(res.enc_riesgo_fecha_mod);
                setUserEncuesta(res.enc_riesgo_user_mod);

                setContratacion(res.contratacion.toString());
                setObservacionesContratacion(res.contratacion_obs);
                setFechaContratacion(res.contratacion_fecha_mod);
                setUserContratacion(res.contratacion_user_mod);

                setShowBackdrop(false);
            }
        });
    }

    async function putNotas(cedula, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/persona_seleccion=${cedula}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'curso_basico_nota': parseFloat(notaCurso),
                'curso_basico_fecha': fechaAplicacionCurso === null ? null : fechaAplicacionCurso.toISOString().split('T')[0],
                'entrevista_nota': parseFloat(notaEntrevista),
                'entrevista_fecha': fechaEntrevista === null ? null : fechaEntrevista.toISOString().split('T')[0],
                'prueba_lectura_nota': parseFloat(notaLectura),
                'prueba_lectura_fecha': fechaLectura === null ? null : fechaLectura.toISOString().split('T')[0]
            })
        });
        if (res.ok) {
            setShowBackdrop(false);
            setMessage('Se ha actualizado exitosamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putNotas(cedula, newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            })
        }
    }

    async function putInformacion(cedula, perfil, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/estado_contratacion/persona_seleccion=${cedula}&perfil_postulacion=${perfil}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'aprob_finan': parseInt(financiera),
                'aprob_finan_obs': observacionesFinanciera,
                'aprob_finan_fecha_mod': fechaFinanciera,
                'curso_basico': parseInt(curso),
                'curso_basico_obs': observacionesCurso,
                'curso_basico_fecha_mod': fechaCurso,
                'enc_riesgo': parseInt(encuesta),
                'enc_riesgo_obs': observacionesEncuesta,
                'enc_riesgo_fecha_mod': fechaEncuesta,
                'contratacion': parseInt(contratacion),
                'contratacion_obs': observacionesContratacion,
                'contratacion_fecha_mod': fechaContratacion,
                'changed_fields': control
            })
        });

        if (res.ok) {
            putNotas(cedula, perfil, access);
            fetchEstado(cargos.filter(c => c.cargo === cargo.split(' -')[0])[0].cod); setColapso(true);

        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putInformacion(cedula, perfil, newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    const limpiar = () => {
        setCargo(null);
        setColapso(false);
        setControl(Array(4).fill(''));
        setOpenEstado(false);
        fetchPersonal();
    }
    return (
        <Dialog
            open={openEstado}
            onClose={() => limpiar()}
            fullWidth={true}
            maxWidth='md'
            TransitionProps={{
                onEnter: () => { fetchNotas() }
            }}
        >
            <DialogTitle disableTypography style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={4}
                >
                    <Grid item xs={10} md={11}>
                        <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Filtros de contratación de {nombre}</Typography>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <IconButton onClick={() => { limpiar() }}>
                            <CloseIcon style={{ align: 'flex-end' }} />
                        </IconButton>
                    </Grid>

                </Grid>
            </DialogTitle>


            <Divider />
            <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={4}
                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                >
                    <Grid item md={4} xs={12}>
                        <Typography variant='body1' align='center' style={{ fontSize: 18, fontWeight: 500 }}>Seleccionar cargo:</Typography>
                    </Grid>

                    <Grid item md={7} xs={12}>
                        <Autocomplete
                            value={cargo}
                            disableClearable
                            onChange={(e, v) => { setCargo(v); fetchEstado(cargos.filter(c => c.cargo === v.split(' -')[0])[0].cod); setColapso(true) }}
                            // getOptionSelected={(option, value) => option.value === value.value}
                            options={
                                postulaciones.map(option => option.perfil_postulacion_text + " - Revisión: " + option.porcentaje_revision * 100 + "%")
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={1} xs={12}>

                    </Grid>
                </Grid>
                <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                <Grid
                    container
                    direction='row'
                    alignItems='flex-end'
                    spacing={4}
                    style={{ marginRight: theme.spacing(1) }}
                >
                    <Grid item md={4} xs={4}>
                        <Typography style={{ fontWeight: 500, marginTop: theme.spacing(2) }}>
                            Nota Curso Básico:
                        </Typography>
                        <TextField
                            value={notaCurso}
                            variant='outlined'
                            fullWidth
                            size='small'
                            onChange={(e) => { setNotaCurso(e.target.value.replace(/[^0-9.]/gm, '')); setFechaCurso(now); control[1] = 'curso_basico'; setControl([...control]) }}

                        />
                    </Grid>
                    <Grid item md={4} xs={4}>
                        <Typography style={{ fontWeight: 500, marginTop: theme.spacing(2) }}>
                            Nota Entrevista:
                        </Typography>
                        <TextField
                            value={notaEntrevista}
                            variant='outlined'
                            fullWidth
                            size='small'
                            onChange={(e) => { setNotaEntrevista(e.target.value.replace(/[^0-9.]/gm, '')); setFechaCurso(now); control[1] = 'curso_basico'; setControl([...control]) }}

                        />
                    </Grid>
                    <Grid item md={4} xs={4}>
                        <Typography style={{ fontWeight: 500, marginTop: theme.spacing(2) }}>
                            Nota Prueba de Lectura:
                        </Typography>
                        <TextField
                            value={notaLectura}
                            variant='outlined'
                            fullWidth
                            size='small'
                            onChange={(e) => { setNotaLectura(e.target.value.replace(/[^0-9.]/gm, '')); setFechaCurso(now); control[1] = 'curso_basico'; setControl([...control]) }}

                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='flex-end'
                    spacing={4}
                    style={{ marginRight: theme.spacing(1) }}
                >
                    <Grid item md={4} xs={4}>
                        <Typography style={{ fontWeight: 500, marginTop: theme.spacing(2) }}>
                            Fecha Curso Básico:
                        </Typography>
                        <KeyboardDatePicker
                            autoOk
                            inputVariant='outlined'
                            size='small'
                            variant='inline'
                            fullWidth
                            value={fechaAplicacionCurso}
                            placeholder="dd/mm/aaaa"
                            onChange={date => { setFechaAplicacionCurso(date); setFechaCurso(now); control[1] = 'curso_basico'; setControl([...control]) }}
                            format="dd/MM/yyyy"
                        />
                    </Grid>
                    <Grid item md={4} xs={4}>
                        <Typography style={{ fontWeight: 500, marginTop: theme.spacing(2) }}>
                            Fecha Entrevista:
                        </Typography>
                        <KeyboardDatePicker
                            autoOk
                            inputVariant='outlined'
                            size='small'
                            variant='inline'
                            fullWidth
                            value={fechaEntrevista}
                            placeholder="dd/mm/aaaa"
                            onChange={date => { setFechaEntrevista(date); setFechaCurso(now); control[1] = 'curso_basico'; setControl([...control]) }}
                            format="dd/MM/yyyy"
                        />
                    </Grid>
                    <Grid item md={4} xs={4}>
                        <Typography style={{ fontWeight: 500, marginTop: theme.spacing(2) }}>
                            Fecha Prueba de Lectura
                        </Typography>
                        <KeyboardDatePicker
                            autoOk
                            inputVariant='outlined'
                            size='small'
                            variant='inline'
                            fullWidth
                            value={fechaLectura}
                            placeholder="dd/mm/aaaa"
                            onChange={date => { setFechaLectura(date); setFechaCurso(now); control[1] = 'curso_basico'; setControl([...control]) }}
                            format="dd/MM/yyyy"
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='row'
                    alignItems='flex-end'
                    spacing={4}
                    style={{ marginRight: theme.spacing(1) }}
                >
                    <Grid item md={12} xs={12} align='right'>
                        <Typography variant='caption'>
                            Última modificación: {fechaCurso === null ? 'No revisado.' : (new Date(fechaCurso).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCurso).toLocaleTimeString('en-US')) + (userCurso === null ? '' : (" hecha por " + userCurso))}
                        </Typography>
                    </Grid>
                </Grid>
                <Collapse in={colapso}>

                    <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <Typography variant='h6' align='center' > Filtros  </Typography>
                        </Grid>


                        <Grid item md={8} xs={12}>
                            <Typography variant='h6' align='center'> Observaciones  </Typography>
                        </Grid>
                    </Grid>


                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <FormControl component="fieldset">
                                <Typography variant='body1'>Aprobación financiera</Typography>
                                <RadioGroup
                                    value={financiera}
                                    onChange={(e) => { setFinanciera(e.target.value); setFechaFinanciera(now); control[0] = 'aprob_finan'; setControl([...control]) }}
                                >
                                    <FormControlLabel value='2' control={<Radio />} label="Apto" />
                                    <FormControlLabel value='3' control={<Radio />} label="No apto" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <TextField
                                value={observacionesFinanciera}
                                multiline
                                rows={3}
                                onChange={(e) => { setObservacionesFinanciera(e.target.value); setFechaFinanciera(now); control[0] = 'aprob_finan'; setControl([...control]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                                autoFocus
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-end'
                        spacing={4}
                        style={{ marginRight: theme.spacing(1) }}
                    >
                        <Grid item md={12} xs={12} align='right'>
                            <Typography variant='caption'>
                                Última modificación: {fechaFinanciera === null ? 'No revisado.' : (new Date(fechaFinanciera).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaFinanciera).toLocaleTimeString('en-US')) + (userFinanciera === null ? '' : (" hecha por " + userFinanciera))}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider light style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <FormControl component="fieldset">
                                <Typography variant='body1'>Encuesta de riesgo </Typography>
                                <RadioGroup
                                    value={encuesta}
                                    onChange={(e) => { setEncuesta(e.target.value); setFechaEncuesta(now); control[2] = 'enc_riesgo'; setControl([...control]) }}
                                >
                                    <FormControlLabel value='2' control={<Radio />} label="Apto" />
                                    <FormControlLabel value='3' control={<Radio />} label="No apto" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <TextField
                                value={observacionesEncuesta}
                                multiline
                                rows={3}
                                onChange={(e) => { setObservacionesEncuesta(e.target.value); setFechaEncuesta(now); control[2] = 'enc_riesgo'; setControl([...control]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                        <Grid
                            container
                            direction='row'
                            alignItems='flex-end'
                            spacing={4}
                            style={{ marginRight: theme.spacing(1) }}
                        >
                            <Grid item md={12} xs={12} align='right'>
                                <Typography variant='caption'>
                                    Última modificación: {fechaEncuesta === null ? 'No revisado.' : (new Date(fechaEncuesta).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaEncuesta).toLocaleTimeString('en-US')) + (userEncuesta === null ? '' : (" hecha por " + userEncuesta))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <FormControl component="fieldset">
                                <Typography variant='body1' style={{ fontWeight: 500 }}>Contratación </Typography>
                                <RadioGroup
                                    value={contratacion}
                                    onChange={(e) => { setContratacion(e.target.value); setFechaContratacion(now); control[3] = 'contratacion'; setControl([...control]) }}
                                >
                                    <FormControlLabel value='2' control={<Radio />} label="Apto" />
                                    <FormControlLabel value='3' control={<Radio />} label="No apto" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <TextField
                                value={observacionesContratacion}
                                multiline
                                rows={3}
                                onChange={(e) => { setObservacionesContratacion(e.target.value); setFechaContratacion(now); control[3] = 'contratacion'; setControl([...control]) }}
                                size='small'
                                variant='outlined'
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-end'
                        spacing={4}
                        style={{ marginRight: theme.spacing(1) }}
                    >
                        <Grid item md={12} xs={12} align='right'>
                            <Typography variant='caption'>
                                Última modificación: {fechaContratacion === null ? 'No revisado.' : (new Date(fechaContratacion).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaContratacion).toLocaleTimeString('en-US')) + (userContratacion === null ? '' : (" hecha por " + userContratacion))}
                            </Typography>
                        </Grid>
                    </Grid>
                </Collapse>
            </DialogContent>

            <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
            <DialogActions style={{ padding: theme.spacing(1.5) }}>
                <Button disabled={!colapso} size='small' variant='contained' color='primary' onClick={() => { setOpenEditarEstado(true) }} className={classes.conditionalRoleDisplay}>
                    Editar estado
                </Button>
                <Button size='small' variant='contained' color='primary' onClick={() => { if (colapso) { putInformacion(cedula, cargos.filter(c => c.cargo === cargo.split(' -')[0])[0].cod); setControl(Array(4).fill('')) } else { putNotas(cedula) } }} className={classes.conditionalRoleDisplay}>
                    Guardar
                </Button>
                <Button size='small' variant='contained' color='secondary' onClick={() => { limpiar() }} >
                    Cancelar
                </Button>
            </DialogActions>


        </Dialog >
    );
}
export default DialogoFiltrosContratacion;