const variablesDescarga = [
    {cod: 1, nombre: 'usuario', descripcion: 'Usuario asignado.'},
    {cod: 2, nombre: 'nombre', descripcion: 'Nombres y apellidos.'},
    {cod: 3, nombre: 'tipo_documento', descripcion: 'Tipo de documento.'},
    {cod: 4, nombre: 'fecha_expedicion', descripcion: 'Fecha de expedición del documento.'},
    {cod: 5, nombre: 'lugar_expedicion', descripcion: 'Lugar de expedición del documento.'},
    {cod: 6, nombre: 'fecha_nacimiento', descripcion: 'Fecha de nacimiento.'},
    {cod: 7, nombre: 'celular', descripcion: 'Número de celular principal.'},
    {cod: 8, nombre: 'celular_alternativo', descripcion: 'Número de celular alternativo.'},
    {cod: 9, nombre: 'telefono', descripcion: 'Número de teléfono.'},
    {cod: 10, nombre: 'correo', descripcion: 'Correo electrónico.'},
    {cod:11, nombre: 'ciudad_residencia', descripcion: 'Ciudad de residencia.'},
    {cod:12, nombre: 'direccion', descripcion: 'Dirección de residencia.'},
    {cod: 13, nombre: 'complemento_direccion', descripcion: 'Complemento de dirección.'},
    {cod:14, nombre: 'barrio', descripcion: 'Barrio.'},
    {cod:15, nombre: 'localidad', descripcion: 'Localidad.'},
    {cod:16, nombre: 'ultimo_nivel_estudios', descripcion: 'Último nivel de estudios alcanzado.'},
    {cod:17, nombre: 'area_estudios', descripcion: 'Área de estudios.'},
    {cod: 18, nombre: 'estado_estudios', descripcion: 'Estado de terminación de estudios.'},
    {cod: 19, nombre: 'fecha_inicio_estudios', descripcion: 'Fecha de inicio de estudios.'},
    {cod: 20, nombre: 'fecha_finalizacion_estudios', descripcion: 'Fecha de fin de estudios.'},
    {cod: 21, nombre: 'centro_educativo', descripcion: 'Institución de estudios.'},
    {cod: 22, nombre: 'estudia_actualmente', descripcion: '¿Estudia actualmente?'},
    {cod: 23, nombre: 'modalidad', descripcion: 'Modalidad de estudios.'},
    {cod: 24, nombre: 'horario', descripcion: 'Horario de estudios.'},
    {cod: 25, nombre: 'tuvo_cirugia', descripcion: '¿Tuvo cirugía?'},
    {cod: 26, nombre: 'que_cirugia', descripcion: '¿Qué cirugía?'},
    {cod: 27, nombre: 'toma_medicamento', descripcion: '¿Toma medicamento?'},
    {cod: 28, nombre: 'que_medicamento', descripcion: '¿Qué medicamento?'},
    {cod: 29, nombre: 'nombre_familiar', descripcion: 'Nombre de referencia familiar.'},
    {cod: 30, nombre: 'parentesco_familiar', descripcion: 'Parentesco de referencia familiar.'},
    {cod: 31, nombre: 'telefono_familiar', descripcion: 'Número de celular o teléfono de referencia familiar.'},
    {cod: 34, nombre: 'nombre_referencia_1', descripcion: 'Nombre de referencia personal 1.'},
    {cod: 35, nombre: 'relacion_referencia_1', descripcion: 'Relación de referencia personal 1.'},
    {cod: 36, nombre: 'telefono_referencia_1', descripcion: 'Número de celular o teléfono de referencia personal 1.'},
    {cod: 37, nombre: 'nombre_referencia_2', descripcion: 'Nombre de referencia personal 2.'},
    {cod: 38, nombre: 'relacion_referencia_2', descripcion: 'Relación de referencia personal 2.'},
    {cod: 39, nombre: 'telefono_referencia_2', descripcion: 'Número de celular o teléfono de referencia personal 2.'},
    {cod: 40, nombre: 'disponibilidad_tiempo', descripcion: 'Disponibilidad de tiempo.'},
    {cod: 41, nombre: 'eps', descripcion: 'EPS.'},
    {cod: 42, nombre: 'pension', descripcion: 'Pensión.'},
    {cod: 43, nombre: 'esta_afiliado', descripcion: '¿Está afiliado?'},
    {cod: 44, nombre: 'tipo_afiliacion', descripcion: 'Tipo de afiliación.'},
    {cod: 45, nombre: 'curso_basico_nota', descripcion: 'Nota del curso básico.'},
    {cod: 46, nombre: 'curso_basico_fecha', descripcion: 'Fecha del curso básico.'},
    {cod: 47, nombre: 'entrevista_nota', descripcion: 'Nota de la entrevista.'},
    {cod: 48, nombre: 'entrevista_fecha', descripcion: 'Fecha de la entrevista.'},
    {cod: 49, nombre: 'prueba_lectura_nota', descripcion: 'Nota de la prueba de lectura.'},
    {cod: 50, nombre: 'prueba_lectura_fecha', descripcion: 'Fecha de la prueba de lectura.'},
    {cod: 51, nombre: 'aprobado_documentos', descripcion: 'Estado de aprobación de documentos.'},
    {cod: 52, nombre: 'fecha_mod_documentos', descripcion: 'Fecha de aprobación de documentos.'},
    {cod: 53, nombre: 'user_mod_documentos', descripcion: 'Usuario que aprobó documentos.'},
    {cod: 54, nombre: 'estado_contratacion', descripcion: 'Estado de contratación.'},
    {cod: 55, nombre: 'fecha_creado', descripcion: 'Fecha de registro de la persona.'}
];

export default variablesDescarga;