const versiones_android = [
    {codename: 'No codename', version: '1.0', cod: 0},
    {codename: 'No codename', version: '1.1', cod: 1},
    {codename: 'Cupcake', version: '1.5', cod: 2},
    {codename: 'Donut', version: '1.6', cod: 3},
    {codename: 'Eclair', version: '2.0 - 2.1', cod: 4},
    {codename: 'Froyo', version: '2.2 - 2.2.3', cod: 5},
    {codename: 'Gingerbread', version: '2.3 - 2.3.7', cod: 6},
    {codename: 'Honeycomb', version: '3.0 - 3.2.6', cod: 7},
    {codename: 'Ice Cream Sandwich', version: '4.0 - 4.0.4', cod: 8},
    {codename: 'Jelly Bean', version: '4.1 - 4.3.1', cod: 9},
    {codename: 'KitKat', version: '4.4 - 4.4.4', cod: 10},
    {codename: 'Lollipop', version: '5.0 - 5.1.1', cod: 11},
    {codename: 'Marshmallow', version: '6.0 - 6.0.1', cod: 12},
    {codename: 'Nougat', version: '7.0', cod: 13},
    {codename: 'Nougat', version: '7.1.0 - 7.1.2', cod: 14},
    {codename: 'Oreo', version: '8.0', cod: 15},
    {codename: 'Oreo', version: '8.1', cod: 16},
    {codename: 'Pie', version: '9.0', cod: 17},
    {codename: 'Android 10', version: '10.0', cod: 18},
    {codename: 'Android 11', version: '11.0', cod: 19},
    {codename: 'No tiene Android', version: '', cod: 20}
]
export default versiones_android