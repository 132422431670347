import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Backdrop,
  CircularProgress,
  IconButton,
  Snackbar,
  Container,
  Menu,
  MenuItem,
  Badge,
  Divider,
  Avatar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import MailIcon from "@material-ui/icons/Mail";

import useBreakpoints from "./styles/useBreakpoints";

// import Logo from '../logo.png';

import Sesion from "./Sesion";
import Tabla from "./components/Tabla/Tabla";
import Copyright from "./Copyright";

const API_DEFAULT = "https://back-sistema-cnc-service-q2nhgfzuoq-uc.a.run.app";
// const API_DEFAULT = "http://127.0.0.1:8000";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    fontWeight: 500,
  },
  logo: {
    width: 110,
    [theme.breakpoints.up("sm")]: {
      width: 135,
    },
  },
  logoSpacing: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(4),
    },
    display: "flex",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function Gestor(props) {
  const { theme } = props;
  const classes = useStyles();
  const point = useBreakpoints();
  const paths = ["/inicio-sesion", "/tabla-personal-seleccion"];

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [auth, setAuth] = useState(localStorage.getItem("auth"));
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refresh")
  );
  const [accessToken, setAccessToken] = useState("");

  const [username, setUsername] = useState("");
  const [userImage, setUserImage] = useState("");
  const [sessionErrors, setSessionErrors] = useState(Array(2).fill(false));

  const [showBackdrop, setShowBackdrop] = useState(false);
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [validRole, setValidRole] = useState(false);

  const renderMenu = (
    <Menu
      style={{ marginTop: isMobileMenuOpen ? 40 : 32 }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={() => {
        setAnchorEl(null);
        setMobileMoreAnchorEl(null);
      }}
    >
      <MenuItem
        style={{
          cursor: "default",
          pointerEvents: "none",
          fontSize: 14,
          paddingTop: 4,
          minHeight: "auto",
        }}
      >
        <span>
          Iniciado como <span style={{ fontWeight: 500 }}>{username}</span>
        </span>
      </MenuItem>
      <Divider style={{ marginTop: 2, marginBottom: 8 }} />
      <MenuItem
        component="a"
        style={{ fontSize: 14, minHeight: "auto" }}
        href="https://perfil.desarrolloscnc.com"
      >
        Perfil
      </MenuItem>
      <MenuItem
        style={{ fontSize: 14, minHeight: "auto" }}
        onClick={() => {
          setAnchorEl(null);
          setMobileMoreAnchorEl(null);
          logOut();
        }}
      >
        Cerrar sesión
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      style={{ marginTop: 32 }}
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={() => {
        setMobileMoreAnchorEl(null);
      }}
    >
      <MenuItem style={{ minHeight: "auto" }}>
        <IconButton
          color="inherit"
          style={{ padding: 2, marginRight: 12, pointerEvents: "none" }}
        >
          <Badge badgeContent={4} color="secondary" variant="dot">
            <MailIcon />
          </Badge>
        </IconButton>
        <span style={{ fontSize: 14 }}>Mensajes</span>
      </MenuItem>
      <MenuItem style={{ minHeight: "auto" }}>
        <IconButton
          color="inherit"
          style={{ padding: 2, marginRight: 12, pointerEvents: "none" }}
        >
          <Badge badgeContent={11} color="secondary" variant="dot">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <span style={{ fontSize: 14 }}>Notificaciones</span>
      </MenuItem>
      <MenuItem
        style={{ minHeight: "auto" }}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <IconButton
          color="inherit"
          style={{ padding: 2, marginRight: 12, pointerEvents: "none" }}
        >
          <Avatar style={{ height: 24, width: 24 }} src={userImage} />
        </IconButton>
        <span style={{ fontSize: 14 }}>Perfil</span>
      </MenuItem>
    </Menu>
  );

  const validateSession = (username, password) => {
    let errorSesion = false;
    if (username === "") {
      errorSesion = true;
      sessionErrors[0] = true;
    }
    if (password === "") {
      errorSesion = true;
      sessionErrors[1] = true;
    }
    if (errorSesion) {
      setSessionErrors([...sessionErrors]);
      setMessage("Existen campos sin diligenciar o con algún error.");
      setSeverity("error");
      setTimeout(() => {
        setSnack(true);
      }, 0);
    } else {
      logIn(username, password);
    }
  };

  const logIn = async (username, password) => {
    setShowBackdrop(true);
    const res = await fetch(`${API_DEFAULT}/usuarios/auth/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });
    res.json().then((d) => {
      if (d["access"]) {
        getRole(d["access"], d["refresh"]);
      } else {
        setShowBackdrop(false);
        setMessage("Los datos de usuario y contraseña son incorrectos.");
        setSeverity("error");
        setTimeout(() => {
          setSnack(true);
        }, 0);
      }
    });
  };

  const getRole = async (access = accessToken, refresh = refreshToken) => {
    setShowBackdrop(true);
    if (access === "") {
      let newAccess = await getAccessTokenWithRefresh();
      if (newAccess) {
        getRole(newAccess, refresh);
      }
    } else {
      const res = await fetch(`${API_DEFAULT}/usuarios/dar_rol/`, {
        headers: { Authorization: `Bearer ${access}` },
      });

      res.json().then(async (res) => {
        if (res["code"] === "token_not_valid") {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            getRole(newAccess, refresh);
          }
        } else if (res["roles"] === undefined) {
          signOff();
        } else if (
          res["roles"].includes("Administrador") ||
          res["roles"].includes("Gestor de selección") ||
          res["roles"].includes("Auxiliar de selección")
        ) {
          const rol1 =
            res["roles"].includes("Administrador") ||
            res["roles"].includes("Gestor de selección");
          localStorage.setItem("refresh", refresh);
          localStorage.setItem("auth", true);
          localStorage.setItem("rol", rol1 ? "gestor" : "auxiliar");
          setUsername(res["username"]);
          setUserImage(res["imagen"] === null ? "" : res["imagen"]);
          setAccessToken(access);
          setRefreshToken(refresh);
          setAuth(true);
          setValidRole(true);
          setShowBackdrop(false);
        } else {
          signOff();
          setShowBackdrop(false);
          setMessage(
            "El usuario no está habilitado para usar esta aplicación."
          );
          setSeverity("warning");
          setTimeout(() => {
            setSnack(true);
          }, 0);
        }
      });
    }
  };

  const getAccessTokenWithRefresh = async () => {
    let access = undefined;
    const res = await fetch(`${API_DEFAULT}/usuarios/refresh_token/`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        refresh: refreshToken,
      }),
    });

    if (!res.ok) {
      signOff();
      setShowBackdrop(false);
      setMessage("La sesión expiró, por favor ingrese nuevamente.");
      setSeverity("info");
      setTimeout(() => {
        setSnack(true);
      }, 0);
    }

    await res.json().then((res) => {
      if (res["code"] === "token_not_valid") {
        signOff();
        setShowBackdrop(false);
        setMessage("La sesión expiró, por favor ingrese nuevamente.");
        setSeverity("info");
        setTimeout(() => {
          setSnack(true);
        }, 0);
      } else {
        access = res["access"];
      }
    });

    return access;
  };

  const logOut = async (access = accessToken) => {
    setShowBackdrop(true);

    const res = await fetch(`${API_DEFAULT}/usuarios/logout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access}`,
      },
      body: JSON.stringify({
        all: false,
        refresh: refreshToken,
      }),
    });

    if (res.ok) {
      setShowBackdrop(false);
      signOff();
    } else {
      res.json().then(async (res) => {
        if (res["code"] === "token_not_valid") {
          let newAccess = await getAccessTokenWithRefresh();
          if (newAccess) {
            setAccessToken(newAccess);
            localStorage.setItem("access", newAccess);

            logOut(newAccess);
          }
        } else {
          setShowBackdrop(false);
          setMessage("Ocurrio un error, intente de nuevo.");
          setSeverity("error");
          setTimeout(() => {
            setSnack(true);
          }, 0);
        }
      });
    }
  };

  const signOff = () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("refresh");
    localStorage.removeItem("rol");
    setAuth(false);
    setUsername("");
    setAccessToken("");
    setRefreshToken("");
    setValidRole(false);
  };

  useEffect(() => {
    if (auth) {
      getRole(accessToken, refreshToken);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    function handleResize() {
      setAnchorEl(null);
      setMobileMoreAnchorEl(null);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="Gestor">
      <header className="Gestor-header">
        <AppBar position="static">
          <Toolbar>
            <div className={classes.logoSpacing}>
              <a
                style={{ display: "contents" }}
                href={"https://desarrolloscnc.com/"}
              >
                {/* <img src={Logo} alt="logo" className={classes.logo} /> */}
              </a>
            </div>
            <Typography variant="h5" className={classes.title}>
              <Link
                to={paths[1]}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Gestor de selección
              </Link>
            </Typography>
            {auth ? (
              <div>
                <div className={classes.sectionDesktop}>
                  {/* <IconButton color='inherit'>
                                        <Badge badgeContent={4} color='secondary' variant='dot'>
                                            <MailIcon />
                                        </Badge>
                                    </IconButton>
                                    <IconButton color='inherit'>
                                        <Badge badgeContent={1} color='secondary' variant='dot'>
                                            <NotificationsIcon />
                                        </Badge>
                                    </IconButton> */}
                  <IconButton
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                    }}
                    color="inherit"
                  >
                    <Avatar style={{ height: 24, width: 24 }} src={userImage} />
                  </IconButton>
                </div>
                <div className={classes.sectionMobile}>
                  <IconButton
                    onClick={(event) => {
                      setMobileMoreAnchorEl(event.currentTarget);
                    }}
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </div>
              </div>
            ) : null}
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </header>
      <div className="Gestor-body" style={{ marginTop: theme.spacing(2) }}>
        <Redirect
          to={{
            pathname: auth ? paths[1] : paths[0],
            state: { from: props.location },
          }}
        />
        <Switch>
          <Route exact path={paths[0]}>
            {!auth ? (
              <Container component="main" maxWidth="xs">
                <Sesion
                  theme={theme}
                  errors={[sessionErrors, setSessionErrors]}
                  validateSession={validateSession}
                />
              </Container>
            ) : null}
          </Route>
          <Route
            path={paths[1]}
            render={() => {
              if (auth) {
                return (
                  <Tabla
                    theme={theme}
                    API_DEFAULT={API_DEFAULT}
                    validRole={validRole}
                    accessToken={[accessToken, setAccessToken]}
                    getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                    setShowBackdrop={setShowBackdrop}
                    setMessage={setMessage}
                    setSeverity={setSeverity}
                    setSnack={setSnack}
                    point={point}
                  />
                );
              } else {
                return <Redirect to={paths[0]} />;
              }
            }}
          />
        </Switch>
      </div>
      <div className="Notifications">
        <Backdrop
          style={{ zIndex: 1301 }}
          className={classes.backdrop}
          open={showBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={snack}
          autoHideDuration={6000}
          onClose={() => setSnack(false)}
        >
          <Alert
            onClose={() => setSnack(false)}
            severity={severity}
            variant="filled"
          >
            {message}
          </Alert>
        </Snackbar>
      </div>
      <div className="Copyright">
        <Copyright />
      </div>
    </div>
  );
}

export default Gestor;
