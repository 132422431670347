import React, { useState } from 'react';
import {
    Collapse,
    CardContent,
    CardHeader,
    Typography,
    Grid,
    TextField,
    Divider,
    IconButton,
    Card,
} from '@material-ui/core';
//import { makeStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

function DialogoExperienciaLaboral(props) {
    const { theme, datosPersonales, experienciaLaboral
        // experienciaLaboral
        //setMessage, setSeverity, setSnack, setShowBackdrop, API_DEFAULT, setExperienciaLaboral, setTrabajoCnc
    } = props;
    // const [open] = props.open;

    const [formatoColapso, setFormatoColapso] = props.formatoColapso;
    const [empresa, setEmpresa] = useState(Array(1).fill(''));
    //const mapeo = useState(Array(3).fill(''));
    const [sector, setSector] = useState(Array(1).fill(''));
    const [telefonoEmpresa, setTelefonoEmpresa] = useState(Array(1).fill(''));
    const [ciudadEmpresa, setCiudadEmpresa] = useState(Array(1).fill(null));
    const [jefe, setJefe] = useState(Array(1).fill(''));
    const [telefonoJefe, setTelefonoJefe] = useState(Array(1).fill(''));
    const [cargo, setCargo] = useState(Array(1).fill(''));
    const [funciones, setFunciones] = useState(Array(1).fill(''));
    const [fechaIngreso, setFechaIngreso] = useState(Array(1).fill(null));
    const [fechaRetiro, setFechaRetiro] = useState(Array(1).fill(null));
    const [tiempoLaborado, setTiempoLaborado] = useState(Array(1).fill(null));
    const [motivoRetiro, setMotivoRetiro] = useState(Array(1).fill(null));
    const [certificacionLaboral, setCertificacionLaboral] = useState(Array(1).fill(null));

    function crearArrays(experienciaLaboral) {
        for (let i = 0; i < experienciaLaboral.length; i++) {
            empresa[i] = experienciaLaboral[i].nombre_empresa; setEmpresa([...empresa]);
            sector[i] = experienciaLaboral[i].sector; setSector([...sector]);
            telefonoEmpresa[i] = experienciaLaboral[i].telefono_empresa; setTelefonoEmpresa([...telefonoEmpresa]);
            ciudadEmpresa[i] = experienciaLaboral[i].ciudad; setCiudadEmpresa([...ciudadEmpresa])
            jefe[i] = experienciaLaboral[i].nombre_jefe; setJefe([...jefe]);
            telefonoJefe[i] = experienciaLaboral[i].telefono_jefe; setTelefonoJefe([...telefonoJefe]);
            cargo[i] = experienciaLaboral[i].cargo; setCargo([...cargo]);
            funciones[i] = experienciaLaboral[i].funciones; setFunciones([...funciones]);
            fechaIngreso[i] = experienciaLaboral[i].fecha_ingreso; setFechaIngreso([...fechaIngreso]);
            fechaRetiro[i] = experienciaLaboral[i].fecha_retiro; setFechaRetiro([...fechaRetiro]);
            tiempoLaborado[i] = experienciaLaboral[i].tiempo_laborado; setTiempoLaborado([...tiempoLaborado]);
            motivoRetiro[i] = experienciaLaboral[i].motivo_retiro; setMotivoRetiro([...motivoRetiro]);
            certificacionLaboral[i] = experienciaLaboral[i].certificacion_laboral ? 'Sí': 'No' ; setCertificacionLaboral([...certificacionLaboral]);
        }
    }

    return (
        <Card variant='outlined' style={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                title={
                    <Typography variant='body1' style={{ fontSize: 18, fontWeight: 500, marginBottom: theme.spacing(-2) }}>3. Experiencia Laboral</Typography>
                }
                action={
                    <IconButton
                        disabled={experienciaLaboral.length === 0}
                        onClick={() => { crearArrays(experienciaLaboral); formatoColapso[2] = !formatoColapso[2]; setFormatoColapso([...formatoColapso]) }}
                        color='secondary'
                        aria-label='add'
                        size='medium'
                    >
                        {formatoColapso[2] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                }
            />

            <Collapse in={formatoColapso[2] === true} mountOnEnter unmountOnExit>

                <CardContent style={{ paddingBottom: theme.spacing(2) }}>

                    {empresa.map((v, i) => (
                        <React.Fragment key={"Experiencia"}>
                            <Divider style={{marginBottom: theme.spacing(2), marginTop: theme.spacing(2) }} />

                            <div>
                                <Typography variant='body1' color='secondary' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}> {i === 0 ? 'Última' : i === 1 ? 'Penúltima' : i === 2 ? 'Antepenúltima' : null} experiencia laboral:</Typography>
                            </div>

                            < Grid
                                container
                                direction='row'
                                alignItems='center'
                                spacing={4}
                            >
                                <Grid item md={3} sm={6} xs={12}>
                                     <Typography variant='body1' style={{ fontWeight: 500 }}>Sector:  </Typography>

                                    <TextField
                                        value={sector[i]}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />


                                </Grid>
                                <Grid item md={6} xs={12}>
                                     <Typography variant='body1' style={{ fontWeight: 500 }}>Empresa:  </Typography>

                                    <TextField
                                        value={empresa[i]}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />

                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                     <Typography variant='body1' style={{ fontWeight: 500 }}>Teléfono de la empresa:</Typography>

                                    <TextField
                                        value={telefonoEmpresa[i]}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />

                                </Grid>
                            </Grid>
                            {/* Jefe, teléfono  */}
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                spacing={4}
                            >
                                <Grid item md={3} xs={12}>
                                     <Typography variant='body1' style={{ fontWeight: 500 }}>Ciudad: </Typography>

                                    <TextField
                                        value={ciudadEmpresa[i]}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />

                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                     <Typography variant='body1' style={{ fontWeight: 500 }}>Jefe inmediato: </Typography>

                                    <TextField
                                        value={jefe[i]}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />

                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                     <Typography variant='body1' style={{ fontWeight: 500 }}>Teléfono del jefe inmediato: </Typography>

                                    <TextField
                                        value={telefonoJefe[i]}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />

                                </Grid>
                            </ Grid>

                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid item xs={6}>

                                    <Grid
                                        container
                                        spacing={3}
                                    >
                                        <Grid item md={12} sm={12} xs={12}>
                                             <Typography variant='body1' style={{ fontWeight: 500 }}>Cargo: </Typography>

                                            <TextField
                                                value={cargo[i]}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />

                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                             <Typography variant='body1' style={{ fontWeight: 500 }}> ¿Cuenta con certificación laboral?: </Typography>

                                            <TextField
                                                value={certificacionLaboral[i]}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />

                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                     <Typography variant='body1' style={{ fontWeight: 500 }}>Funciones: </Typography>

                                    <TextField
                                        value={funciones[i]}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        multiline
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />

                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                spacing={4}
                            >
                                <Grid item md={3} sm={6} xs={12}>
                                     <Typography variant='body1' style={{ fontWeight: 500 }}>Fecha de ingreso: </Typography>

                                    <TextField
                                        value={new Date(fechaIngreso[i]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric'})}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />

                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                     <Typography variant='body1' style={{ fontWeight: 500 }}>Fecha de retiro: </Typography>

                                    <TextField
                                        value={new Date(fechaRetiro[i]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric'})}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />

                                </Grid>
                                <Grid item md={3} xs={12}>
                                     <Typography variant='body1' style={{ fontWeight: 500 }}> Tiempo total laborado: </Typography>

                                    <TextField
                                        value={tiempoLaborado[i]}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        multiline
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />

                                </Grid>
                                <Grid item md={3} xs={12}>
                                     <Typography variant='body1' style={{ fontWeight: 500 }}> Motivo del retiro: </Typography>

                                    <TextField
                                        value={motivoRetiro[i]}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        multiline
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </React.Fragment>
                    ))
                    }

                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(1) }} />
                    <Typography variant='body1' color='secondary' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }}>Experiencia laboral con el Centro Nacional de Consultoría</Typography>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >

                        <Grid item md={3} xs={12}>

                             <Typography variant='body1' style={{ fontWeight: 500 }}> ¿Ha trabajado para el CNC?: </Typography>

                            <TextField
                                value={datosPersonales.servicio_cnc ? 'Sí' : 'No'}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                        </Grid>

                        <Grid item md={3} xs={12}>
                             <Typography variant='body1' style={{ fontWeight: 500 }}> ¿Cuánto tiempo en meses?: </Typography>

                            <TextField
                                value={datosPersonales.cuanto_tiempo_cnc}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                             <Typography variant='body1' style={{ fontWeight: 500 }}>¿En qué año?:  </Typography>

                            <TextField
                                value={datosPersonales.ano_cnc === 0 ? 'No aplica' : datosPersonales.ano_cnc}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                        </Grid>
                        <Grid item md={3} xs={12}>
                             <Typography variant='body1' style={{ fontWeight: 500 }}> Tipo de contrato: </Typography>

                            <TextField
                                value={datosPersonales.tipo_contrato}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />

                        </Grid>
                    </Grid>

                </CardContent>

            </Collapse>

        </Card >

    );

}

export default DialogoExperienciaLaboral;