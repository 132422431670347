const cargos = [
    {cargo: 'Todos'},
    {cargo: 'Recolector de información presencial', cod: 0},
    {cargo: 'Recolector de información telefónico', cod: 1},
    {cargo: 'Auditor presencial', cod: 2},
    {cargo: 'Supervisor telefónico', cod: 3},
    {cargo: 'Coordinador de campo', cod: 4},
    {cargo: 'Recolector de información telefónico - ECAR', cod: 5},
];

export default cargos;