import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Grid,
    TextField,
    Tooltip,
    Collapse,
    IconButton,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from "@material-ui/pickers";
import differenceInYears from 'date-fns/differenceInYears';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import municipios from '../../../../../data/municipios'

function DialogoDatosPersonales(props) {
    const { theme,
        point,
        readOnly,
        documento
    } = props;
    const [formatoColapso, setFormatoColapso] = props.formatoColapso;

    const [errores, setErrores] = props.errores;

    const [primerApellido, setPrimerApellido] = props.primerApellido;
    const [segundoApellido, setSegundoApellido] = props.segundoApellido;
    const [primerNombre, setPrimerNombre] = props.primerNombre;
    const [segundoNombre, setSegundoNombre] = props.segundoNombre;
    const [tipoDocumento, setTipoDocumento] = props.tipoDocumento;
    const [fechaExpedicion, setFechaExpedicion] = props.fechaExpedicion;
    const [lugarExpedicion, setLugarExpedicion] = props.lugarExpedicion;
    const [fechaNacimiento, setFechaNacimiento] = props.fechaNacimiento;
    const [ciudad, setCiudad] = props.ciudad;
    const [celular, setCelular] = props.celular;
    const [celular2, setCelular2] = props.celular2;
    const [telefono, setTelefono] = props.telefono;
    const [correo, setCorreo] = props.correo;
    const [direccion, setDireccion] = props.direccion;
    const [complementoDireccion, setComplementoDireccion] = props.complementoDireccion;
    const [barrio, setBarrio] = props.barrio;
    const [localidad, setLocalidad] = props.localidad;

    return (
        <Card variant='outlined' style={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                title={
                    <Typography variant='body1' style={{ fontSize: 18, fontWeight: 500 }}>1. Datos Personales</Typography>
                }
                action={
                    <IconButton
                        // disabled={completado[0] === false}
                        onClick={() => { formatoColapso[0] = !formatoColapso[0]; setFormatoColapso([...formatoColapso]) }}
                        color='secondary'
                        aria-label='add'
                        size='medium'
                    >
                        {formatoColapso[0] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                }
            />

            <Collapse in={formatoColapso[0] === true} mountOnEnter unmountOnExit>
                <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Primer apellido: </Typography>
                            <TextField
                                value={primerApellido}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { let value = e.target.value; if ((value.length > 0)) { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() }; if (value.length < 21) { setPrimerApellido(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1]/g, '')); errores[0] = false; setErrores([...errores]) } }}
                                style={{ marginBottom: [errores[1]].includes(true) && !errores[0] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                error={errores[0]}
                                helperText={errores[0] ? 'Este campo no puede quedar vacío' : null}
                            />

                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}> Segundo apellido: </Typography>

                            <TextField
                                value={segundoApellido}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { let value = e.target.value; if (value.length > 0) { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() }; if (value.length < 21) setSegundoApellido(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1]/g, '')) }}
                                style={{ marginBottom: [errores[0], errores[1]].includes(true) && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Primer nombre: </Typography>

                            <TextField
                                value={primerNombre}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { let value = e.target.value; if (value.length > 0) { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() }; if (value.length < 21) setPrimerNombre(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1]/g, '')); errores[1] = false; setErrores([...errores]) }}
                                style={{ marginBottom: [errores[0]].includes(true) && !errores[1] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                error={errores[1]}
                                helperText={errores[1] ? 'Este campo no puede quedar vacío' : null}
                            />

                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}> Segundo nombre: </Typography>
                            <TextField
                                value={segundoNombre}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { let value = e.target.value; if (value.length > 0) { value = e.target.value[0].toUpperCase() + e.target.value.slice(1).toLowerCase() }; if (value.length < 21) setSegundoNombre(value.replace(/[^A-Za-zÀ-ú\u00f1\u00d1]/g, '')) }}
                                style={{ marginBottom: [errores[0], errores[1]].includes(true) && !['xs', 'sm'].includes(point) ? theme.spacing(2.8) : null }}

                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}> Tipo de documento: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={tipoDocumento}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        style={{ marginBottom: errores[2] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        value={tipoDocumento}
                                        onChange={(e, v) => { setTipoDocumento(v) }}
                                        options={
                                            ['Cédula de Ciudadanía', 'Cédula de Extranjería'].map(option => option)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                style={{ marginBottom: errores[2] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                        disableClearable={true}
                                    />
                                }
                            </React.Fragment>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Fecha de expedición: </Typography>
                            <KeyboardDatePicker
                                autoOk
                                inputVariant='outlined'
                                size='small'
                                variant='inline'
                                fullWidth
                                value={fechaExpedicion}
                                placeholder="dd/mm/aaaa"
                                onChange={date => { setFechaExpedicion(date); errores[2] = false; setErrores([...errores]) }}
                                format="dd/MM/yyyy"
                                error={errores[2]}
                                helperText={errores[2] ? (differenceInYears(fechaExpedicion, fechaNacimiento) < 18 ? 'Fecha de expedición incorrecta' : 'Este campo no puede quedar vacío') : null}
                                disabled={readOnly}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Número de documento:  </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={documento}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        style={{ marginBottom: errores[2] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Tooltip title='Este campo no puede ser modificado.' arrow>
                                        <TextField
                                            value={documento}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            style={{ marginBottom: errores[2] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                        />
                                    </Tooltip>
                                }
                            </React.Fragment>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={3} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Lugar de expedición: </Typography>
                            <React.Fragment>
                                {
                                    readOnly ?
                                        <TextField
                                            value={lugarExpedicion}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            style={{ marginBottom: errores[3] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                        />
                                        :
                                        <Autocomplete
                                            value={lugarExpedicion}
                                            onChange={(e, v) => { setLugarExpedicion(v) }}
                                            options={
                                                municipios.map(option => option.municipio + ' - ' + option.departamento)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                    style={{ marginBottom: errores[3] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                                />
                                            )}
                                            disableClearable={true}
                                        />
                                }
                            </React.Fragment>
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Fecha de nacimiento: </Typography>
                            <KeyboardDatePicker
                                autoOk
                                inputVariant='outlined'
                                size='small'
                                variant='inline'
                                fullWidth
                                value={fechaNacimiento}
                                placeholder="dd/mm/aaaa"
                                onChange={date => { setFechaNacimiento(date); errores[3] = false; errores[2] = false; setErrores([...errores]) }}
                                format="dd/MM/yyyy"
                                error={errores[3]}
                                helperText={errores[3] ? (differenceInYears(fechaExpedicion, fechaNacimiento) < 18 ? 'Fecha de expedición incorrecta' : 'Este campo no puede quedar vacío') : null}
                                disabled={readOnly}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Ciudad de residencia: </Typography>
                            <React.Fragment>
                                {
                                    readOnly ?
                                        <TextField
                                            value={ciudad}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            style={{ marginBottom: errores[3] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                        />
                                        :
                                        <Autocomplete
                                            value={ciudad}
                                            onChange={(e, v) => { setCiudad(v) }}
                                            options={
                                                municipios.map(option => option.municipio + ' - ' + option.departamento)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                    style={{ marginBottom: errores[3] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                                />
                                            )}
                                            disableClearable={true}
                                        />
                                }
                            </React.Fragment>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Celular: </Typography>

                            <TextField
                                value={celular}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length <= 10) setCelular(e.target.value.replace(/[^0-9]/g, '')); errores[4] = false; setErrores([...errores]) }}
                                error={errores[4]}
                                helperText={errores[4] ? (celular.length !== 0 && celular.length !== 10) ? 'El celular tiene menos de 10 dígitos' : 'Este campo no puede quedar vacío' : null}

                            />

                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Celular alternativo: </Typography>

                            <TextField
                                value={celular2}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length <= 10) setCelular2(e.target.value.replace(/[^0-9]/g, '')); errores[5] = false; setErrores([...errores]) }}
                                error={errores[5]}
                                helperText={errores[5] ? (celular2.length !== 0 && celular2.length !== 10) ? 'El celular tiene menos de 10 dígitos' : 'Este campo no puede quedar vacío' : null}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Teléfono:</Typography>

                            <TextField
                                value={telefono}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length <= 10) setTelefono(e.target.value.replace(/[^0-9]/g, '')); }}
                                style={{ marginBottom: [errores[4], errores[5]].includes(true) && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Correo electrónico: </Typography>
                            <TextField
                                value={correo}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { setCorreo(e.target.value.toLowerCase()); errores[6] = false; setErrores([...errores]) }}
                                errores={errores[6]}
                                helperText={errores[6] ? 'Este campo no puede quedar vacío' : null}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Dirección: </Typography>
                            <TextField
                                value={direccion}
                                size='small'
                                variant='outlined'
                                fullWidth
                                multiline
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { setDireccion(e.target.value); errores[7] = false; setErrores([...errores]) }}
                                error={errores[7]}
                                helperText={errores[7] ? 'Este campo no puede quedar vacío' : null}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Información adicional de dirección:</Typography>
                            <TextField
                                value={complementoDireccion}
                                size='small'
                                variant='outlined'
                                fullWidth
                                multiline
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { let value = e.target.value; if (value.length < 101) setComplementoDireccion(value) }}
                                style={{ marginBottom: errores[7] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Barrio: </Typography>

                            <TextField
                                value={barrio}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 101) setBarrio(e.target.value.replace(/[^a-zA-ZÀ-ú\u00f1\u00d1\d\s]+$/g, '')); errores[8] = false; setErrores([...errores]) }}
                                errores={errores[8]}
                                helperText={errores[8] ? 'Este campo no puede quedar vacío' : null}
                            />
                        </Grid>
                        <Grid item md={6} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Localidad o comuna: </Typography>
                            <TextField
                                value={localidad}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 101) setLocalidad(e.target.value.replace(/[^a-zA-ZÀ-ú\u00f1\u00d1\d\s]+$/g, '')) }}
                                style={{ marginBottom: errores[8] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>
                </CardContent>
            </ Collapse>
        </Card>
    );
}

export default DialogoDatosPersonales;