import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Grid,
    TextField,
    Collapse,
    IconButton,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import fondosPension from '../../../../../data/fondosPension';
import epss from '../../../../../data/epss';
import tipoAfiliaciones from '../../../../../data/tipoAfiliaciones';

function DialogoInformacionPersonal(props) {
    const { theme,
        point,
        // classes,
        readOnly
    } = props;
    const [errores, setErrores] = props.errores;
    const [formatoColapso, setFormatoColapso] = props.formatoColapso;

    const [cirugia, setCirugia] = props.cirugia;
    const [tipoCirugia, setTipoCirugia] = props.tipoCirugia;
    const [medicamento, setMedicamento] = props.medicamento;
    const [tipoMedicamento, setTipoMedicamento] = props.tipoMedicamento;
    const [nombreFamiliar, setNombreFamiliar] = props.nombreFamiliar;
    const [parentesco, setParentesco] = props.parentesco;
    const [telefonoFamiliar, setTelefonoFamiliar] = props.telefonoFamiliar;
    const [nombreReferencia, setNombreReferencia] = props.nombreReferencia;
    const [relacionReferencia, setRelacionReferencia] = props.relacionReferencia;
    const [telefonoReferencia, setTelefonoReferencia] = props.telefonoReferencia;
    const [nombreReferencia2, setNombreReferencia2] = props.nombreReferencia2;
    const [relacionReferencia2, setRelacionReferencia2] = props.relacionReferencia2;
    const [telefonoReferencia2, setTelefonoReferencia2] = props.telefonoReferencia2;
    const [eps, setEps] = props.eps;
    const [fondoPension, setFondoPension] = props.fondoPension;
    const [estadoAfiliacion, setEstadoAfiliacion] = props.estadoAfiliacion;
    const [tipoAfiliacion, setTipoAfiliacion] = props.tipoAfiliacion;

    return (
        <Card variant='outlined' style={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                title={
                    <Typography variant='body1' style={{ fontSize: 18, fontWeight: 500 }}>4. Información Personal</Typography>
                }
                action={
                    <IconButton
                        onClick={() => { formatoColapso[3] = !formatoColapso[3]; setFormatoColapso([...formatoColapso]) }}
                        color='secondary'
                        aria-label='add'
                        size='medium'
                    >
                        {formatoColapso[3] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                }
            />

            <Collapse in={formatoColapso[3] === true} mountOnEnter unmountOnExit>

                <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                    <Typography variant='body1' color='secondary' style={{ fontSize: 16, fontWeight: 500, marginBottom: theme.spacing(2) }}>Historial médico:</Typography>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>¿Le han practicado alguna cirugía?: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={cirugia ? 'Sí' : 'No'}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        style={{ marginBottom: errores[14] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        value={cirugia ? 'Sí' : 'No'}
                                        onChange={(e, v) => { setCirugia(v); if (v === "No") { setTipoCirugia('') } }}
                                        options={
                                            [
                                                "Sí", "No"
                                            ].map(option => option)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                style={{ marginBottom: errores[14] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                        disableClearable={true}
                                    />
                                }
                            </React.Fragment>

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>¿Cuál?: </Typography>

                            <TextField
                                value={tipoCirugia}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly || cirugia === 'No' || cirugia === false,
                                }}
                                onChange={(e) => { if (e.target.value.length < 146) setTipoCirugia(e.target.value); errores[14] = false; setErrores([...errores]) }}
                                error={errores[14]}
                                helperText={errores[14] ? 'El campo no puede quedar vacío' : null}
                            />

                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>¿Actualmente toma algún medicamento?: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={medicamento ? 'Sí' : 'No'}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        style={{ marginBottom: errores[15] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        value={medicamento ? 'Sí' : 'No'}
                                        onChange={(e, v) => { setMedicamento(v); if (v === "No") { setTipoMedicamento('') } }}
                                        options={
                                            [
                                                "Sí", "No"
                                            ].map(option => option)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                style={{ marginBottom: errores[15] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                    />
                                }
                            </React.Fragment>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>¿Cuál?: </Typography>

                            <TextField
                                value={tipoMedicamento}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly || medicamento === 'No' || medicamento === false,
                                }}
                                error={errores[15]}
                                helperText={errores[15] ? 'Este campo no puede quedar vacío' : null}
                                onChange={(e) => { if (e.target.value.length < 146) setTipoMedicamento(e.target.value); errores[15] = false; setErrores([...errores]) }}
                            />
                        </Grid>
                    </Grid>

                    <div>
                        <Typography variant='body1' color='secondary' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Nucleo Familiar:</Typography>
                    </div>

                    {
                        nombreFamiliar.map((v, i) => (
                            <React.Fragment key={"referencia-familiar"}>
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    spacing={4}
                                >
                                    <Grid item md={4} xs={12}>
                                        <Typography variant='body1' style={{ fontWeight: 500 }}>Nombre completo: </Typography>

                                        <TextField
                                            value={nombreFamiliar[i]}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                            onChange={(e) => { nombreFamiliar[i] = e.target.value; setNombreFamiliar([...nombreFamiliar]) }}
                                        />

                                    </Grid>
                                    <Grid item md={4} xs={12}>
                                        <Typography variant='body1' style={{ fontWeight: 500 }}>Parentesco: </Typography>

                                        <TextField
                                            value={parentesco[i]}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                            onChange={(e) => { parentesco[i] = e.target.value; setParentesco([...parentesco]) }}
                                        />

                                    </Grid>
                                    <Grid item md={4} sm={6} xs={12}>
                                        <Typography variant='body1' style={{ fontWeight: 500 }}>Teléfono del familiar:</Typography>

                                        <TextField
                                            value={telefonoFamiliar[i]}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                            onChange={(e) => { telefonoFamiliar[i] = e.target.value; if (e.target.value.length < 11) setTelefonoFamiliar([...telefonoFamiliar]) }}
                                        />
                                    </Grid>
                                </Grid>

                            </React.Fragment>
                        ))
                    }


                    <div>
                        <Typography variant='body1' color='secondary' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Referencias Personales:</Typography>
                    </div>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Nombre completo: </Typography>
                            <TextField
                                value={nombreReferencia}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 61) setNombreReferencia(e.target.value); errores[16] = false; setErrores([...errores]) }}
                                error={errores[16]}
                                helperText={errores[16] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[17], errores[18]].includes(true) && !errores[16] && 'xs' !== point ? theme.spacing(2.8) : null }}

                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Relación: </Typography>

                            <TextField
                                value={relacionReferencia}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 46) setRelacionReferencia(e.target.value); errores[17] = false; setErrores([...errores]) }}
                                error={errores[17]}
                                helperText={errores[17] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[16], errores[18]].includes(true) && !errores[17] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Teléfono:</Typography>

                            <TextField
                                value={telefonoReferencia}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 11) setTelefonoReferencia(e.target.value); errores[18] = false; setErrores([...errores]) }}
                                error={errores[18]}
                                helperText={errores[18] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[16], errores[17]].includes(true) && !errores[18] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Nombre completo: </Typography>

                            <TextField
                                value={nombreReferencia2}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 61) setNombreReferencia2(e.target.value); errores[19] = false; setErrores([...errores]) }}
                                error={errores[19]}
                                helperText={errores[19] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[20], errores[21]].includes(true) && !errores[19] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Relación: </Typography>

                            <TextField
                                value={relacionReferencia2}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 46) setRelacionReferencia2(e.target.value); errores[20] = false; setErrores([...errores]) }}
                                error={errores[20]}
                                helperText={errores[20] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[19], errores[21]].includes(true) && !errores[20] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Teléfono: </Typography>

                            <TextField
                                value={telefonoReferencia2}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { if (e.target.value.length < 11) setTelefonoReferencia2(e.target.value); errores[21] = false; setErrores([...errores]) }}
                                error={errores[21]}
                                helperText={errores[21] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: [errores[19], errores[20]].includes(true) && !errores[21] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>

                    <div>
                        <Typography variant='body1' color='secondary' style={{ fontSize: 16, fontWeight: 500, marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>Información seguridad social:</Typography>
                    </div>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>EPS: </Typography>
                            <React.Fragment>
                                {
                                    readOnly ?
                                        <TextField
                                            value={eps}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                        />
                                        :
                                        <Autocomplete
                                            value={eps}
                                            onChange={(e, v) => { setEps(v) }}
                                            options={
                                                epss.map(option => option.eps)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                />
                                            )}
                                            disableClearable={true}
                                        />
                                }
                            </React.Fragment>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Fondo de pensión: </Typography>
                            <React.Fragment>
                                {
                                    readOnly ?

                                        <TextField
                                            value={fondoPension}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                        />
                                        :
                                        <Autocomplete
                                            value={fondoPension}
                                            onChange={(e, v) => { setFondoPension(v) }}
                                            options={
                                                fondosPension.map(option => option.pension)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                />

                                            )}
                                            disableClearable={true}
                                        />
                                }
                            </React.Fragment>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Estado de afiliación: </Typography>
                            <React.Fragment>
                                {
                                    readOnly ?

                                        <TextField
                                            value={estadoAfiliacion ? 'Activo' : 'Inactivo'}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                        :
                                        <Autocomplete
                                            value={estadoAfiliacion ? 'Activo' : 'Inactivo'}
                                            onChange={(e, v) => { setEstadoAfiliacion(v === 'Activo') }}
                                            options={
                                                ['Activo',
                                                    'Inactivo'].map(option => option)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                />
                                            )}
                                            disableClearable={true}
                                        />
                                }
                            </React.Fragment>

                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Tipo de afiliación: </Typography>
                            <React.Fragment>
                                {
                                    readOnly ?

                                        <TextField
                                            value={tipoAfiliacion}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            InputProps={{
                                                readOnly: readOnly,
                                            }}
                                        />
                                        :
                                        <Autocomplete
                                            value={tipoAfiliacion}
                                            onChange={(e, v) => { setTipoAfiliacion(v) }}
                                            options={
                                                tipoAfiliaciones.map(option => option.tipo)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                />

                                            )}
                                            disableClearable={true}
                                        />
                                }
                            </React.Fragment>
                        </Grid>
                    
                    </Grid>

                </CardContent>
            </Collapse>
        </Card>


    );

}

export default DialogoInformacionPersonal;