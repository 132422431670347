import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    Grid,
    TextField,
    Collapse,
    IconButton,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from "@material-ui/pickers";
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import niveles from '../../../../../data/nivelEstudios'
import areas from '../../../../../data/areaEstudios'
import horarioEstudios from '../../../../../data/horarioEstudios';

function DialogoEstudiosRealizados(props) {
    const { theme,
        point,
        classes,
        readOnly
    } = props;
    const [errores, setErrores] = props.errores;
    const [formatoColapso, setFormatoColapso] = props.formatoColapso;

    const [nivel, setNivel] = props.nivel;
    const [area, setArea] = props.area;
    const [estado, setEstado] = props.estado;
    const [fechaInicio, setFechaInicio] = props.fechaInicio;
    const [fechaFin, setFechaFin] = props.fechaFin;
    const [centroEducativo, setCentroEducativo] = props.centroEducativo;
    const [estudioActual, setEstudioActual] = props.estudioActual;
    const [modalidad, setModalidad] = props.modalidad;
    const [horario, setHorario] = props.horario;

    return (
        <Card variant='outlined' style={{ marginBottom: theme.spacing(2) }}>
            <CardHeader
                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                title={
                    <Typography variant='body1' style={{ fontSize: 18, fontWeight: 500 }}>2. Estudios Realizados</Typography>
                }
                action={
                    <IconButton
                        //disabled={completado[1] === false}
                        onClick={() => { formatoColapso[1] = !formatoColapso[1]; setFormatoColapso([...formatoColapso]) }}
                        color='secondary'
                        aria-label='add'
                        size='medium'
                    >
                        {formatoColapso[1] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                }
            />

            <Collapse in={formatoColapso[1] === true} mountOnEnter unmountOnExit>

                <CardContent style={{ paddingBottom: theme.spacing(2) }}>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Último nivel de estudios: </Typography>

                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={nivel}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: readOnly,
                                        }}
                                        style={{ marginBottom: errores[9] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        value={nivel}
                                        onChange={(e, v) => { setNivel(v); setArea(null); }}
                                        options={
                                            niveles.map(option => option.nivel)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                style={{ marginBottom: errores[9] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                        disableClearable={true}
                                    />
                                }
                            </React.Fragment>

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Área: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={area}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: readOnly,
                                        }}
                                        error={errores[9]}
                                        helperText={errores[9] ? 'Este campo no puede quedar vacío' : null}
                                    />
                                    :
                                    <Autocomplete
                                        disabled={nivel === null}
                                        value={area}
                                        onChange={(e, v) => { setArea(v); errores[9] = false; setErrores([...errores]) }}
                                        options={
                                            (["Preescolar", "Básica Primaria (1° - 5°)", "Básica Secundaria (6° - 9°)", "Media  (10° - 13°)"].includes(nivel) ? areas.filter(m => m.nivel === nivel) : areas.filter(m => m.nivel === "Superior")).map(option => option.area)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    className: nivel === null ? classes.backgroundAutocomplete : null
                                                }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                error={errores[9]}
                                                helperText={errores[9] ? 'Este campo no puede quedar vacío' : null}
                                            />
                                        )}
                                    />
                                }
                            </React.Fragment>


                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Estado: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={estado}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: readOnly,
                                        }}
                                        style={{ marginBottom: errores[9] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        value={estado}
                                        onChange={(e, v) => { setEstado(v); setFechaFin(null); errores[10] = false; setErrores([...errores]) }}
                                        options={
                                            [
                                                "Culminado", "En curso", "Abandonado",
                                                "Aplazado"
                                            ].map(option => option)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                style={{ marginBottom: errores[9] === true && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                        disableClearable={true}
                                    />
                                }
                            </React.Fragment>

                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Fecha de inicio: </Typography>

                            <KeyboardDatePicker
                                autoOk
                                inputVariant='outlined'
                                size='small'
                                variant='inline'
                                fullWidth
                                value={fechaInicio}
                                placeholder="dd/mm/aaaa"
                                onChange={date => { setFechaInicio(date); }}
                                format="dd/MM/yyyy"
                                disabled={readOnly}
                                style={{ marginBottom: [errores[10], errores[11]].includes(true) && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Fecha de finalización: </Typography>

                            <KeyboardDatePicker
                                autoOk
                                inputVariant='outlined'
                                size='small'
                                variant='inline'
                                fullWidth
                                value={fechaFin}
                                placeholder="dd/mm/aaaa"
                                onChange={date => { setFechaFin(date); errores[10] = false; setErrores([...errores]) }}
                                format="dd/MM/yyyy"
                                error={errores[10]}
                                helperText={errores[10] ? ((differenceInCalendarDays(fechaFin, fechaInicio) < 0) ? 'Fecha de finalización es anterior a la fecha de inicio' : 'Este campo no puede quedar vacío') : null}
                                disabled={readOnly || estado === null || estado !== 'Culminado'}
                                style={{ marginBottom: errores[11] === true && !errores[10] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Centro Educativo: </Typography>

                            <TextField
                                value={centroEducativo}
                                size='small'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: readOnly,
                                }}
                                onChange={(e) => { setCentroEducativo(e.target.value); errores[11] = false; setErrores([...errores]) }}
                                error={errores[11]}
                                helperText={errores[11] ? 'Este campo no puede quedar vacío' : null}
                                style={{ marginBottom: errores[10] === true && !errores[11] && 'xs' !== point ? theme.spacing(2.8) : null }}
                            />

                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}>

                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>¿Estudia actualmente?: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={estudioActual ? 'Sí' : 'No'}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: readOnly,
                                        }}
                                        style={{ marginBottom: [errores[12], errores[13]].includes(true) && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        value={estudioActual ? 'Sí' : 'No'}
                                        onChange={(e, v) => { setEstudioActual(v); setModalidad(null); setHorario(null); errores[12] = false; errores[13] = false; setErrores([...errores]) }}
                                        options={
                                            [
                                                "Sí", "No"
                                            ].map(option => option)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                style={{ marginBottom: [errores[12], errores[13]].includes(true) && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                        disableClearable={true}
                                    />
                                }
                            </React.Fragment>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Modalidad: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={modalidad}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        style={{ marginBottom: errores[13] === true && !errores[12] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        disabled={estudioActual === null || estudioActual === 'No'}
                                        value={modalidad}
                                        onChange={(e, v) => { setModalidad(v); errores[12] = false; setErrores([...errores]) }}
                                        options={
                                            [
                                                "Presencial", "Virtual", "Semipresencial"
                                            ].map(option => option)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                error={errores[12]}
                                                helperText={errores[12] ? 'Este campo no puede quedar vacío' : null}
                                                style={{ marginBottom: errores[13] === true && !errores[12] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                            />
                                        )}
                                    />
                                }
                            </React.Fragment>

                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Typography variant='body1' style={{ fontWeight: 500 }}>Horario: </Typography>
                            <React.Fragment>
                                {readOnly ?
                                    <TextField
                                        value={horario}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        style={{ marginBottom: errores[12] === true && !errores[13] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                    />
                                    :
                                    <Autocomplete
                                        disabled={estudioActual === null || estudioActual === 'No'}
                                        value={horario}
                                        onChange={(e, v) => { setHorario(v); errores[13] = false; setErrores([...errores]) }}
                                        options={
                                            horarioEstudios.map(option => option.horario)
                                        }
                                        noOptionsText='No hay coincidencias'
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                error={errores[13]}
                                                helperText={errores[13] ? 'Este campo no puede quedar vacío' : null}
                                                style={{ marginBottom: errores[12] === true && !errores[13] && 'xs' !== point ? theme.spacing(2.8) : null }}
                                                />
                                        )}
                                    />
                                }
                            </React.Fragment>
                        </Grid>
                    </Grid>

                </CardContent>
            </Collapse>
        </Card>

    );

}

export default DialogoEstudiosRealizados;