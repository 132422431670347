import React, { useRef, useState } from 'react';
import {
    Typography,
    Grid,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    IconButton,
    Radio,
    TextField,
    Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import aprobacionDocs from '../../../data/aprobacionDocs';

import { saveAs } from 'file-saver';

function DialogoDocumentos(props) {
    const { theme,
        classes,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        nombre,
        cedula,
        correo,
        estadoContratacion,
        setOpenEstado,
        setMessage,
        setSeverity,
        setSnack,
        setShowBackdrop,
        fetchPersonal
    } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [archivos, setArchivos] = props.archivos
    const [openDocumentos, setOpenDocumentos] = props.open;
    const hiddenInputs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]
    const rol = localStorage.getItem('rol');

    const [aprobacion, setAprobacion] = useState(Array(5).fill(null));
    const [observaciones, setObservaciones] = useState(Array(5).fill(''));
    const [fecha, setFecha] = useState(Array(5).fill('')); // Esta es la fecha de revisión
    const [fechaCreado, setFechaCreado] = useState(Array(5).fill(''));
    const [fechaModificado, setFechaModificado] = useState(Array(5).fill(''));
    const [user, setUser] = useState(Array(5).fill(''));
    const [mensaje, setMensaje] = useState('');
    const [fechaMensaje, setFechaMensaje] = useState(null);
    const [openEliminar, setOpenEliminar] = useState(false);
    const [archivoEliminar, setArchivoEliminar] = useState('');
    const [control, setControl] = useState(Array(22).fill(''));
    const [aprobadoGeneral, setAprobadoGeneral] = useState(null);
    const [fechaAprobadoGeneral, setFechaAprobadoGeneral] = useState('');
    const [userAprobadoGeneral, setUserAprobadoGeneral] = useState('');
    const [contrasenaRUT, setContrasenaRUT] = useState('');


    let now = new Date().toISOString();

    async function postArchivo(archivo, tipo, access = accessToken) {
        if (archivo !== undefined) {
            if (archivo.size < 20971520) { //20Mb
                if (archivo.type.split('/')[0] === 'image' || archivo.type.split('/')[1] === 'pdf' || archivo.type.split('/')[1] === 'msword' || archivo.type.split('/')[1] === 'vnd.openxmlformats-officedocument.wordprocessingml.document' || archivo.type.split('/')[1] === 'wps-office.docx' || archivo.type.split('/')[1] === 'wps-office.doc') {
                    setShowBackdrop(true);

                    let formData = new FormData();
                    formData.append('tipo_documento', tipo);
                    formData.append('archivo', archivo);
                    formData.append('fecha_mod_file', now);
                    console.log(archivo.size)
                    const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/docs_soporte=${cedula}/`, {
                        method: 'POST',
                        headers: { 'Authorization': `Bearer ${access}` },
                        body: formData
                    });
                    if (res.ok) {
                        setShowBackdrop(false);
                        setMessage('Se ha subido el documento correctamente');
                        setSeverity('success');
                        setTimeout(() => { setSnack(true) }, 0);
                        fetchDocumentos(cedula, nombre);
                    }
                    else {
                        res.json().then(async res => {
                            if (res['code'] === 'token_not_valid') {
                                let newAccess = await getAccessTokenWithRefresh();
                                if (newAccess) {
                                    setAccessToken(newAccess);
                                    localStorage.setItem('access', newAccess);

                                    postArchivo(archivo, tipo, newAccess);
                                }
                            } else {
                                setShowBackdrop(false);
                                setMessage('Ocurrió un error, intente de nuevo.');
                                setSeverity('error');
                                setTimeout(() => { setSnack(true) }, 0);
                            }
                        });
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('El tipo de archivo seleccionado no es soportado. Archivos soportados: imagen, pdf o documento de Word');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            } else {
                setShowBackdrop(false);
                setMessage('El tamaño del archivo excede el limite permitido.');
                setSeverity('warning');
                setTimeout(() => { setSnack(true) }, 0);
            }

        }
    }


    async function putEstadoDocumentos(estado, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/persona_seleccion=${cedula}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'aprobado_documentos': estado
            })
        });
        if (!res.ok) {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putEstadoDocumentos(estado, newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }

    async function putControlDocumentos(cedula, access = accessToken) {
        setShowBackdrop(true);
        if (control.every((val, i, arr) => val === arr[0])) {
            setShowBackdrop(false);
            setMessage('Se ha actualizado los documentos.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
        }
        let res = [];
        for (let i = 0; i < control.length; i++) {
            if (control[i] === '') {
                continue;
            } else if (control[i] !== 'general' && archivos[control[i]].length === 0) {
                continue;
            } else {
                if (control[i] === 'general') {
                    const res0 = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/docs_soporte=${cedula}/`, {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
                        body: JSON.stringify({
                            'aprobado_documentos': aprobacionDocs.filter(o => o.estado === aprobadoGeneral)[0].cod,
                            'fecha_mod_documentos': fechaAprobadoGeneral,
                            'general': true
                        })
                    });
                    res = res0;
                } else {
                    const res0 = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/docs_soporte=${cedula}/`, {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
                        body: JSON.stringify({
                            'tipo_documento': control[i],
                            'aprobado': parseInt(aprobacion[control[i]]),
                            'observacion': observaciones[control[i]],
                            'fecha_mod': fecha[control[i]],
                            'password': contrasenaRUT,
                            'general': false
                        })
                    });
                    res = res0;
                }
            }
            if (res.ok) {
                setShowBackdrop(false);
                setMessage('Se ha actualizado los documentos.');
                setSeverity('success');
                setTimeout(() => { setSnack(true) }, 0);
                fetchDocumentos(cedula, nombre);
            }
            else {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);

                            putControlDocumentos(cedula, newAccess);
                        }
                    } else {
                        setShowBackdrop(false);
                        setMessage('Ocurrió un error, intente de nuevo.');
                        setSeverity('error');
                        setTimeout(() => { setSnack(true) }, 0);
                    }
                });
            }
        }
    }
    async function fetchDocumentos(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/docs_soporte=${cedula}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchDocumentos(newAccess);
                }
            }
            else {
                for (let i = 0; i < res.length; i++) {
                    archivos[res[i].tipo_documento] = { 'name': res[i].archivo.split('https://storage.googleapis.com/sistema-cnc/')[1], 'url': res[i].archivo };
                    setArchivos([...archivos])

                    aprobacion[res[i].tipo_documento] = res[i].aprobado
                    setAprobacion([...aprobacion])
                    observaciones[res[i].tipo_documento] = res[i].observacion
                    setObservaciones([...observaciones])
                    fecha[res[i].tipo_documento] = res[i].fecha_mod
                    setFecha([...fecha])
                    fechaCreado[res[i].tipo_documento] = res[i].fecha_creado
                    setFechaCreado([...fechaCreado])
                    fechaModificado[res[i].tipo_documento] = res[i].fecha_mod_file
                    setFechaModificado([...fechaModificado])
                    user[res[i].tipo_documento] = res[i].user_mod
                    setUser([...user])

                    if (res[i].tipo_documento === 3) {
                        setContrasenaRUT(res[i].password);
                    }
                }
                setArchivos(archivos);
                setShowBackdrop(false);
            }
        });
    }
    async function fetchAprobacionDocumentos(cc, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/aprobacion_docs/persona_seleccion=${cedula}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchAprobacionDocumentos(cc, newAccess);
                }
            }
            else {
                setAprobadoGeneral(res.aprobado_documentos);
                setFechaAprobadoGeneral(res.fecha_mod_documentos);
                setUserAprobadoGeneral(res.user_mod_documentos);
                setMensaje(res.cuerpo_correo);
                setFechaMensaje(res.fecha_correo);
                setShowBackdrop(false);
            }
        });
    }

    async function putCorreo(cedula, enviar, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/enviar_correo/persona_seleccion=${cedula}&enviar=${enviar}/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'nombre': nombre,
                'correo': correo,
                'cuerpo_correo': mensaje,
                'fecha_correo': fechaMensaje
            })
        });
        if (res.ok) {
            setShowBackdrop(false);
            setMessage(enviar === 1 ? 'Se ha enviado el correo satisfactoriamente.' : 'Se ha guardado el mesaje satisfactoriamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            limpiar();
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        putCorreo(cedula, enviar, newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }
    async function deleteArchivo(tipo, access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/docs_soporte=${cedula}/`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'tipo_documento': tipo
            })
        })
        if (res.ok) {
            setShowBackdrop(false);
            setMessage('Se ha eliminado el documento correctamente');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);

            limpiar();
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        deleteArchivo(tipo, newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }
    const limpiar = () => {
        setOpenDocumentos(false);
        setControl(Array(6).fill(''));
        setAprobacion(Array(22).fill(null));
        setObservaciones(Array(22).fill(''));
        setFecha(Array(22).fill(''));
        setFechaCreado(Array(22).fill(''));
        setFechaModificado(Array(22).fill(''));
        setArchivos(Array(22).fill([]));
        setArchivoEliminar('');
        setOpenEliminar(false);
        setMensaje('');
        setFechaMensaje(null);
        setAprobadoGeneral(null);
        setFechaAprobadoGeneral('');
        setUserAprobadoGeneral('');
        fetchPersonal();
        setContrasenaRUT('');
    }
    const limpiarDelete = () => {
        setOpenEliminar(false);
        setArchivoEliminar('');
    }
    const dialogoEliminarDocumento = (
        <Dialog
            open={openEliminar}
            onClose={() => limpiarDelete()}
        >
            <DialogTitle>{"¿Eliminar archivo?"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    ¿Estás seguro que quieres eliminar este documento?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { limpiarDelete() }} color="primary">
                    Cancelar
                </Button>
                <Button onClick={() => {
                    let count = 0
                    let i = archivos.length;
                    while (i--) {
                        if (!Array.isArray(archivos[i]))
                            count = count + 1
                    }
                    if (count === 1) {
                        putEstadoDocumentos(0)
                    }
                    deleteArchivo(archivoEliminar)
                }} color="primary" autoFocus>
                    Eliminar
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <div>
            <Dialog
                open={openDocumentos}
                TransitionProps={{
                    onEnter: () => { fetchDocumentos(); fetchAprobacionDocumentos() }
                }}
                onClose={() => limpiar()}
                fullWidth={true}
                maxWidth='md'
            >
                <DialogTitle disableTypography style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item xs={10} md={11}>
                            <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Documentos de {nombre}</Typography>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton onClick={() => limpiar()}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={6} xs={6}>
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Documento de identidad:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[0].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[0].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[0].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[0].name}`, archivos[0].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[0].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[0].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[0].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[0]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 0);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[0].url === undefined}
                                                        onClick={() => { setArchivoEliminar(0); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[0])}
                                                    onChange={(e) => { aprobacion[0] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[0] = now; setFecha([...fecha]); control[0] = 0; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[0].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[0].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[0] === null || fecha[0] === '' ? 'No revisado' : (new Date(fecha[0]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[0]).toLocaleTimeString('en-US')) + (user[0] === null ? '' : (" hecha por " + user[0])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[0] === null || fechaCreado[0] === '' ? 'No subido' : (new Date(fechaCreado[0]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[0]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de modificación: {fechaModificado[0] === null || fechaModificado[0] === '' ? 'No subido' : (new Date(fechaModificado[0]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[0]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Certificado académico:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[1].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[1].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[1].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[1].name}`, archivos[1].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[1].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[1].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[1].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[1]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 1);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[1].url === undefined}
                                                        onClick={() => { setArchivoEliminar(1); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[1])}
                                                    onChange={(e) => { aprobacion[1] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[1] = now; setFecha([...fecha]); control[1] = 1; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[1].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[1].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[1] === null || fecha[1] === '' ? 'No revisado' : (new Date(fecha[0]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[1]).toLocaleTimeString('en-US')) + (user[1] === null ? '' : (" hecha por " + user[1])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[1] === null || fechaCreado[1] === '' ? 'No subido' : (new Date(fechaCreado[1]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[1]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de modificación: {fechaModificado[1] === null || fechaModificado[1] === '' ? 'No subido' : (new Date(fechaModificado[1]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[1]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={6} xs={6}>
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Certificación laboral:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[2].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[2].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[2].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[2].name}`, archivos[2].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[2].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[2].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[2].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[2]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 2);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[2].url === undefined}
                                                        onClick={() => { setArchivoEliminar(2); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[2])}
                                                    onChange={(e) => { aprobacion[2] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[2] = now; setFecha([...fecha]); control[2] = 2; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[2].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[2].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[2] === null || fecha[2] === '' ? 'No revisado' : (new Date(fecha[2]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[2]).toLocaleTimeString('en-US')) + (user[2] === null ? '' : (" hecha por " + user[2])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[2] === null || fechaCreado[2] === '' ? 'No subido' : (new Date(fechaCreado[2]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[2]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ marginTop: theme.spacing(-4) }}>
                                        Fecha de modificación: {fechaModificado[2] === null || fechaModificado[2] === '' ? 'No subido' : (new Date(fechaModificado[2]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[2]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={6} xs={6}>
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Hoja de vida:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[4].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[4].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[4].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[4].name}`, archivos[4].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[4].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[4].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[4].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[4]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 4);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[4].url === undefined}
                                                        onClick={() => { setArchivoEliminar(4); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[4])}
                                                    onChange={(e) => { aprobacion[4] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[4] = now; setFecha([...fecha]); control[4] = 4; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[4].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[4].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[4] === null || fecha[4] === '' ? 'No revisado' : (new Date(fecha[4]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[4]).toLocaleTimeString('en-US')) + (user[4] === null ? '' : (" hecha por " + user[4])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[4] === null || fechaCreado[4] === '' ? 'No subido' : (new Date(fechaCreado[4]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[4]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de modificación: {fechaModificado[4] === null || fechaModificado[4] === '' ? 'No subido' : (new Date(fechaModificado[4]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[4]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    {/* RENDERIZADO CONDICIONAL DE CARGUE DE DOCUMENTOS POR GESTORES SELECCION */}
                    
                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid citem md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Foto tamaño 3x4:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[5].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[5].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[5].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[5].name}`, archivos[5].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[5].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[5].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[5].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[5]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 5);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[5].url === undefined}
                                                        onClick={() => { setArchivoEliminar(2); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[5])}
                                                    onChange={(e) => { aprobacion[5] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[5] = now; setFecha([...fecha]); control[5] = 5; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[5].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[5].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[5] === null || fecha[5] === '' ? 'No revisado' : (new Date(fecha[5]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[5]).toLocaleTimeString('en-US')) + (user[5] === null ? '' : (" hecha por " + user[5])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[5] === null || fechaCreado[5] === '' ? 'No subido' : (new Date(fechaCreado[5]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[5]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ marginTop: theme.spacing(-4) }}>
                                        Fecha de modificación: {fechaModificado[5] === null || fechaModificado[5] === '' ? 'No subido' : (new Date(fechaModificado[5]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[5]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>

                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Constancia de Estudios:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[6].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[6].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[6].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[6].name}`, archivos[6].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[6].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[6].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[6].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[6]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 6);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[6].url === undefined}
                                                        onClick={() => { setArchivoEliminar(6); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[6])}
                                                    onChange={(e) => { aprobacion[6] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[6] = now; setFecha([...fecha]); control[6] = 6; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[6].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[6].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[6] === null || fecha[6] === '' ? 'No revisado' : (new Date(fecha[6]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[6]).toLocaleTimeString('en-US')) + (user[6] === null ? '' : (" hecha por " + user[6])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[6] === null || fechaCreado[6] === '' ? 'No subido' : (new Date(fechaCreado[6]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[6]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de modificación: {fechaModificado[6] === null || fechaModificado[6] === '' ? 'No subido' : (new Date(fechaModificado[6]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[6]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                        </Grid>
                        )}
                        </Grid>
                    </Grid>

                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Tarjeta Profesional:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[7].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[7].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[7].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[7].name}`, archivos[7].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[7].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[7].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[7].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[7]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 7);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[7].url === undefined}
                                                        onClick={() => { setArchivoEliminar(7); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[7])}
                                                    onChange={(e) => { aprobacion[7] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[7] = now; setFecha([...fecha]); control[7] = 7; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[7].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[7].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[7] === null || fecha[7] === '' ? 'No revisado' : (new Date(fecha[7]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[7]).toLocaleTimeString('en-US')) + (user[7] === null ? '' : (" hecha por " + user[7])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[7] === null || fechaCreado[7] === '' ? 'No subido' : (new Date(fechaCreado[7]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[7]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ marginTop: theme.spacing(-4) }}>
                                        Fecha de modificación: {fechaModificado[7] === null || fechaModificado[7] === '' ? 'No subido' : (new Date(fechaModificado[7]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[7]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>

                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Certificado EPS:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[8].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[8].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[8].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[8].name}`, archivos[8].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[8].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[8].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[8].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[8]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 8);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[8].url === undefined}
                                                        onClick={() => { setArchivoEliminar(8); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[8])}
                                                    onChange={(e) => { aprobacion[8] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[8] = now; setFecha([...fecha]); control[8] = 8; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[8].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[8].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[8] === null || fecha[8] === '' ? 'No revisado' : (new Date(fecha[8]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[8]).toLocaleTimeString('en-US')) + (user[8] === null ? '' : (" hecha por " + user[8])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[8] === null || fechaCreado[8] === '' ? 'No subido' : (new Date(fechaCreado[8]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[8]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de modificación: {fechaModificado[8] === null || fechaModificado[8] === '' ? 'No subido' : (new Date(fechaModificado[8]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[8]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>
                    </Grid>
                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Certificado de afiliación pensiones:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[9].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[9].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[9].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[9].name}`, archivos[9].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[9].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[9].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[9].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[9]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 9);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[9].url === undefined}
                                                        onClick={() => { setArchivoEliminar(9); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[9])}
                                                    onChange={(e) => { aprobacion[9] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[9] = now; setFecha([...fecha]); control[9] = 9; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[9].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[9].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[9] === null || fecha[9] === '' ? 'No revisado' : (new Date(fecha[9]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[9]).toLocaleTimeString('en-US')) + (user[9] === null ? '' : (" hecha por " + user[9])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[9] === null || fechaCreado[9] === '' ? 'No subido' : (new Date(fechaCreado[9]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[9]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ marginTop: theme.spacing(-4) }}>
                                        Fecha de modificación: {fechaModificado[9] === null || fechaModificado[9] === '' ? 'No subido' : (new Date(fechaModificado[9]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[9]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>

                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Certificado de afiliación cesantias:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[10].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[10].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[10].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[10].name}`, archivos[10].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[10].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[10].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[10].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[10]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 10);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[10].url === undefined}
                                                        onClick={() => { setArchivoEliminar(10); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[10])}
                                                    onChange={(e) => { aprobacion[10] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[10] = now; setFecha([...fecha]); control[10] = 10; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[10].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[10].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[10] === null || fecha[10] === '' ? 'No revisado' : (new Date(fecha[10]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[10]).toLocaleTimeString('en-US')) + (user[10] === null ? '' : (" hecha por " + user[10])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[10] === null || fechaCreado[10] === '' ? 'No subido' : (new Date(fechaCreado[10]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[10]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de modificación: {fechaModificado[10] === null || fechaModificado[10] === '' ? 'No subido' : (new Date(fechaModificado[10]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[10]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>
                    </Grid>


                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Documento de beneficiarios:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[11].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[11].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[11].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[11].name}`, archivos[11].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[11].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[11].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[11].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[11]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 11);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[11].url === undefined}
                                                        onClick={() => { setArchivoEliminar(11); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[11])}
                                                    onChange={(e) => { aprobacion[11] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[11] = now; setFecha([...fecha]); control[11] = 11; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[11].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[11].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[11] === null || fecha[11] === '' ? 'No revisado' : (new Date(fecha[11]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[11]).toLocaleTimeString('en-US')) + (user[11] === null ? '' : (" hecha por " + user[11])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[11] === null || fechaCreado[11] === '' ? 'No subido' : (new Date(fechaCreado[11]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[11]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ marginTop: theme.spacing(-4) }}>
                                        Fecha de modificación: {fechaModificado[11] === null || fechaModificado[11] === '' ? 'No subido' : (new Date(fechaModificado[11]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[11]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>

                        
                    </Grid>


                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Antecedentes Policia:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[12].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[12].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[12].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[12].name}`, archivos[12].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[12].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[12].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[12].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[12]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 12);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[12].url === undefined}
                                                        onClick={() => { setArchivoEliminar(12); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[12])}
                                                    onChange={(e) => { aprobacion[12] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[12] = now; setFecha([...fecha]); control[12] = 12; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[12].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[12].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[12] === null || fecha[12] === '' ? 'No revisado' : (new Date(fecha[12]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[12]).toLocaleTimeString('en-US')) + (user[12] === null ? '' : (" hecha por " + user[12])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[12] === null || fechaCreado[12] === '' ? 'No subido' : (new Date(fechaCreado[12]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[12]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ marginTop: theme.spacing(-4) }}>
                                        Fecha de modificación: {fechaModificado[12] === null || fechaModificado[12] === '' ? 'No subido' : (new Date(fechaModificado[12]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[12]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>

                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Antecedentes Procuraduría:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[4].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[13].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[13].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[13].name}`, archivos[13].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[13].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[13].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[13].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[13]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 13);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[13].url === undefined}
                                                        onClick={() => { setArchivoEliminar(13); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[13])}
                                                    onChange={(e) => { aprobacion[13] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[13] = now; setFecha([...fecha]); control[13] = 13; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[13].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[13].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[13] === null || fecha[13] === '' ? 'No revisado' : (new Date(fecha[13]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[13]).toLocaleTimeString('en-US')) + (user[13] === null ? '' : (" hecha por " + user[13])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[13] === null || fechaCreado[13] === '' ? 'No subido' : (new Date(fechaCreado[13]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[13]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de modificación: {fechaModificado[13] === null || fechaModificado[13] === '' ? 'No subido' : (new Date(fechaModificado[13]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[13]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>
                    </Grid>

                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Antecedentes Contraloría:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[14].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[14].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[14].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[14].name}`, archivos[14].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[14].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[14].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[14].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[14]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 14);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[14].url === undefined}
                                                        onClick={() => { setArchivoEliminar(14); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[14])}
                                                    onChange={(e) => { aprobacion[14] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[14] = now; setFecha([...fecha]); control[14] = 14; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[14].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[14].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[14] === null || fecha[14] === '' ? 'No revisado' : (new Date(fecha[14]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[14]).toLocaleTimeString('en-US')) + (user[14] === null ? '' : (" hecha por " + user[14])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[14] === null || fechaCreado[14] === '' ? 'No subido' : (new Date(fechaCreado[14]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[14]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ marginTop: theme.spacing(-4) }}>
                                        Fecha de modificación: {fechaModificado[14] === null || fechaModificado[14] === '' ? 'No subido' : (new Date(fechaModificado[14]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[14]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>

                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Acuerdo de Confidencialidad:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[15].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[15].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[15].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[15].name}`, archivos[15].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[15].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[15].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[15].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[15]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 15);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[15].url === undefined}
                                                        onClick={() => { setArchivoEliminar(15); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[15])}
                                                    onChange={(e) => { aprobacion[15] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[15] = now; setFecha([...fecha]); control[15] = 15; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[15].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[15].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[15] === null || fecha[15] === '' ? 'No revisado' : (new Date(fecha[15]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[15]).toLocaleTimeString('en-US')) + (user[15] === null ? '' : (" hecha por " + user[15])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[15] === null || fechaCreado[15] === '' ? 'No subido' : (new Date(fechaCreado[15]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[15]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de modificación: {fechaModificado[15] === null || fechaModificado[15] === '' ? 'No subido' : (new Date(fechaModificado[15]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[15]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>
                    </Grid>



                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Contrato Firmado CCD:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[16].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[16].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[16].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[16].name}`, archivos[16].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[16].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[16].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[16].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[16]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 16);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[16].url === undefined}
                                                        onClick={() => { setArchivoEliminar(16); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[16])}
                                                    onChange={(e) => { aprobacion[16] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[16] = now; setFecha([...fecha]); control[16] = 16; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[16].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[16].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[16] === null || fecha[16] === '' ? 'No revisado' : (new Date(fecha[16]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[16]).toLocaleTimeString('en-US')) + (user[16] === null ? '' : (" hecha por " + user[16])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[16] === null || fechaCreado[16] === '' ? 'No subido' : (new Date(fechaCreado[16]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[16]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ marginTop: theme.spacing(-4) }}>
                                        Fecha de modificación: {fechaModificado[16] === null || fechaModificado[16] === '' ? 'No subido' : (new Date(fechaModificado[16]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[16]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>

                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Perfil de Cargo:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[17].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[17].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[17].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[17].name}`, archivos[17].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[17].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[17].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[17].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[17]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 17);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[17].url === undefined}
                                                        onClick={() => { setArchivoEliminar(17); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[17])}
                                                    onChange={(e) => { aprobacion[17] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[17] = now; setFecha([...fecha]); control[17] = 17; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[17].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[17].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[17] === null || fecha[17] === '' ? 'No revisado' : (new Date(fecha[17]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[17]).toLocaleTimeString('en-US')) + (user[17] === null ? '' : (" hecha por " + user[17])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[17] === null || fechaCreado[17] === '' ? 'No subido' : (new Date(fechaCreado[17]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[17]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de modificación: {fechaModificado[17] === null || fechaModificado[17] === '' ? 'No subido' : (new Date(fechaModificado[17]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[17]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>
                    </Grid>



                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Examen Medico:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[18].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[18].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[18].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[18].name}`, archivos[18].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[18].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[18].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[18].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[18]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 18);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[18].url === undefined}
                                                        onClick={() => { setArchivoEliminar(18); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[18])}
                                                    onChange={(e) => { aprobacion[18] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[18] = now; setFecha([...fecha]); control[18] = 18; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[18].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[18].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[18] === null || fecha[18] === '' ? 'No revisado' : (new Date(fecha[18]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[18]).toLocaleTimeString('en-US')) + (user[18] === null ? '' : (" hecha por " + user[18])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[18] === null || fechaCreado[18] === '' ? 'No subido' : (new Date(fechaCreado[18]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[18]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ marginTop: theme.spacing(-4) }}>
                                        Fecha de modificación: {fechaModificado[18] === null || fechaModificado[18] === '' ? 'No subido' : (new Date(fechaModificado[18]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[18]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>

                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Soporte ARL:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[19].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[19].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[19].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[19].name}`, archivos[19].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[19].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[19].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[19].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[19]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 19);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[19].url === undefined}
                                                        onClick={() => { setArchivoEliminar(19); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[19])}
                                                    onChange={(e) => { aprobacion[19] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[19] = now; setFecha([...fecha]); control[19] = 19; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[19].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[19].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[19] === null || fecha[19] === '' ? 'No revisado' : (new Date(fecha[19]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[19]).toLocaleTimeString('en-US')) + (user[19] === null ? '' : (" hecha por " + user[19])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[19] === null || fechaCreado[19] === '' ? 'No subido' : (new Date(fechaCreado[19]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[19]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de modificación: {fechaModificado[19] === null || fechaModificado[19] === '' ? 'No subido' : (new Date(fechaModificado[19]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[19]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>
                    </Grid>



                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Soporte EPS:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[20].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[20].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[20].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[20].name}`, archivos[20].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[20].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[20].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[20].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[20]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 20);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[20].url === undefined}
                                                        onClick={() => { setArchivoEliminar(20); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[20])}
                                                    onChange={(e) => { aprobacion[20] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[20] = now; setFecha([...fecha]); control[20] = 20; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[20].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[20].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[20] === null || fecha[20] === '' ? 'No revisado' : (new Date(fecha[20]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[20]).toLocaleTimeString('en-US')) + (user[20] === null ? '' : (" hecha por " + user[20])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[20] === null || fechaCreado[20] === '' ? 'No subido' : (new Date(fechaCreado[20]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[20]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ marginTop: theme.spacing(-19) }}>
                                        Fecha de modificación: {fechaModificado[20] === null || fechaModificado[20] === '' ? 'No subido' : (new Date(fechaModificado[20]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[20]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>

                        <Grid item md={6} xs={6}>
                        {estadoContratacion === 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>Soporte Caja:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[21].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[21].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[21].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[21].name}`, archivos[21].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[21].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[21].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[21].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[21]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 21);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[21].url === undefined}
                                                        onClick={() => { setArchivoEliminar(21); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[21])}
                                                    onChange={(e) => { aprobacion[21] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[21] = now; setFecha([...fecha]); control[21] = 21; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[21].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[21].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[21] === null || fecha[21] === '' ? 'No revisado' : (new Date(fecha[21]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[21]).toLocaleTimeString('en-US')) + (user[21] === null ? '' : (" hecha por " + user[21])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[21] === null || fechaCreado[21] === '' ? 'No subido' : (new Date(fechaCreado[21]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[21]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de modificación: {fechaModificado[21] === null || fechaModificado[21] === '' ? 'No subido' : (new Date(fechaModificado[21]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[21]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        </Grid>
                    </Grid>


                    
                    


                        
                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />

                    <Grid
                        container
                        direction='row'
                        alignItems='flex-start'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={6} xs={6}>
                        {estadoContratacion !== 'Registrado Laboral'  && (
                            <Grid
                                container
                                direction='column'
                                alignItems='flex-start'
                                spacing={2}
                            >
                                <Grid item md={12} xs={12}>
                                    <Typography variant='body1' style={{ fontWeight: 500 }}>RUT COD. 49:</Typography>
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        spacing={10}
                                    >
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title={archivos[3].url === undefined ? 'Subir archivo' : 'Descargar archivo'}>
                                                <div>
                                                    <IconButton
                                                        variant="contained"
                                                        color={archivos[3].url === undefined ? 'inherit' : 'primary'}
                                                        onClick={() => {
                                                            if (archivos[3].url !== undefined) {
                                                                saveAs(`https://sistema-cnc.storage.googleapis.com/${archivos[3].name}`, archivos[3].name.split('/').slice(-1)[0])
                                                            }
                                                            else {
                                                                hiddenInputs[3].current.click();
                                                            }
                                                        }}
                                                        disabled={archivos[3].url === undefined && rol !== 'gestor'}
                                                    >
                                                        {archivos[3].url === undefined ? <CloudUploadIcon /> : <CloudDownloadIcon />}
                                                    </IconButton>
                                                    <input
                                                        type="file"
                                                        ref={hiddenInputs[3]}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => {
                                                            postArchivo(e.target.files[0], 3);
                                                            e.target.value = null;
                                                            if ((archivos.some((v) => v !== null)) && aprobadoGeneral === 'Sin documentos') {
                                                                putEstadoDocumentos(1);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={2} xs={2}>
                                            <Tooltip title='Eliminar archivo'>
                                                <div>
                                                    <IconButton
                                                        disabled={archivos[3].url === undefined}
                                                        onClick={() => { setArchivoEliminar(3); setOpenEliminar(true) }}
                                                        className={classes.conditionalRoleVisibility}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item md={7} xs={6} align='right' >
                                            <FormControl component="fieldset">
                                                <RadioGroup
                                                    row
                                                    value={parseInt(aprobacion[3])}
                                                    onChange={(e) => { aprobacion[3] = parseInt(e.target.value); setAprobacion([...aprobacion]); fecha[3] = now; setFecha([...fecha]); control[3] = 3; setControl([...control]) }}
                                                >
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[3].length === 0} value={2} control={<Radio />} label={<CheckIcon />} />
                                                    <FormControlLabel className={classes.conditionalRoleVisibility} disabled={archivos[3].length === 0} value={3} control={<Radio />} label={<CloseIcon />} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption' style={{ fontWeight: 400 }}>
                                        Última revisión: {fecha[3] === null || fecha[3] === '' ? 'No revisado' : (new Date(fecha[3]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fecha[3]).toLocaleTimeString('en-US')) + (user[3] === null ? '' : (" hecha por " + user[3])) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de carga: {fechaCreado[3] === null || fechaCreado[3] === '' ? 'No subido' : (new Date(fechaCreado[3]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaCreado[3]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12} align='left'>
                                    <Typography variant='caption'>
                                        Fecha de modificación: {fechaModificado[3] === null || fechaModificado[3] === '' ? 'No subido' : (new Date(fechaModificado[3]).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaModificado[3]).toLocaleTimeString('en-US')) + '.\n'}
                                    </Typography>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                        justifyContent='flex-start'
                                        spacing={2}>
                                        <Grid item md={11} xs={11}>
                                            <Typography variant='body1'>Contraseña RUT: </Typography>
                                            <TextField
                                                value={contrasenaRUT}
                                                onChange={(e) => { setContrasenaRUT(e.target.value); control[3] = 3; setControl([...control]) }}
                                                size='small'
                                                variant='outlined'
                                                fullWidth
                                                disabled={control[3] === ''}
                                            />
                                        </Grid>
                                        <Grid item md={1} xs={1} style={{ marginTop: theme.spacing(3) }}>
                                            <IconButton
                                                onClick={(e) => { control[3] = 3; setControl([...control]) }}
                                                className={classes.conditionalRoleVisibility}
                                            >
                                                <EditIcon />
                                            </IconButton>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                            </Grid>
                    </Grid>

                    <Divider style={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(2) }} />
                    <Typography variant='body1' style={{ fontWeight: 500, marginLeft: theme.spacing(2), marginBottom: theme.spacing(2) }}>Aprobado general: Evalúe el estado general de los documentos de {nombre}.</Typography>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        <Grid item md={4} xs={4}>

                            <Autocomplete
                                value={aprobadoGeneral}
                                onChange={(e, v) => { setAprobadoGeneral(v); setFechaAprobadoGeneral(now); control[5] = 'general'; setControl([...control]) }}
                                options={
                                    aprobacionDocs.slice(1, 6).map(option => option.estado)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                    />

                                )}
                                disabled={rol !== 'gestor'}
                            />
                        </Grid>
                    </Grid>
                    <Grid item md={12} xs={12} align='left'>
                        <Typography variant='caption' style={{ marginLeft: theme.spacing(2) }}>
                            Última modificación: {fechaAprobadoGeneral === null || fechaAprobadoGeneral === '' ? 'No revisado.' : (new Date(fechaAprobadoGeneral).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' }) + " - " + new Date(fechaAprobadoGeneral).toLocaleTimeString('en-US')) + (userAprobadoGeneral === null ? '' : (" hecha por " + userAprobadoGeneral))}
                        </Typography>
                    </Grid>
                    {/* <Collapse in={aprobacion.includes(3)} mountOnEnter unmountOnExit> */}
                    <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} />
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(2), marginTop: theme.spacing(2), marginRight: theme.spacing(2) }}
                        className={classes.conditionalRoleDisplay}
                    >
                        <Grid item md={10} xs={10}>
                            <Typography style={{ fontWeight: 500 }}> Enviar correo electrónico:</Typography>
                            <TextField
                                value={mensaje}
                                label={mensaje === '' ? 'Cuerpo del correo' : ''}
                                multiline
                                fullWidth
                                variant='outlined'
                                rows={2}
                                onChange={(e) => { if (e.target.value.length < 1001) setMensaje(e.target.value); setFechaMensaje(now); }}
                            >
                            </TextField>
                        </Grid>
                        <Grid item md={2} xs={2}>
                            <Tooltip title={'Enviar'}>
                                <IconButton
                                    color='primary'
                                    onClick={() => { putControlDocumentos(cedula); putCorreo(cedula, 1) }}
                                >
                                    <SendIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Guarda este mensaje sin enviarlo por correo a la persona.">
                                <IconButton
                                    color='primary'
                                    onClick={() => { putCorreo(cedula, 0) }}
                                >
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}
                        className={classes.conditionalRoleDisplay}
                    >
                        <Grid item md={12} xs={12}>
                            <Typography
                                variant='caption'
                            >
                                {(fechaMensaje === null || fechaMensaje === undefined) ? 'No se ha enviado ningún correo anteriormente.' : ('El último correo fue enviado el ' + (new Date(fechaMensaje).toLocaleDateString("es-ES", { day: 'numeric', month: 'long', year: 'numeric' })) + '.')}
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* </Collapse> */}
                </DialogContent>

                <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} />
                <DialogActions style={{ padding: theme.spacing(1.5) }}>
                    <Button size='small' variant='contained' color='primary' onClick={() => { setOpenEstado(true) }}>
                        Estado
                    </Button>
                    <Button size='small' variant='contained' color='primary' onClick={() => { putControlDocumentos(cedula) }} className={classes.conditionalRoleDisplay}>
                        Guardar
                    </Button>
                    <Button size='small' variant='contained' color='secondary' onClick={() => { limpiar() }}>
                        Cancelar
                    </Button>
                </DialogActions>
                {dialogoEliminarDocumento}
            </Dialog>
        </div>
    );
}

export default DialogoDocumentos;