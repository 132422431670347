const epss = [
    {cod: 0, eps: 'ECOOPSOS -ENTIDAD COOPERATIVA SOLIDARIA DE SALUD'},
    {cod: 1, eps: 'EMDISALUD ESS - EMPRESA MUTUAL DLLO INT. SALUD'},
    {cod: 2, eps: 'EMSSANAR E.S.S. EMPRESA SOLIDARIA DE SALUD NARINO'},
    {cod: 3, eps: 'ENTIDAD PROMOTORA DE SALUD MALLAMAS EPSI'},
    {cod: 4, eps: 'EPS ALIANZA MEDELLIN ANTIOQUIA E.P.S. SAS '},
    {cod: 5, eps: 'EPS COLPATRIA'},
    {cod: 6, eps: 'EPS FAMISANAR LTDA'},
    {cod: 7, eps: 'EPS PROGRAMA DE SALUD U DE A'},
    {cod: 8, eps: 'FONDO DE PASIVO SOCIAL FERROCARRILES NACIONALES DE'},
    {cod: 9, eps: 'GOLDEN GROUP S.A. ENTIDAD PROMOTORA DE SALUD'},
    {cod: 10, eps: 'HUMANA VIVIR S A E P S'},
    {cod: 11, eps: 'ISS E P S'},
    {cod: 12, eps: 'MANEXKA - ASOC. CABILDOS INDÍG. RESGUARDO ZENÚ'},
    {cod: 13, eps: 'MEDIMAS EPS S.A.S'},
    {cod: 14, eps: 'MULTIMÉDICAS EPS'},
    {cod: 15, eps: 'MUNICIPIO DE MEDELLIN'},
    {cod: 16, eps: 'NINGUNA EPS'},
    {cod: 17, eps: 'NUEVA EPS S.A.'},
    {cod: 18, eps: 'PIJAOSALUD EPSI - ENTIDAD PROMOTORA DE SALUD'},
    {cod: 19, eps: 'RED SALUD'},
    {cod: 20, eps: 'RISARALDA E P S'},
    {cod: 21, eps: 'SALUD COLOMBIA - REGIMEN CONTRIBUTIVO'},
    {cod: 22, eps: 'SALUD COLPATRIA E P S'},
    {cod: 23, eps: 'SALUD TOTAL S.A. ENTIDAD PROMOTORA DE SALUD'},
    {cod: 24, eps: 'SALUD VIDA S.A. ENTIDAD PROMOTORA DE SALUD'},
    {cod: 25, eps: 'SALUDCOOP EPS'},
    {cod: 26, eps: 'SANIDAD FUERZAS MILITARES'},
    {cod: 27, eps: 'SANITAS EPS - ENTIDAD PROMOTORA DE SALUD SANITAS'},
    {cod: 28, eps: 'SAVIA SALUD EPS (COMFAMA)'},
    {cod: 29, eps: 'SELVASALUD S A E P S'},
    {cod: 30, eps: 'SERVICIO MEDICO Y ODONTOLOGICO EAAB-ESP'},
    {cod: 31, eps: 'SISTEMA UNIVERSITARIO DE SALUD'},
    {cod: 32, eps: 'SOLSALUD E P S'},
    {cod: 33, eps: 'SOS EPS (OCCIDENTAL DE SALUD S.A)'},
    {cod: 34, eps: 'SSS EMPRESAS PUBLICAS DE MED SERVICIOS MEDICOS'},
    {cod: 35, eps: 'SURA EPS'},
    {cod: 36, eps: 'UNIMEC EPS'},
    {cod: 37, eps: 'UNISALUD'},
    {cod: 38, eps: 'CAJACOPI ATLANTICO - CCF'},
    {cod: 39, eps: 'A.I.C- ASOCIACIÓN INDÍGENA DEL CAUCA'},
    {cod: 40, eps: 'ALIANSALUD ENTIDAD PROMOTORA DE SALUD S.A.'},
    {cod: 41, eps: 'AMBUQ - ASOC. MUTUAL BARRIOS UNIDOS QUIBDÓ E.S.S.'},
    {cod: 42, eps: 'ANAS WAYUU - ENTIDAD PROMOTORA DE SALUD'},
    {cod: 43, eps: 'ASMET SALUD - ASOCIACIÓN MUTUAL LA ESPERANZA'},
    {cod: 44, eps: 'ASOCIACIÓN MUTUAL SER EMPRESA SOLIDARIA SALUD ESS'},
    {cod: 45, eps: 'BARRANQUILLA SANA EPS'},
    {cod: 46, eps: 'CAFESALUD EPS'},
    {cod: 47, eps: 'CAJA DE COMPENSACIÓN FAMILIAR CAFAM EPS'},
    {cod: 48, eps: 'CAJANAL EPS'},
    {cod: 49, eps: 'CALISALUD EPS'},
    {cod: 50, eps: 'CAPITAL SALUD EPSS S.A.S.'},
    {cod: 51, eps: 'CAPRECOM - CAJA DE PREVISIÓN SOCIAL DE COMUNIC EPS'},
    {cod: 52, eps: 'CAPRESOCA EPS'},
    {cod: 53, eps: 'COLSEGUROS EPS'},
    {cod: 54, eps: 'COLSUBSIDIO EPS - CAJA CBIANA DE SUBSIDIO FAMILIAR'},
    {cod: 55, eps: 'COMFABOY EPS-CCF DE BOYACÁ'},
    {cod: 56, eps: 'COMFACHOCO-CCF DEL CHOCÓ'},
    {cod: 57, eps: 'COMFACOR EPS-CCF DE CÓRDOBA'},
    {cod: 58, eps: 'COMFACUNDI - CCF DE CUNDINAMARCA'},
    {cod: 59, eps: 'COMFAMILIAR CARTAGENA'},
    {cod: 60, eps: 'COMFAMILIAR DE LA GUAJIRA EPS-CCF'},
    {cod: 61, eps: 'COMFAMILIAR HUILA EPS-CCF'},
    {cod: 62, eps: 'COMFAMILIAR NARIÑO EPS-CCF'},
    {cod: 63, eps: 'COMFASUCRE EPS-CCF DE SUCRE'},
    {cod: 64, eps: 'COMFENALCO ANTIOQUIA EPS'},
    {cod: 65, eps: 'COMFENALCO QUINDIO'},
    {cod: 66, eps: 'COMFENALCO VALLE EPS'},
    {cod: 67, eps: 'COMPARTA - COOPERATIVA DE SALUD COMUNITARIA'},
    {cod: 68, eps: 'COMPENSAR ENTIDAD PROMOTORA DE SALUD'},
    {cod: 69, eps: 'CONSORCIO FISALUD (FOSYGA)'},
    {cod: 70, eps: 'CONVIDA - ENTIDAD ADM DE RÉGIMEN SUBSIDIADO'},
    {cod: 71, eps: 'COOMEVA EPS. S.A.'},
    {cod: 72, eps: 'COOSALUD E.S.S. COOP.SALUD Y DLLO INT. CARTAGENA'},
    {cod: 73, eps: 'CRUZ BLANCA ENTIDAD PROMOTORA DE SALUD S.A.'},
    {cod: 74, eps: 'DUSAKAWI- ASOCIACIÓN CABILDOS INDÍGENAS DEL CESAR'},
    {cod: 75, eps: 'EPS CONDOR SA'},
    {cod: 76, eps: 'EPS DE CALDAS SA'},
    {cod: 77, eps: 'SALUD MIA'},
    {cod: 78, eps: 'ECOPETROL'},
    {cod: 79, eps: 'SERVISALUD'},
    {cod: 80, eps: 'RED VITAL'},
    {cod: 81, eps: 'COSMITET LTDA'},
    {cod: 84, eps: 'COMFAORIENTE'},
];
export default epss;