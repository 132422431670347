import React, { useState, useRef } from 'react';
import {
    Typography,
    Grid,
    Divider,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    TextField,
    DialogActions,
    Button,
    Collapse,
    Link,
    Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import WorkIcon from '@material-ui/icons/Work';

import { Autocomplete } from '@material-ui/lab';
import DialogoContratacion from './DialogoContratacion';
import { saveAs } from 'file-saver'; 
import { KeyboardDatePicker } from "@material-ui/pickers";
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import estadosContratacion from '../../../data/estadosContratacion'
import municipios from '../../../data/municipios';

function DialogoEditarEstado(props) {

    const { theme,
        classes,
        API_DEFAULT,
        getAccessTokenWithRefresh,
        point,
        nombre,
        cedula,
        celular,
        correo,
        ciudad,
        hayContratos,
        setShowBackdrop,
        setMessage,
        setSeverity,
        setSnack,
        fetchPersonal,
    } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [openEditarEstado, setOpenEditarEstado] = props.open;
    const [estado, setEstado] = props.estado;
    const [fechaInicio, setFechaInicio] = useState(null);
    const [fechaFin, setFechaFin] = useState(null);
    const [duracion, setDuracion] = useState('');
    const [honorariosLetra, setHonorariosLetra] = useState('');
    const [honorariosNumero, setHonorariosNumero] = useState('');
    const [errores, setErrores] = useState(Array(5).fill(false))
    const [carnet, setCarnet] = useState(null);
    const [nombreCarnet, setNombreCarnet] = useState('');
    const [archivosContrato, setArchivosContrato] = useState(Array(2).fill(null))
    const [nombresContrato, setNombresContrato] = useState(Array(2).fill(''));
    const [dateAlert, setDateAlert] = useState(false);
    const hiddenInputs = [useRef(null)];
    const rol = localStorage.getItem('rol');

    const [openContratacion, setOpenContratacion] = useState(false);

    const validar = () => {
        let error = false;

        if (estado.includes('Apto') && fechaInicio === null) {
            error = true;
            errores[0] = true;
        }
        if (estado.includes('Apto') && (fechaFin === null || differenceInCalendarDays(fechaFin, fechaInicio) < 0)) {
            error = true;
            errores[1] = true;
        }
        if (estado.includes('Apto') && duracion === '') {
            error = true;
            errores[2] = true;
        }
        if ((estado === 'Apto contratación (AP)' || estado === 'Apto contratación (ECAR)') && honorariosNumero === '') {
            error = true;
            errores[3] = true;
        }
        if ((estado === 'Apto contratación (AP)' || estado === 'Apto contratación (ECAR)') && honorariosLetra === '') {
            error = true;
            errores[4] = true;
        }
        if (error) {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        } else {
            putEstadoContratacion()
        }


    }

    async function fetchCarnet(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/basicos_persona=${cedula}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchCarnet(newAccess);
                }
            }
            else {
                setShowBackdrop(false);
                if (res.carnet !== null) {
                    setCarnet(res.carnet);
                    
                    setNombreCarnet(res.carnet.split('https://storage.googleapis.com/sistema-cnc/')[1])
                }
            }

        });
    }
    async function fetchContratacion(access = accessToken) {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/contratacion/persona_seleccion=${cedula}&perfil=${estadosContratacion.filter(e => e.estado === estado)[0].cargo_proceso}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchContratacion(newAccess);
                }
            }
            else {
                setShowBackdrop(false);
                archivosContrato[0] = res.contrato;
                archivosContrato[1] = res.comodato;
                setArchivosContrato([...archivosContrato]);
                nombresContrato[0] = res.contrato;
                nombresContrato[1] = res.comodato;
                setNombresContrato([...nombresContrato]);
                setFechaInicio((res.fecha_inicio_contrato === null) || (res.fecha_inicio_contrato === undefined) ? null : new Date(res.fecha_inicio_contrato.split('-')[0], res.fecha_inicio_contrato.split('-')[1] - 1, res.fecha_inicio_contrato.split('-')[2]))
                setFechaFin((res.fecha_fin_contrato === null) || (res.fecha_fin_contrato === undefined) ? null : new Date(res.fecha_fin_contrato.split('-')[0], res.fecha_fin_contrato.split('-')[1] - 1, res.fecha_fin_contrato.split('-')[2]));
                setDuracion(res.duracion);
                setDateAlert((res.fecha_inicio_contrato === null) || (res.fecha_inicio_contrato === undefined) ? true : false);
            }

        });
    }
    async function putEstadoContratacion(access = accessToken) {
        setShowBackdrop(true);
        if (estado.includes('Apto')) {
            const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/contratacion/persona_seleccion=${cedula}&perfil=${estadosContratacion.filter(e => e.estado === estado)[0].cargo_proceso}/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
                body: JSON.stringify({
                    'fecha_inicio_contrato': fechaInicio.toISOString().split('T')[0],
                    'fecha_fin_contrato': fechaFin.toISOString().split('T')[0],
                    'duracion': duracion,
                    'celular': celular,
                    'correo': correo,
                    'nombre': nombre,
                    'ciudad': municipios.filter(m => m.daneMuni === ciudad)[0].municipio,
                    'honorarios_num': honorariosNumero,
                    'honorarios_letra': honorariosLetra
                })
            });
            if (res.ok) {
                const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/persona_seleccion=${cedula}/`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
                    body: JSON.stringify({
                        'estado_contratacion': estadosContratacion.filter(e => e.estado === estado)[0].cod
                    })
                });
                if (res.ok) {
                    setShowBackdrop(false);
                    setMessage('Se ha actualizado el estado de la persona exitosamente. Contrato creado');
                    setSeverity('success');
                    setTimeout(() => { setSnack(true) }, 0);
                    limpiar();
                    fetchPersonal();
                } else {
                    res.json().then(async res => {
                        if (res['code'] === 'token_not_valid') {
                            let newAccess = await getAccessTokenWithRefresh();
                            if (newAccess) {
                                setAccessToken(newAccess);
                                localStorage.setItem('access', newAccess);

                                putEstadoContratacion(newAccess);
                            }
                        } else {
                            setShowBackdrop(false);
                            setMessage('Ocurrió un error, intente de nuevo.');
                            setSeverity('error');
                            setTimeout(() => { setSnack(true) }, 0);
                        }
                    });
                }
            }
            else {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);

                            putEstadoContratacion(newAccess);
                        }
                    } else {
                        setShowBackdrop(false);
                        setMessage('Ocurrió un error, intente de nuevo.');
                        setSeverity('error');
                        setTimeout(() => { setSnack(true) }, 0);
                    }
                });
            }

        }
        else {
            const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/persona_seleccion=${cedula}/`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
                body: JSON.stringify({
                    'estado_contratacion': estadosContratacion.filter(e => e.estado === estado)[0].cod
                })
            });
            if (res.ok) {
                setShowBackdrop(false);
                setMessage('Se ha actualizado el estado de la persona exitosamente.');
                setSeverity('success');
                setTimeout(() => { setSnack(true) }, 0);
                limpiar();
                fetchPersonal();
            } else {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);

                            putEstadoContratacion(newAccess);
                        }
                    } else {
                        setShowBackdrop(false);
                        setMessage('Ocurrió un error, intente de nuevo.');
                        setSeverity('error');
                        setTimeout(() => { setSnack(true) }, 0);
                    }
                });
            }
        }
    }

    async function postArchivo(archivo, ext, access = accessToken) {
        if (archivo !== undefined) {
            if (archivo.type.split('/')[1] === 'pdf' || archivo.type.split('/')[1] === 'msword' || archivo.type.split('/')[1] === 'vnd.openxmlformats-officedocument.wordprocessingml.document' || archivo.type.split('/')[1] === 'wps-office.docx' || archivo.type.split('/')[1] === 'wps-office.doc') {
                setShowBackdrop(true);

                let formData = new FormData();
                formData.append('carnet', archivo);
                formData.append('ext', ext);

                const res = await fetch(`${API_DEFAULT}/seleccion/gestor_seleccion/carnet_persona=${cedula}/`, {
                    method: 'PUT',
                    headers: { 'Authorization': `Bearer ${access}` },
                    body: formData
                })

                if (res.ok) {
                    setShowBackdrop(false);
                    setMessage('Se ha guardado el carnet correctamente');
                    setSeverity('success');
                    setTimeout(() => { setSnack(true) }, 0);
                    setOpenEditarEstado(false);
                    setOpenEditarEstado(true);

                }
                else {
                    res.json().then(async res => {
                        if (res['code'] === 'token_not_valid') {
                            let newAccess = await getAccessTokenWithRefresh();
                            if (newAccess) {
                                setAccessToken(newAccess);
                                localStorage.setItem('access', newAccess);

                                postArchivo(archivo, ext, newAccess);
                            }
                        } else {
                            setShowBackdrop(false);
                            setMessage('Ocurrió un error, intente de nuevo.');
                            setSeverity('error');
                            setTimeout(() => { setSnack(true) }, 0);
                        }
                    });
                }
            }
            else {
                setMessage('El tipo de archivo seleccionado no es soportado. Archivos soportados: imagen, pdf o documento de Word');
                setSeverity('warning');
                setTimeout(() => { setSnack(true) }, 0);
            }
        }
    }
    const limpiar = () => {
        setOpenEditarEstado(false);
        setEstado('');
        setFechaInicio(null);
        setFechaFin(null);
        setHonorariosLetra('');
        setHonorariosNumero('');
        setArchivosContrato(Array(2).fill(null));
        setNombresContrato(Array(2).fill(''));
        setDuracion('1 mes');
        setErrores(Array(5).fill(false));
        setCarnet(null);
        setDateAlert(false);
    }
    return (
        <div>
            <Dialog
                open={openEditarEstado}
                TransitionProps={{
                    onEnter: () => { fetchCarnet(); if (estado.includes('Apto') || estado.includes('Contratado')) { fetchContratacion() } }
                }}
                onClose={() => limpiar()}
                fullWidth={true}
                maxWidth='xs'
            >
                <DialogTitle disableTypography style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }}>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                    >
                        <Grid item xs={10}>
                            <Typography variant='h6' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Editar estado de {nombre}</Typography>
                        </Grid>
                        <Grid item xs={2} >
                            <IconButton onClick={() => { limpiar() }}>
                                <CloseIcon style={{ align: 'flex-end' }} />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>


                <Divider />
                <DialogContent style={{ paddingBottom: theme.spacing(2) }}>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                        style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                    >
                        <Grid item xs={12}>
                            <Typography variant='body1' align='center' style={{ fontSize: 18, fontWeight: 500, justifyContent: 'center' }}>Seleccionar estado:</Typography>
                            <Autocomplete
                                value={estado}
                                disableClearable
                                onChange={(e, v) => {
                                    setEstado(v);
                                    setFechaInicio(null);
                                    setFechaFin(null);
                                    setDuracion('1 mes');
                                    errores[0] = false;
                                    errores[1] = false;
                                    errores[2] = false;
                                    setErrores([...errores])
                                }}
                                options={
                                    estadosContratacion.slice(1).map(e => e.estado)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth

                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        alignItems='center'
                        spacing={4}
                        style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                    >
                        <Grid item md={4} xs={4}>
                            <Typography style={{ fontWeight: 500, marginBottom: theme.spacing(1), align: 'center' }}> Carnet: </Typography>
                            <Button
                                variant="contained"
                                component="label"
                                startIcon={carnet === null ? <CloudUploadIcon /> : <EditIcon />}
                                color={carnet ? 'secondary' : 'inherit'}
                                onClick={() => {
                                    if (carnet === undefined || nombreCarnet === null) {
                                        saveAs(carnet, nombreCarnet.split('/').slice(-1)[0])
                                    }
                                    else {
                                        hiddenInputs[0].current.click();
                                    }

                                }}
                                disabled={rol !== 'gestor'}
                            >
                                {carnet === null ? 'Subir' : 'Editar'}
                            </Button>
                            <input
                                type="file"
                                ref={hiddenInputs[0]}
                                style={{ display: 'none' }}
                                onChange={(e) => { postArchivo(e.target.files[0], e.target.files[0].name.split('.')[1]); e.target.value = null }}
                            />

                        </Grid>
                        <Grid item md={8} xs={8}>
                            {carnet !== null ?
                                <Link style={{ marginTop: theme.spacing(3) }} component="button" variant="body2" color='initial' onClick={() => {
                                    if (nombreCarnet === undefined || nombreCarnet === null) {
                                        saveAs(carnet, nombreCarnet.split('/').slice(-1)[0])
                                    }
                                    else {
                                        saveAs(carnet, carnet.name)
                                    }
                                }}>
                                    {nombreCarnet.split('/').slice(-1)[0].length > 33 ? nombreCarnet.split('/').slice(-1)[0].substring(0, 15) + "..." + nombreCarnet.split('/').slice(-1)[0].substring(nombreCarnet.split('/').slice(-1)[0].length - 15, nombreCarnet.split('/').slice(-1)[0].length) : nombreCarnet.split('/').slice(-1)[0]}
                                </Link>
                                :
                                null}

                        </Grid>
                    </Grid>
                    <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} />
                    <Collapse in={(estado.includes('Contratado')) || (estado.includes('Apto'))} mountOnEnter unmountOnExit>
                        <Grid
                            container
                            direction='row'
                            alignItems='center'
                            spacing={4}
                            style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                        >
                            <Grid item xs={6}>
                                <Typography variant='body2' align='center' style={{ fontSize: 16, fontWeight: 500, justifyContent: 'left' }}>Fecha inicio <span style={{ color: '#e53935' }}>*</span></Typography>
                                <KeyboardDatePicker
                                    autoOk
                                    inputVariant='outlined'
                                    size='small'
                                    variant='inline'
                                    fullWidth
                                    value={fechaInicio}
                                    placeholder="dd/mm/aaaa"
                                    onChange={date => { setFechaInicio(date); errores[0] = false; differenceInCalendarDays(date, new Date()) < 0 ? setDateAlert(true) : setDateAlert(false); errores[1] = false; setErrores([...errores]) }}
                                    format="dd/MM/yyyy"
                                    error={errores[0]}
                                    helperText={errores[0] ? 'Este campo no puede quedar vacío.' : null}
                                    style={{ marginBottom: errores[1] && (!errores[0] && !dateAlert) && 'xs' !== point ? theme.spacing(4.8) : null }}
                                />
                                {
                                    (differenceInCalendarDays(fechaInicio, new Date()) < 0 && dateAlert) ?

                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={1}>
                                                <WarningIcon style={{ fontSize: 'small', color: '#eb6f00' }} />
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography variant='caption' align='center' style={{ justifyContent: 'left', color: '#eb6f00' }}> La fecha ingresada es anterior al día de hoy.</Typography>
                                            </Grid>
                                        </Grid>

                                        :
                                        null
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant='body2' align='center' style={{ fontSize: 16, fontWeight: 500, justifyContent: 'left' }}>Fecha fin <span style={{ color: '#e53935' }}>*</span></Typography>
                                <KeyboardDatePicker
                                    className={classes.textField}
                                    autoOk
                                    inputVariant='outlined'
                                    size='small'
                                    variant='inline'
                                    fullWidth
                                    value={fechaFin}
                                    placeholder="dd/mm/aaaa"
                                    onChange={date => { setFechaFin(date); errores[1] = false; setErrores([...errores]) }}
                                    format="dd/MM/yyyy"
                                    error={errores[1]}
                                    helperText={errores[1] ? ((differenceInCalendarDays(fechaFin, fechaInicio) < 0) ? 'La fecha ingresada es anterior a la fecha de inicio.' : 'Este campo no puede quedar vacío.') : null}
                                    style={{ marginBottom: (errores[0] || dateAlert) && !errores[1] && 'xs' !== point ? theme.spacing(4.8) : null }}
                                />

                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction='row'
                            alignItems='center'
                            spacing={4}
                            style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                        >

                            <Grid item md={6} xs={6}>
                                <Typography variant='body2' align='center' style={{ fontSize: 16, fontWeight: 500, justifyContent: 'left' }}>Duración del contrato: <span style={{ color: '#e53935' }}>*</span></Typography>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    size='small'
                                    onChange={(e, v) => { if (e.target.value.length < 31) setDuracion(e.target.value); errores[2] = false; setErrores([...errores]) }}
                                    error={errores[2]}
                                    helperText={errores[2] ? 'Ingresa una duración' : null}
                                    value={duracion}
                                />
                            </Grid>
                        </Grid>
                        <Collapse in={(estado === 'Apto contratación (AP)' || estado === 'Apto contratación (ECAR)') } mountOnEnter unmountOnExit>
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                spacing={4}
                                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                            >

                                <Grid item md={6} xs={6}>
                                    <Typography variant='body2' align='center' style={{ fontSize: 16, fontWeight: 500, justifyContent: 'left' }}>Honorarios en número: <span style={{ color: '#e53935' }}>*</span></Typography>
                                </Grid>
                                <Grid item md={6} xs={6}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        size='small'
                                        onChange={(e, v) => { setHonorariosNumero(e.target.value); errores[3] = false; setErrores([...errores]) }}
                                        error={errores[3]}
                                        helperText={errores[3] ? 'Ingresa los honorarios' : null}
                                        value={honorariosNumero}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction='row'
                                alignItems='center'
                                spacing={4}
                                style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                            >

                                <Grid item md={6} xs={6}>
                                    <Typography variant='body2' align='center' style={{ fontSize: 16, fontWeight: 500, justifyContent: 'left' }}>Honorarios en letra: <span style={{ color: '#e53935' }}>*</span></Typography>
                                </Grid>
                                <Grid item md={6} xs={6}>
                                    <TextField
                                        variant='outlined'
                                        fullWidth
                                        size='small'
                                        onChange={(e, v) => { setHonorariosLetra(e.target.value); errores[4] = false; setErrores([...errores]) }}
                                        error={errores[4]}
                                        helperText={errores[4] ? 'Ingresa los honorarios' : null}
                                        value={honorariosLetra}
                                    />
                                </Grid>
                            </Grid>

                        </Collapse>
                        <Divider style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} />

                        {archivosContrato[0] !== null ?
                            <React.Fragment>
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    spacing={4}
                                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                                >


                                    <Grid item md={4} xs={4}>
                                        <Typography style={{ fontWeight: 500, marginBottom: theme.spacing(1), align: 'center' }}> Contrato: </Typography>
                                        <Button
                                            variant="contained"
                                            component="label"
                                            startIcon={<CloudDownloadIcon />}
                                            color={archivosContrato[0] ? 'secondary' : 'inherit'}
                                            onClick={() => {
                                                saveAs(archivosContrato[0], nombresContrato[0].split('/').slice(-1)[0])
                                            }}
                                        >
                                            Ver
                                        </Button>

                                    </Grid>
                                    <Grid item md={8} xs={8}>
                                        {archivosContrato[0] !== null ?
                                            <Link style={{ marginTop: theme.spacing(3) }} component="button" variant="body2" color='initial' onClick={() => {
                                                saveAs(archivosContrato[0], nombresContrato[0].split('/').slice(-1)[0])
                                            }}>
                                                {nombresContrato[0].split('/').slice(-1)[0].length > 33 ? nombresContrato[0].split('/').slice(-1)[0].substring(0, 15) + "..." + nombresContrato[0].split('/').slice(-1)[0].substring(nombresContrato[0].split('/').slice(-1)[0].length - 15, nombresContrato[0].split('/').slice(-1)[0].length) : nombresContrato[0].split('/').slice(-1)[0]}
                                            </Link>
                                            :
                                            null}
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                    spacing={4}
                                    style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1) }}
                                >

                                    <Grid item md={4} xs={4}>
                                        <Typography style={{ fontWeight: 500, marginBottom: theme.spacing(1), align: 'center' }}> Comodato: </Typography>
                                        <Button
                                            variant="contained"
                                            component="label"
                                            startIcon={<CloudDownloadIcon />}
                                            color={archivosContrato[1] ? 'secondary' : 'inherit'}
                                            onClick={() => {
                                                saveAs(archivosContrato[1], nombresContrato[1].split('/').slice(-1)[0])
                                            }}
                                        >
                                            Ver
                                        </Button>

                                    </Grid>
                                    <Grid item md={8} xs={8}>
                                        {archivosContrato[1] !== null ?
                                            <Link style={{ marginTop: theme.spacing(3) }} component="button" variant="body2" color='initial' onClick={() => {
                                                saveAs(archivosContrato[1], nombresContrato[1].split('/').slice(-1)[0])
                                            }}>
                                                {nombresContrato[1].split('/').slice(-1)[0].length > 33 ? nombresContrato[1].split('/').slice(-1)[0].substring(0, 15) + "..." + nombresContrato[1].split('/').slice(-1)[0].substring(nombresContrato[1].split('/').slice(-1)[0].length - 15, nombresContrato[1].split('/').slice(-1)[0].length) : nombresContrato[1].split('/').slice(-1)[0]}
                                            </Link>
                                            :
                                            null}
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                            :
                            null}
                    </Collapse>
                </DialogContent>
                <Divider style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} />

                <DialogActions style={{ padding: theme.spacing(1.5) }}>
                    <Tooltip title="Ver detalle de contrataciones pasadas">
                        <div>
                            <IconButton
                                onClick={() => { setOpenContratacion(true) }}
                                style={{ color: hayContratos === false ? '#D3D3D3' : '#E47C9C' }}
                                disabled={hayContratos === false}
                            >
                                <WorkIcon />
                            </IconButton>
                        </div>
                    </Tooltip>
                    <Button size='small' variant='contained' color='secundary' onClick={() => {limpiar(); setOpenEditarEstado(false);}} style={{display: rol === 'gestor'?'none':'inline'}}>
                        Cancelar
                    </Button>
                    <Button size='small' variant='contained' color='primary' onClick={() => { validar() }} className={classes.conditionalRoleDisplay}>
                        Actualizar estado
                    </Button>
                </DialogActions>
            </Dialog>

            <DialogoContratacion
                theme={theme}
                classes={classes}
                accessToken={[accessToken, setAccessToken]}
                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                setShowBackdrop={setShowBackdrop}
                setMessage={setMessage}
                setSeverity={setSeverity}
                setSnack={setSnack}
                API_DEFAULT={API_DEFAULT}
                openContratacion={[openContratacion, setOpenContratacion]}
                cedula={cedula}
                nombre={nombre}
            />
        </div>
    );
}
export default DialogoEditarEstado;